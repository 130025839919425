import React from 'react';
import { MenuPage } from '~type/menu';
import { About } from './about';
import { Controls } from './controls';
import { LoadGame } from './load-game';
import { NewGame } from './new-game';
import { SaveGame } from './save-game';
import { Settings } from './settings';
import { Wrapper } from './styles';
const PAGES = {
    [MenuPage.NEW_GAME]: NewGame,
    [MenuPage.SAVE_GAME]: SaveGame,
    [MenuPage.LOAD_GAME]: LoadGame,
    [MenuPage.SETTINGS]: Settings,
    [MenuPage.ABOUT]: About,
    [MenuPage.CONTROLS]: Controls,
};
export const Content = ({ page }) => {
    const PageComponent = page && PAGES[page];
    return (React.createElement(Wrapper, null, PageComponent && React.createElement(PageComponent, null)));
};
