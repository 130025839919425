import Phaser from 'phaser';
import { DIFFICULTY } from '~const/world/difficulty';
import { LEVEL_TILE_SIZE } from '~const/world/level';
import { registerAudioAssets, registerSpriteAssets } from '~lib/assets';
import { progressionLinear } from '~lib/difficulty';
import { Level } from '~scene/world/level';
import { EntityType } from '~type/world/entities';
import { CrystalTexture, CrystalAudio, } from '~type/world/entities/crystal';
import { TileType } from '~type/world/level';
export class Crystal extends Phaser.GameObjects.Image {
    constructor(scene, { positionAtMatrix, variant = 0, }) {
        const tilePosition = Object.assign(Object.assign({}, positionAtMatrix), { z: 1 });
        const positionAtWorld = Level.ToWorldPosition(tilePosition);
        super(scene, positionAtWorld.x, positionAtWorld.y, CrystalTexture.CRYSTAL, variant);
        this.tileType = TileType.CRYSTAL;
        scene.addEntity(EntityType.CRYSTAL, this);
        this.positionAtMatrix = positionAtMatrix;
        this.setDepth(Level.GetTileDepth(positionAtWorld.y, tilePosition.z));
        this.setOrigin(0.5, LEVEL_TILE_SIZE.origin);
        this.scene.level.putTile(this, tilePosition);
    }
    pickup() {
        const resources = this.getResourcesAmount();
        this.scene.player.giveResources(resources);
        this.scene.sound.play(CrystalAudio.PICKUP);
        this.destroy();
    }
    getResourcesAmount() {
        const amount = progressionLinear({
            defaultValue: DIFFICULTY.CRYSTAL_RESOURCES,
            scale: DIFFICULTY.CRYSTAL_RESOURCES_GROWTH,
            level: this.scene.wave.number,
        });
        return Phaser.Math.Between(amount, amount + Math.floor(amount * 10.99));
    }
    getSavePayload() {
        return {
            position: this.positionAtMatrix,
        };
    }
}
registerAudioAssets(CrystalAudio);
registerSpriteAssets(CrystalTexture, LEVEL_TILE_SIZE);
