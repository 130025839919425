import Phaser from 'phaser';
import { DIFFICULTY } from '~const/world/difficulty';
import { PLAYER_TILE_SIZE, PLAYER_MOVE_DIRECTIONS, PLAYER_MOVE_ANIMATIONS, PLAYER_SKILLS, PLAYER_SUPERSKILLS, } from '~const/world/entities/player';
import { Crystal } from '~entity/crystal';
import { Sprite } from '~entity/sprite';
import { registerAudioAssets, registerSpriteAssets } from '~lib/assets';
import { progressionLinear, progressionQuadratic } from '~lib/difficulty';
import { eachEntries } from '~lib/utils';
import { Particles } from '~scene/world/effects';
import { GameSettings } from '~type/game';
import { NoticeType } from '~type/screen';
import { TutorialStep, TutorialStepState } from '~type/tutorial';
import { WorldEvents } from '~type/world';
import { ParticlesTexture } from '~type/world/effects';
import { EntityType } from '~type/world/entities';
import { BuildingVariant } from '~type/world/entities/building';
import { PlayerTexture, MovementDirection, PlayerAudio, PlayerSkill, } from '~type/world/entities/player';
import { TileType } from '~type/world/level';
import { WaveEvents } from '~type/world/wave';
export class Player extends Sprite {
    get experience() { return this._experience; }
    set experience(v) { this._experience = v; }
    get resources() { return this._resources; }
    set resources(v) { this._resources = v; }
    get score() { return this._score; }
    set score(v) { this._score = v; }
    get kills() { return this._kills; }
    set kills(v) { this._kills = v; }
    get upgradeLevel() { return this._upgradeLevel; }
    set upgradeLevel(v) { this._upgradeLevel = v; }
    get activeSuperskills() { return this._activeSuperskills; }
    set activeSuperskills(v) { this._activeSuperskills = v; }
    constructor(scene, data) {
        super(scene, Object.assign(Object.assign({}, data), { texture: PlayerTexture.PLAYER, health: DIFFICULTY.PLAYER_HEALTH, speed: DIFFICULTY.PLAYER_SPEED }));
        this._experience = 0;
        this._resources = DIFFICULTY.PLAYER_START_RESOURCES;
        this._score = 0;
        this._kills = 0;
        this._upgradeLevel = {
            [PlayerSkill.MAX_HEALTH]: 1,
            [PlayerSkill.SPEED]: 1,
            [PlayerSkill.BUILD_AREA]: 1,
            [PlayerSkill.ASSISTANT]: 1,
        };
        this.movementKeysState = {};
        this.direction = 0;
        this.isMoving = false;
        this.dustEffect = null;
        this._activeSuperskills = {};
        scene.add.existing(this);
        this.gamut = PLAYER_TILE_SIZE.gamut;
        this.handleKeyboard();
        this.registerAnimations();
        this.addDustEffect();
        this.addIndicator({
            color: 0xd0ff4f,
            value: () => this.live.health / this.live.maxHealth,
            size: 20,
        });
        this.body.setSize(14, 26);
        this.setTilesGroundCollision(true);
        this.setTilesCollision([
            TileType.MAP,
            TileType.BUILDING,
            TileType.CRYSTAL,
        ], (tile) => {
            if (tile instanceof Crystal) {
                tile.pickup();
            }
        });
        this.addCollider(EntityType.ENEMY, 'collider', (enemy) => {
            enemy.attack(this);
        });
        this.addCollider(EntityType.ENEMY, 'overlap', (enemy) => {
            enemy.overlapTarget();
        });
        this.scene.wave.on(WaveEvents.COMPLETE, this.onWaveComplete.bind(this));
    }
    update() {
        super.update();
        if (this.live.isDead()) {
            return;
        }
        if (this.dustEffect) {
            this.dustEffect.emitter.setDepth(this.depth - 1);
        }
        this.updateDirection();
        this.updateVelocity();
    }
    giveScore(amount) {
        if (this.live.isDead()) {
            return;
        }
        this.score += amount;
    }
    giveExperience(amount) {
        if (this.live.isDead()) {
            return;
        }
        this.experience += Math.round(amount / this.scene.game.getDifficultyMultiplier());
    }
    giveResources(amount) {
        if (this.live.isDead()) {
            return;
        }
        this.resources += amount;
        if (this.scene.game.tutorial.state(TutorialStep.RESOURCES) === TutorialStepState.IN_PROGRESS) {
            this.scene.game.tutorial.complete(TutorialStep.RESOURCES);
        }
    }
    takeResources(amount) {
        this.resources -= amount;
        if (this.resources < DIFFICULTY.BUILDING_GENERATOR_COST
            && this.scene.builder.getBuildingsByVariant(BuildingVariant.GENERATOR).length === 0) {
            this.scene.game.tutorial.start(TutorialStep.RESOURCES);
        }
    }
    incrementKills() {
        this.kills++;
    }
    getSuperskillCost(type) {
        return progressionLinear({
            defaultValue: PLAYER_SUPERSKILLS[type].cost,
            scale: DIFFICULTY.SUPERSKILL_COST_GROWTH,
            level: this.scene.wave.number,
        });
    }
    useSuperskill(type) {
        if (this.activeSuperskills[type] || !this.scene.wave.isGoing) {
            return;
        }
        const cost = this.getSuperskillCost(type);
        if (this.resources < cost) {
            this.scene.game.screen.notice(NoticeType.ERROR, 'Not enough resources');
            return;
        }
        this.activeSuperskills[type] = true;
        this.takeResources(cost);
        this.scene.events.emit(WorldEvents.USE_SUPERSKILL, type);
        this.scene.time.addEvent({
            delay: PLAYER_SUPERSKILLS[type].duration,
            callback: () => {
                delete this.activeSuperskills[type];
            },
        });
    }
    getExperienceToUpgrade(type) {
        return progressionQuadratic({
            defaultValue: PLAYER_SKILLS[type].experience,
            scale: DIFFICULTY.PLAYER_EXPERIENCE_TO_UPGRADE_GROWTH,
            level: this.upgradeLevel[type],
            roundTo: 10,
        });
    }
    getUpgradeNextValue(type, level) {
        const nextLevel = level !== null && level !== void 0 ? level : this.upgradeLevel[type] + 1;
        switch (type) {
            case PlayerSkill.MAX_HEALTH: {
                return progressionQuadratic({
                    defaultValue: DIFFICULTY.PLAYER_HEALTH,
                    scale: DIFFICULTY.PLAYER_HEALTH_GROWTH,
                    level: nextLevel,
                    roundTo: 10,
                });
            }
            case PlayerSkill.SPEED: {
                return progressionQuadratic({
                    defaultValue: DIFFICULTY.PLAYER_SPEED,
                    scale: DIFFICULTY.PLAYER_SPEED_GROWTH,
                    level: nextLevel,
                });
            }
            case PlayerSkill.BUILD_AREA: {
                return progressionQuadratic({
                    defaultValue: DIFFICULTY.BUILDER_BUILD_AREA,
                    scale: DIFFICULTY.BUILDER_BUILD_AREA_GROWTH,
                    level: nextLevel,
                });
            }
            case PlayerSkill.ASSISTANT: {
                return nextLevel;
            }
        }
    }
    upgrade(type) {
        const experience = this.getExperienceToUpgrade(type);
        if (this.experience < experience) {
            this.scene.game.screen.notice(NoticeType.ERROR, 'Not enough experience');
            return;
        }
        this.setSkillUpgrade(type, this.upgradeLevel[type] + 1);
        this.experience -= experience;
        this.scene.sound.play(PlayerAudio.UPGRADE);
        this.scene.game.tutorial.complete(TutorialStep.UPGRADE_SKILL);
    }
    setSkillUpgrade(type, level) {
        const nextValue = this.getUpgradeNextValue(type, level);
        switch (type) {
            case PlayerSkill.MAX_HEALTH: {
                const addedHealth = nextValue - this.live.maxHealth;
                this.live.setMaxHealth(nextValue);
                this.live.addHealth(addedHealth);
                if (this.scene.assistant) {
                    this.scene.assistant.live.setMaxHealth(nextValue);
                    this.scene.assistant.live.addHealth(addedHealth);
                }
                break;
            }
            case PlayerSkill.SPEED: {
                this.speed = nextValue;
                if (this.scene.assistant) {
                    this.scene.assistant.speed = nextValue;
                }
                break;
            }
            case PlayerSkill.BUILD_AREA: {
                this.scene.builder.setBuildAreaRadius(nextValue);
                break;
            }
            case PlayerSkill.ASSISTANT: {
                if (this.scene.assistant) {
                    this.scene.assistant.level = nextValue;
                }
                break;
            }
        }
        this.upgradeLevel[type] = level;
    }
    getSavePayload() {
        return {
            position: this.positionAtMatrix,
            score: this.score,
            experience: this.experience,
            resources: this.resources,
            kills: this.kills,
            health: this.live.health,
            upgradeLevel: this.upgradeLevel,
        };
    }
    loadSavePayload(data) {
        this.score = data.score;
        this.experience = data.experience;
        this.resources = data.resources;
        this.kills = data.kills;
        eachEntries(data.upgradeLevel, (type, level) => {
            if (level > 1) {
                this.setSkillUpgrade(type, level);
            }
        });
        this.live.setHealth(data.health);
    }
    onDamage() {
        this.scene.camera.shake();
        const audio = Phaser.Utils.Array.GetRandom([
            PlayerAudio.DAMAGE_1,
            PlayerAudio.DAMAGE_2,
            PlayerAudio.DAMAGE_3,
        ]);
        if (this.scene.game.sound.getAll(audio).length === 0) {
            this.scene.game.sound.play(audio);
        }
        super.onDamage();
    }
    onDead() {
        this.scene.sound.play(PlayerAudio.DEAD);
        this.setVelocity(0, 0);
        this.stopMovement();
        this.scene.tweens.add({
            targets: [this, this.container],
            alpha: 0.0,
            duration: 250,
        });
    }
    onWaveComplete(number) {
        const experience = progressionQuadratic({
            defaultValue: DIFFICULTY.WAVE_EXPERIENCE,
            scale: DIFFICULTY.WAVE_EXPERIENCE_GROWTH,
            level: number,
        });
        this.giveExperience(experience);
        this.giveScore(number * 10);
        this.live.heal();
    }
    handleKeyboard() {
        var _a, _b;
        const keysMap = {
            w: 'UP',
            ArrowUp: 'UP',
            s: 'DOWN',
            ArrowDown: 'DOWN',
            a: 'LEFT',
            ArrowLeft: 'LEFT',
            d: 'RIGHT',
            ArrowRight: 'RIGHT',
        };
        (_a = this.scene.input.keyboard) === null || _a === void 0 ? void 0 : _a.on(Phaser.Input.Keyboard.Events.ANY_KEY_DOWN, (event) => {
            if (keysMap[event.key]) {
                this.movementKeysState[keysMap[event.key]] = true;
            }
        });
        (_b = this.scene.input.keyboard) === null || _b === void 0 ? void 0 : _b.on(Phaser.Input.Keyboard.Events.ANY_KEY_UP, (event) => {
            if (keysMap[event.key]) {
                this.movementKeysState[keysMap[event.key]] = false;
            }
        });
    }
    updateVelocity() {
        var _a, _b;
        if (!this.isMoving) {
            this.setVelocity(0, 0);
            return;
        }
        const collide = this.handleCollide(this.direction);
        if (collide) {
            this.setVelocity(0, 0);
            return;
        }
        const friction = (_b = (_a = this.currentBiome) === null || _a === void 0 ? void 0 : _a.friction) !== null && _b !== void 0 ? _b : 1;
        const speed = this.speed / friction;
        const velocity = this.scene.physics.velocityFromAngle(this.direction, speed);
        this.setVelocity(velocity.x, velocity.y);
    }
    updateDirection() {
        const x = this.getKeyboardSingleDirection(['LEFT', 'RIGHT']);
        const y = this.getKeyboardSingleDirection(['UP', 'DOWN']);
        const key = `${x}|${y}`;
        const oldMoving = this.isMoving;
        const oldDirection = this.direction;
        if (x !== 0 || y !== 0) {
            this.isMoving = true;
            this.direction = PLAYER_MOVE_DIRECTIONS[key];
        }
        else {
            this.isMoving = false;
        }
        if (oldMoving !== this.isMoving || oldDirection !== this.direction) {
            if (this.isMoving) {
                this.anims.play(PLAYER_MOVE_ANIMATIONS[key]);
                if (!oldMoving) {
                    if (this.dustEffect) {
                        this.dustEffect.emitter.start();
                    }
                    this.scene.game.sound.play(PlayerAudio.WALK, {
                        loop: true,
                        rate: 1.8,
                    });
                }
            }
            else {
                this.stopMovement();
            }
        }
    }
    stopMovement() {
        this.isMoving = false;
        if (this.anims.currentAnim) {
            this.anims.setProgress(0);
            this.anims.stop();
        }
        if (this.dustEffect) {
            this.dustEffect.emitter.stop();
        }
        this.scene.sound.stopByKey(PlayerAudio.WALK);
    }
    getKeyboardSingleDirection(directions) {
        var _a;
        const type = (_a = directions.find((direction) => this.movementKeysState[direction])) !== null && _a !== void 0 ? _a : 'NONE';
        return MovementDirection[type];
    }
    addDustEffect() {
        if (!this.scene.game.isSettingEnabled(GameSettings.EFFECTS)) {
            return;
        }
        this.dustEffect = new Particles(this, {
            key: 'dust',
            texture: ParticlesTexture.BIT,
            params: {
                follow: this,
                followOffset: {
                    x: 0,
                    y: -this.gamut * this.scaleY * 0.5,
                },
                lifespan: { min: 150, max: 300 },
                scale: 0.6,
                speed: 10,
                frequency: 150,
                alpha: { start: 0.75, end: 0.0 },
                emitting: false,
            },
        });
    }
    registerAnimations() {
        Object.values(PLAYER_MOVE_ANIMATIONS).forEach((key, index) => {
            this.anims.create({
                key,
                frames: this.anims.generateFrameNumbers(PlayerTexture.PLAYER, {
                    start: index * 4,
                    end: (index + 1) * 4 - 1,
                }),
                frameRate: 8,
                repeat: -1,
            });
        });
    }
}
registerAudioAssets(PlayerAudio);
registerSpriteAssets(PlayerTexture, PLAYER_TILE_SIZE);
