import { useGame } from 'phaser-react-ui';
import React, { useState } from 'react';
import { Button } from '~scene/system/interface/button';
import { Table } from '~scene/system/interface/table';
import { Wrapper, Empty } from './styles';
export const LoadGame = () => {
    const game = useGame();
    const [selectedSave, setSelectedSave] = useState(game.storage.saves[0]);
    const [saves, setSaves] = useState(game.storage.saves);
    const onClickStart = () => {
        game.continueGame(selectedSave);
    };
    const deleteSave = (event, name) => {
        event.stopPropagation();
        // eslint-disable-next-line no-alert
        if (window.confirm('Do you confirm delete this save?')) {
            game.storage.delete(name).then(() => {
                setSaves([...game.storage.saves]);
                if (selectedSave.name === name) {
                    setSelectedSave(game.storage.saves[0]);
                }
            });
        }
    };
    return (React.createElement(Wrapper, null,
        saves.length === 0 ? (React.createElement(Empty, null, "Saves not found")) : (React.createElement(Table, null,
            React.createElement(Table.Head, null,
                React.createElement(Table.HeadRow, null,
                    React.createElement(Table.Cell, null, "Name"),
                    React.createElement(Table.Cell, null, "Planet"),
                    React.createElement(Table.Cell, null, "Difficulty"),
                    React.createElement(Table.Cell, null, "Wave"),
                    React.createElement(Table.Cell, null, "Score"),
                    React.createElement(Table.Cell, null, "Date"),
                    React.createElement(Table.Cell, null))),
            React.createElement(Table.Body, null, saves.map((save) => (React.createElement(Table.BodyRow, { key: save.name, onClick: () => setSelectedSave(save), "$active": save.name === selectedSave.name },
                React.createElement(Table.Cell, null, save.name),
                React.createElement(Table.Cell, null, save.payload.level.planet),
                React.createElement(Table.Cell, null, save.payload.game.difficulty),
                React.createElement(Table.Cell, null, save.payload.wave.number),
                React.createElement(Table.Cell, null, save.payload.player.score),
                React.createElement(Table.Cell, null, new Date(save.date).toLocaleString()),
                React.createElement(Table.Cell, { "$type": "delete", onClick: (event) => deleteSave(event, save.name) }, "X"))))))),
        React.createElement(Button, { view: "primary", size: "medium", onClick: onClickStart, disabled: saves.length === 0 }, "Start")));
};
