import { useScene, useSceneUpdate } from 'phaser-react-ui';
import React, { useState } from 'react';
import { WAVE_TIMELEFT_ALARM } from '~const/world/wave';
import { formatTime } from '~lib/utils';
import { GameScene } from '~type/game';
import { CurrentNumber, Container, State, Wrapper, Label, Value, } from './styles';
export const Wave = () => {
    const world = useScene(GameScene.WORLD);
    const [currentNumber, setCurrentNumber] = useState(1);
    const [value, setValue] = useState(null);
    const [isGoing, setGoing] = useState(false);
    const [isAlarm, setAlarm] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    useSceneUpdate(world, () => {
        const currentIsDisabled = world.wave.isPeaceMode || world.isTimePaused();
        setDisabled(currentIsDisabled);
        if (currentIsDisabled) {
            return;
        }
        setCurrentNumber(world.wave.number);
        setGoing(world.wave.isGoing);
        if (world.wave.isGoing) {
            const enemiesLeft = world.wave.getEnemiesLeft();
            setValue(enemiesLeft);
            setAlarm(false);
        }
        else {
            const timeleft = world.wave.getTimeleft();
            setValue(formatTime(timeleft));
            setAlarm(timeleft <= WAVE_TIMELEFT_ALARM);
        }
    });
    return isDisabled ? (React.createElement("div", null)) : (React.createElement(Wrapper, null,
        React.createElement(Container, null,
            React.createElement(CurrentNumber, { "$going": isGoing }, currentNumber),
            React.createElement(State, null,
                React.createElement(Label, null, isGoing ? 'Enemies' : 'Timeleft'),
                React.createElement(Value, { "$attention": isAlarm }, value)))));
};
