import Phaser from 'phaser';
import { SHOT_BALL_DAMAGE_SPREAD_FACTOR, SHOT_BALL_DAMAGE_SPREAD_MAX_DISTANCE } from '~const/world/entities/shot';
import { registerAudioAssets, registerImageAssets } from '~lib/assets';
import { Particles } from '~scene/world/effects';
import { Level } from '~scene/world/level';
import { GameSettings } from '~type/game';
import { ParticlesTexture } from '~type/world/effects';
import { EntityType } from '~type/world/entities';
import { ShotBallAudio, ShotBallTexture, } from '~type/world/entities/shot';
export class ShotBall extends Phaser.Physics.Arcade.Image {
    constructor(scene, params, { texture, audio, glowColor = null, scale = 1.0, }) {
        super(scene, 0, 0, texture);
        this.initiator = null;
        this.positionCallback = null;
        this.effect = null;
        this.startPosition = null;
        this.glowColor = null;
        scene.addEntity(EntityType.SHOT, this);
        this.params = params;
        this.audio = audio;
        this.glowColor = glowColor;
        this.setActive(false);
        this.setVisible(false);
        this.setScale(scale);
        this.scene.physics.add.collider(this, this.scene.getEntitiesGroup(EntityType.ENEMY), (_, enemy) => {
            this.hit(enemy);
        });
    }
    setInitiator(initiator, positionCallback = null) {
        this.initiator = initiator;
        this.positionCallback = positionCallback;
        initiator.on(Phaser.GameObjects.Events.DESTROY, () => {
            this.destroy();
        });
    }
    update() {
        if (!this.params.maxDistance || !this.startPosition) {
            return;
        }
        const distance = Phaser.Math.Distance.BetweenPoints(this, this.startPosition);
        if (distance > this.params.maxDistance) {
            this.stop();
            return;
        }
        const depth = Level.GetDepth(this.y, 1);
        this.setDepth(depth);
    }
    shoot(target) {
        var _a, _b;
        if (!this.initiator || !this.params.speed) {
            return;
        }
        const position = (_b = (_a = this.positionCallback) === null || _a === void 0 ? void 0 : _a.call(this)) !== null && _b !== void 0 ? _b : this.initiator;
        this.setPosition(position.x, position.y);
        this.setActive(true);
        this.setVisible(true);
        if (this.glowColor
            && this.scene.game.isSettingEnabled(GameSettings.EFFECTS)) {
            this.effect = new Particles(this, {
                key: 'glow',
                texture: ParticlesTexture.GLOW,
                params: {
                    follow: this,
                    scale: 0.25 * this.scale,
                    alpha: { start: 1.0, end: 0.0 },
                    lifespan: 100,
                    tint: this.glowColor,
                    blendMode: 'ADD',
                },
            });
        }
        this.startPosition = { x: this.x, y: this.y };
        const distanceToTarget = Phaser.Math.Distance.BetweenPoints(this, target.body.center);
        const speed = Math.min(this.params.speed, 1200);
        const timeToTarget = (distanceToTarget / speed);
        const targetPosition = this.scene.getFuturePosition(target, timeToTarget);
        this.scene.physics.world.enable(this, Phaser.Physics.Arcade.DYNAMIC_BODY);
        this.scene.physics.moveTo(this, targetPosition.x, targetPosition.y, speed);
        if (this.scene.game.sound.getAll(this.audio).length < 3) {
            this.scene.game.sound.play(this.audio);
        }
    }
    hit(target) {
        const { damage, freeze } = this.params;
        if (freeze && target.live.armour <= 0) {
            const duration = freeze / this.scale;
            target.freeze(duration, true);
        }
        if (damage) {
            target.live.damage(damage);
            this.scene.getEntities(EntityType.ENEMY).forEach((enemy) => {
                if (enemy !== target) {
                    const distance = Phaser.Math.Distance.BetweenPoints(target, enemy);
                    if (distance < SHOT_BALL_DAMAGE_SPREAD_MAX_DISTANCE) {
                        const damageByDistance = damage
                            * SHOT_BALL_DAMAGE_SPREAD_FACTOR
                            * (1 - (distance / SHOT_BALL_DAMAGE_SPREAD_MAX_DISTANCE));
                        enemy.live.damage(damageByDistance);
                    }
                }
            });
        }
        this.stop();
    }
    stop() {
        if (this.effect) {
            this.effect.destroy();
            this.effect = null;
        }
        this.setActive(false);
        this.setVisible(false);
        this.startPosition = null;
        this.scene.physics.world.disable(this);
    }
}
registerAudioAssets(ShotBallAudio);
registerImageAssets(ShotBallTexture);
