import { DIFFICULTY } from '~const/world/difficulty';
import { ShotLazer } from '~entity/shot/lazer';
import { BuildingIcon, BuildingTexture, BuildingVariant, } from '~type/world/entities/building';
import { BuildingTower } from '../tower';
export class BuildingTowerLazer extends BuildingTower {
    constructor(scene, data) {
        const shot = new ShotLazer(scene, {
            damage: DIFFICULTY.BUILDING_TOWER_LAZER_DAMAGE,
        });
        super(scene, Object.assign(Object.assign({}, data), { variant: BuildingVariant.TOWER_LAZER, health: BuildingTowerLazer.Health, texture: BuildingTowerLazer.Texture, radius: {
                default: DIFFICULTY.BUILDING_TOWER_LAZER_RADIUS,
                growth: DIFFICULTY.BUILDING_TOWER_LAZER_RADIUS_GROWTH,
            }, delay: {
                default: DIFFICULTY.BUILDING_TOWER_LAZER_DELAY,
                growth: DIFFICULTY.BUILDING_TOWER_LAZER_DELAY_GROWTH,
            } }), shot);
    }
}
BuildingTowerLazer.Name = 'Lazer tower';
BuildingTowerLazer.Description = 'Instant and continuous laser attack of enemies';
BuildingTowerLazer.Params = [
    { label: 'Health', value: DIFFICULTY.BUILDING_TOWER_LAZER_HEALTH, icon: BuildingIcon.HEALTH },
    { label: 'Readius', value: DIFFICULTY.BUILDING_TOWER_LAZER_RADIUS, icon: BuildingIcon.RADIUS },
    { label: 'Damage', value: DIFFICULTY.BUILDING_TOWER_LAZER_DAMAGE, icon: BuildingIcon.DAMAGE },
];
BuildingTowerLazer.Texture = BuildingTexture.TOWER_LAZER;
BuildingTowerLazer.Cost = DIFFICULTY.BUILDING_TOWER_LAZER_COST;
BuildingTowerLazer.Health = DIFFICULTY.BUILDING_TOWER_LAZER_HEALTH;
BuildingTowerLazer.AllowByWave = DIFFICULTY.BUILDING_TOWER_LAZER_ALLOW_BY_WAVE;
