import { OutlineShader } from './outline';
export const shaders = {
    OutlineShader,
};
Phaser.GameObjects.Image.prototype.addShader = function (shader, config) {
    if (!shaders[shader]) {
        console.warn(`Shader '${shader}' is not found`);
        return;
    }
    this.setPostPipeline(shader);
    if (config) {
        this.updateShader(shader, config);
    }
};
Phaser.GameObjects.Image.prototype.updateShader = function (shader, config) {
    var _a;
    const pipeline = this.postPipelines.find((p) => (p.name === shader));
    (_a = pipeline === null || pipeline === void 0 ? void 0 : pipeline.setConfig) === null || _a === void 0 ? void 0 : _a.call(pipeline, config);
};
Phaser.GameObjects.Image.prototype.removeShader = function (shader) {
    this.removePostPipeline(shader);
};
