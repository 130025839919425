import { useGame } from 'phaser-react-ui';
import React, { useMemo } from 'react';
import { GameState } from '~type/game';
import { MenuPage } from '~type/menu';
import { Wrapper, Item, Space } from './styles';
export const Navigation = ({ page, onSelect }) => {
    const game = useGame();
    const menuItems = useMemo(() => {
        const items = [];
        if (game.state === GameState.IDLE) {
            items.push({
                label: 'New game',
                page: MenuPage.NEW_GAME,
            }, {
                label: 'Load game',
                page: MenuPage.LOAD_GAME,
            });
        }
        else {
            items.push({
                label: 'Continue',
                onClick: () => game.resumeGame(),
            }, {
                label: 'Save game',
                page: MenuPage.SAVE_GAME,
                disabled: game.world.wave.isGoing,
            }, {
                label: 'Main menu',
                page: MenuPage.NEW_GAME,
                onClick: () => {
                    // eslint-disable-next-line no-alert
                    if (window.confirm('Do you confirm stop game?')) {
                        game.stopGame();
                    }
                },
            });
        }
        items.push(null, {
            label: 'Settings',
            page: MenuPage.SETTINGS,
        }, {
            label: 'About',
            page: MenuPage.ABOUT,
        }, {
            label: 'Controls',
            page: MenuPage.CONTROLS,
        });
        return items;
    }, []);
    return (React.createElement(Wrapper, null, menuItems.map((item, index) => {
        var _a;
        return (item ? (React.createElement(Item, { key: item.label, onClick: (_a = item.onClick) !== null && _a !== void 0 ? _a : (() => item.page && onSelect(item.page)), "$active": item.page === page, "$disabled": item.disabled }, item.label)) : (React.createElement(Space, { key: index })));
    })));
};
