import { useGame } from 'phaser-react-ui';
import React, { useRef, useEffect, useState, } from 'react';
import { MAX_GAME_SAVES } from '~const/game';
import { Button } from '~scene/system/interface/button';
import { Table } from '~scene/system/interface/table';
import { Input, Limit, Wrapper } from './styles';
export const SaveGame = () => {
    const game = useGame();
    const [saves, setSaves] = useState(game.storage.saves);
    const [saveName, setSaveName] = useState('');
    const refInput = useRef();
    const onSelectSave = (save) => {
        setSaveName(save.name);
    };
    const onChangeSaveName = (event) => {
        setSaveName(event.target.value);
    };
    const onClickSave = () => {
        if (!saveName) {
            return;
        }
        const exist = saves.some((save) => save.name === saveName);
        // eslint-disable-next-line no-alert
        if (!exist || window.confirm('Do you confirm rewrite this save?')) {
            game.storage.save(game, saveName).then(() => {
                setSaveName('');
                setSaves([...game.storage.saves]);
            });
        }
    };
    const deleteSave = (event, name) => {
        event.stopPropagation();
        // eslint-disable-next-line no-alert
        if (window.confirm('Do you confirm delete this save?')) {
            game.storage.delete(name).then(() => {
                setSaves([...game.storage.saves]);
            });
        }
    };
    useEffect(() => {
        if (refInput.current) {
            refInput.current.focus();
        }
    }, []);
    return (React.createElement(Wrapper, null,
        saves.length > 0 && (React.createElement(Table, null,
            React.createElement(Table.Head, null,
                React.createElement(Table.HeadRow, null,
                    React.createElement(Table.Cell, null, "Name"),
                    React.createElement(Table.Cell, null, "Planet"),
                    React.createElement(Table.Cell, null, "Difficulty"),
                    React.createElement(Table.Cell, null, "Wave"),
                    React.createElement(Table.Cell, null, "Score"),
                    React.createElement(Table.Cell, null, "Date"),
                    React.createElement(Table.Cell, null))),
            React.createElement(Table.Body, null, saves.map((save) => (React.createElement(Table.BodyRow, { key: save.name, onClick: () => onSelectSave(save), "$active": save.name === saveName },
                React.createElement(Table.Cell, null, save.name),
                React.createElement(Table.Cell, null, save.payload.level.planet),
                React.createElement(Table.Cell, null, save.payload.game.difficulty),
                React.createElement(Table.Cell, null, save.payload.wave.number),
                React.createElement(Table.Cell, null, save.payload.player.score),
                React.createElement(Table.Cell, null, new Date(save.date).toLocaleString()),
                React.createElement(Table.Cell, { "$type": "delete", onClick: (event) => deleteSave(event, save.name) }, "X"))))))),
        saves.length >= MAX_GAME_SAVES ? (React.createElement(Limit, null, "You have maximum saves. Delete or rewrite exist")) : (React.createElement(Input, { type: "text", placeholder: "Save name", value: saveName, autoFocus: true, onChange: onChangeSaveName })),
        React.createElement(Button, { view: "primary", size: "medium", disabled: !saveName, onClick: onClickSave }, "Save")));
};
