import { useScene, useSceneUpdate } from 'phaser-react-ui';
import React, { useState } from 'react';
import { GameScene } from '~type/game';
import { Wrapper, Icon, Value } from './styles';
export const Cost = ({ type, value, size }) => {
    const world = useScene(GameScene.WORLD);
    const [haveAmount, setHaveAmount] = useState(0);
    useSceneUpdate(world, () => {
        setHaveAmount(world.player[type]);
    });
    return (React.createElement(Wrapper, { "$size": size },
        React.createElement(Icon, { src: `assets/sprites/hud/${type}.png` }),
        React.createElement(Value, { "$attention": haveAmount < value }, value)));
};
