import Phaser from 'phaser';
import { WORLD_DEPTH_EFFECT } from '~const/world';
import { SHOT_LAZER_DELAY, SHOT_LAZER_REPEAT } from '~const/world/entities/shot';
import { registerAudioAssets } from '~lib/assets';
import { Particles } from '~scene/world/effects';
import { GameSettings } from '~type/game';
import { ParticlesTexture } from '~type/world/effects';
import { EntityType } from '~type/world/entities';
import { ShotLazerAudio, } from '~type/world/entities/shot';
export class ShotLazer extends Phaser.GameObjects.Line {
    constructor(scene, params) {
        super(scene);
        this.initiator = null;
        this.positionCallback = null;
        this.timer = null;
        this.target = null;
        scene.addEntity(EntityType.SHOT, this);
        this.params = params;
        this.setActive(false);
        this.setVisible(false);
        this.setStrokeStyle(2, 0xb136ff, 0.5);
        this.setDepth(WORLD_DEPTH_EFFECT);
        this.setOrigin(0.0, 0.0);
        this.on(Phaser.GameObjects.Events.DESTROY, () => {
            if (this.timer) {
                this.timer.destroy();
            }
        });
    }
    setInitiator(initiator, positionCallback = null) {
        this.initiator = initiator;
        this.positionCallback = positionCallback;
        initiator.on(Phaser.GameObjects.Events.DESTROY, () => {
            this.destroy();
        });
    }
    update() {
        this.updateLine();
    }
    shoot(target) {
        if (!this.initiator) {
            return;
        }
        this.target = target;
        this.timer = this.scene.time.addEvent({
            delay: SHOT_LAZER_DELAY,
            repeat: SHOT_LAZER_REPEAT,
            callback: () => this.processing(),
        });
        this.updateLine();
        this.setActive(true);
        this.setVisible(true);
        if (this.scene.game.sound.getAll(ShotLazerAudio.LAZER).length < 3) {
            this.scene.game.sound.play(ShotLazerAudio.LAZER);
        }
    }
    stop() {
        this.target = null;
        if (this.timer) {
            this.timer.destroy();
            this.timer = null;
        }
        this.setVisible(false);
        this.setActive(false);
    }
    updateLine() {
        var _a, _b, _c;
        if (!this.initiator || !((_a = this.target) === null || _a === void 0 ? void 0 : _a.body)) {
            return;
        }
        const position = (_c = (_b = this.positionCallback) === null || _b === void 0 ? void 0 : _b.call(this)) !== null && _c !== void 0 ? _c : this.initiator;
        this.setTo(position.x, position.y, this.target.body.center.x, this.target.body.center.y);
    }
    hit() {
        if (!this.target || !this.params.damage) {
            return;
        }
        const momentDamage = this.params.damage / SHOT_LAZER_REPEAT;
        this.target.live.damage(momentDamage);
        if (!this.scene.game.isSettingEnabled(GameSettings.EFFECTS)) {
            return;
        }
        new Particles(this.target, {
            key: 'glow',
            texture: ParticlesTexture.GLOW,
            params: {
                duration: 150,
                follow: this.target,
                followOffset: this.target.getBodyOffset(),
                lifespan: { min: 100, max: 150 },
                scale: { start: 0.2, end: 0.1 },
                speed: 80,
                tint: 0xb136ff,
            },
        });
    }
    processing() {
        var _a, _b;
        if (!this.initiator
            || !this.params.maxDistance
            || !((_a = this.target) === null || _a === void 0 ? void 0 : _a.body)
            || this.target.live.isDead()
            || Phaser.Math.Distance.BetweenPoints(this.initiator, this.target.body.center) > this.params.maxDistance) {
            this.stop();
            return;
        }
        this.hit();
        if (((_b = this.timer) === null || _b === void 0 ? void 0 : _b.repeatCount) === 0) {
            this.stop();
        }
    }
}
registerAudioAssets(ShotLazerAudio);
