import Phaser from 'phaser';
import { CONTROL_KEY } from '~const/controls';
import { WORLD_DEPTH_EFFECT } from '~const/world';
import { DIFFICULTY } from '~const/world/difficulty';
import { BUILDING_BUILD_DURATION, BUILDING_MAX_UPGRADE_LEVEL, BUILDING_PATH_COST } from '~const/world/entities/building';
import { LEVEL_TILE_SIZE } from '~const/world/level';
import { registerAudioAssets, registerImageAssets, registerSpriteAssets } from '~lib/assets';
import { progressionQuadratic, progressionLinear } from '~lib/difficulty';
import { Live } from '~lib/live';
import { Effect } from '~scene/world/effects';
import { Level } from '~scene/world/level';
import { GameEvents, GameSettings } from '~type/game';
import { LiveEvents } from '~type/live';
import { NoticeType } from '~type/screen';
import { TutorialStep } from '~type/tutorial';
import { WorldEvents } from '~type/world';
import { BuilderEvents } from '~type/world/builder';
import { EffectTexture } from '~type/world/effects';
import { EntityType } from '~type/world/entities';
import { BuildingEvents, BuildingAudio, BuildingTexture, BuildingOutlineState, BuildingIcon, } from '~type/world/entities/building';
import { TileType } from '~type/world/level';
export class Building extends Phaser.GameObjects.Image {
    get upgradeLevel() { return this._upgradeLevel; }
    set upgradeLevel(v) { this._upgradeLevel = v; }
    get isFocused() { return this._isFocused; }
    set isFocused(v) { this._isFocused = v; }
    constructor(scene, { positionAtMatrix, instant, health, texture, variant, radius, delay, }) {
        const tilePosition = Object.assign(Object.assign({}, positionAtMatrix), { z: 1 });
        const positionAtWorld = Level.ToWorldPosition(tilePosition);
        super(scene, positionAtWorld.x, positionAtWorld.y, texture);
        this.tileType = TileType.BUILDING;
        this._upgradeLevel = 1;
        this.radius = null;
        this.delay = null;
        this.nextActionTimestamp = 0;
        this.outlineState = BuildingOutlineState.NONE;
        this.alertIcon = null;
        this.alertTween = null;
        this.upgradeIcon = null;
        this.upgradeTween = null;
        this.actionsArea = null;
        this._isFocused = false;
        this.toFocus = false;
        this.isSelected = false;
        this.defaultHealth = 0;
        this.buildTimer = null;
        this.buildBar = null;
        scene.addEntity(EntityType.BUILDING, this);
        this.radius = radius !== null && radius !== void 0 ? radius : null;
        this.delay = delay !== null && delay !== void 0 ? delay : null;
        this.defaultHealth = health;
        this.variant = variant;
        this.positionAtMatrix = positionAtMatrix;
        this.live = new Live({ health });
        this.addActionArea();
        this.setInteractive({
            pixelPerfect: true,
            useHandCursor: true,
        });
        this.handleKeyboard();
        this.handlePointer();
        this.scene.builder.addFoundation(positionAtMatrix);
        this.setDepth(Level.GetTileDepth(positionAtWorld.y, tilePosition.z));
        this.setOrigin(0.5, LEVEL_TILE_SIZE.origin);
        this.scene.level.putTile(this, tilePosition);
        if (!instant) {
            this.startBuildProcess();
        }
        this.scene.level.navigator.setPointCost(positionAtMatrix, BUILDING_PATH_COST);
        this.live.on(LiveEvents.DAMAGE, this.onDamage.bind(this));
        this.live.on(LiveEvents.DEAD, this.onDead.bind(this));
        this.on(Phaser.GameObjects.Events.DESTROY, () => {
            this.stopBuildProcess();
            this.removeAlertIcon();
            this.removeUpgradeIcon();
            this.unfocus();
            this.unselect();
            this.scene.level.navigator.resetPointCost(positionAtMatrix);
            this.live.removeAllListeners();
        });
    }
    update() {
        this.updateOutline();
        // Catch focus by camera moving
        if (this.toFocus) {
            this.focus();
        }
    }
    actionsAreaContains(position) {
        if (!this.actionsArea) {
            return false;
        }
        const offset = this.actionsArea.getTopLeft();
        const contains = this.actionsArea.geom.contains(position.x - offset.x, position.y - offset.y);
        return contains;
    }
    pauseActions() {
        if (!this.delay) {
            return;
        }
        this.nextActionTimestamp = this.scene.getTime() + this.getActionsDelay();
    }
    isActionAllowed() {
        if (!this.delay) {
            return true;
        }
        return (this.nextActionTimestamp < this.scene.getTime());
    }
    getInfo() {
        const info = [{
                label: 'Health',
                icon: BuildingIcon.HEALTH,
                value: this.live.health,
            }];
        const delay = this.getActionsDelay();
        if (delay) {
            info.push({
                label: 'Delay',
                icon: BuildingIcon.DELAY,
                value: `${delay / 1000} s`,
            });
        }
        return info;
    }
    getControls() {
        const actions = [];
        if (this.isUpgradeAllowed()) {
            actions.push({
                label: 'Upgrade',
                cost: this.getUpgradeCost(),
                disabled: this.getUpgradeAllowedByWave() > this.scene.wave.number,
                onClick: () => {
                    this.upgrade();
                },
            });
        }
        if (!this.live.isMaxHealth()) {
            actions.push({
                label: 'Repair',
                cost: this.getRepairCost(),
                onClick: () => {
                    this.repair();
                },
            });
        }
        return actions;
    }
    getMeta() {
        return this.constructor;
    }
    getActionsRadius() {
        return this.radius
            ? progressionLinear({
                defaultValue: this.radius.default,
                scale: this.radius.growth,
                level: this.upgradeLevel,
            })
            : 0;
    }
    getActionsDelay() {
        return this.delay
            ? progressionLinear({
                defaultValue: this.delay.default,
                scale: this.delay.growth,
                level: this.upgradeLevel,
                roundTo: 100,
            })
            : 0;
    }
    getUpgradeCost(level) {
        const costPerLevel = this.getMeta().Cost / BUILDING_MAX_UPGRADE_LEVEL;
        const nextLevel = level !== null && level !== void 0 ? level : this.upgradeLevel;
        return Math.round(costPerLevel * nextLevel * DIFFICULTY.BUILDING_UPGRADE_COST_MULTIPLIER);
    }
    getRepairCost() {
        const damaged = 1 - (this.live.health / this.live.maxHealth);
        let cost = this.getMeta().Cost;
        for (let i = 1; i < this.upgradeLevel; i++) {
            cost += this.getUpgradeCost(i);
        }
        return Math.ceil(cost * damaged);
    }
    isUpgradeAllowed() {
        return this.upgradeLevel < BUILDING_MAX_UPGRADE_LEVEL;
    }
    getUpgradeAllowedByWave() {
        var _a;
        return ((_a = this.getMeta().AllowByWave) !== null && _a !== void 0 ? _a : 1) + this.upgradeLevel;
    }
    upgrade() {
        if (!this.isUpgradeAllowed()) {
            return;
        }
        const waveNumber = this.getUpgradeAllowedByWave();
        if (waveNumber > this.scene.wave.number) {
            this.scene.game.screen.notice(NoticeType.ERROR, `Upgrade will be available on ${waveNumber} wave`);
            return;
        }
        const cost = this.getUpgradeCost();
        if (this.scene.player.resources < cost) {
            this.scene.game.screen.notice(NoticeType.ERROR, 'Not enough resources');
            return;
        }
        this.upgradeLevel++;
        this.addUpgradeIcon();
        this.updateActionArea();
        this.upgradeHealth();
        this.setFrame(this.upgradeLevel - 1);
        this.emit(BuildingEvents.UPGRADE);
        this.scene.builder.emit(BuilderEvents.UPGRADE, this);
        this.scene.player.takeResources(cost);
        const experience = progressionLinear({
            defaultValue: DIFFICULTY.BUILDING_UPGRADE_EXPERIENCE,
            scale: DIFFICULTY.BUILDING_UPGRADE_EXPERIENCE_GROWTH,
            level: this.upgradeLevel,
        });
        this.scene.player.giveExperience(experience);
        this.scene.game.sound.play(BuildingAudio.UPGRADE);
        this.scene.game.tutorial.complete(TutorialStep.UPGRADE_BUILDING);
    }
    repair() {
        if (this.live.isMaxHealth()) {
            return;
        }
        const cost = this.getRepairCost();
        if (this.scene.player.resources < cost) {
            this.scene.game.screen.notice(NoticeType.ERROR, 'Not enough resources');
            return;
        }
        this.live.heal();
        this.scene.player.takeResources(cost);
        this.scene.sound.play(BuildingAudio.REPAIR);
    }
    upgradeHealth() {
        const maxHealth = this.getMaxHealth();
        const addedHealth = maxHealth - this.live.maxHealth;
        this.live.setMaxHealth(maxHealth);
        this.live.addHealth(addedHealth);
    }
    getMaxHealth() {
        return progressionQuadratic({
            defaultValue: this.defaultHealth,
            scale: DIFFICULTY.BUILDING_HEALTH_GROWTH,
            level: this.upgradeLevel,
            roundTo: 100,
        });
    }
    getSavePayload() {
        return {
            variant: this.variant,
            position: this.positionAtMatrix,
            health: this.live.health,
            upgradeLevel: this.upgradeLevel,
        };
    }
    loadSavePayload(data) {
        this.upgradeLevel = data.upgradeLevel;
        this.updateActionArea();
        this.setFrame(this.upgradeLevel - 1);
        this.live.setMaxHealth(this.getMaxHealth());
        this.live.setHealth(data.health);
    }
    onDamage() {
        const audio = Phaser.Utils.Array.GetRandom([
            BuildingAudio.DAMAGE_1,
            BuildingAudio.DAMAGE_2,
        ]);
        if (this.scene.game.sound.getAll(audio).length < 3) {
            this.scene.game.sound.play(audio);
        }
        if (!this.scene.game.isSettingEnabled(GameSettings.EFFECTS)) {
            return;
        }
        new Effect(this.scene, {
            texture: EffectTexture.DAMAGE,
            position: this.getTopCenterByLevel(),
            depth: this.depth + 1,
            rate: 14,
        });
    }
    onDead() {
        this.break();
    }
    focus() {
        this.toFocus = true;
        if (this.isFocused
            || this.scene.player.live.isDead()
            || this.scene.builder.isBuild) {
            return;
        }
        this.isFocused = true;
    }
    unfocus() {
        this.toFocus = false;
        if (!this.isFocused) {
            return;
        }
        this.isFocused = false;
    }
    getPositionOnGround() {
        return {
            x: this.x,
            y: this.y + LEVEL_TILE_SIZE.height * 0.5,
        };
    }
    addAlertIcon() {
        if (this.alertIcon) {
            return;
        }
        this.alertIcon = this.scene.add.image(this.x, this.y, BuildingIcon.ALERT);
        this.alertIcon.setDepth(this.depth + 1);
        this.alertTween = this.scene.tweens.add({
            targets: this.alertIcon,
            alpha: { from: 1.0, to: 0.0 },
            duration: 500,
            ease: 'Linear',
            yoyo: true,
            repeat: -1,
        });
    }
    removeAlertIcon() {
        var _a;
        if (!this.alertIcon) {
            return;
        }
        this.alertIcon.destroy();
        this.alertIcon = null;
        (_a = this.alertTween) === null || _a === void 0 ? void 0 : _a.destroy();
        this.alertTween = null;
    }
    addUpgradeIcon() {
        if (this.upgradeIcon) {
            this.removeUpgradeIcon();
        }
        this.upgradeIcon = this.scene.add.image(this.x, this.y, BuildingIcon.UPGRADE);
        this.upgradeIcon.setDepth(this.depth + 1);
        this.upgradeTween = this.scene.tweens.add({
            targets: this.upgradeIcon,
            y: { from: this.y, to: this.y - 32 },
            alpha: { from: 1.0, to: 0.0 },
            duration: 500,
            ease: 'Linear',
            onComplete: () => {
                this.removeUpgradeIcon();
            },
        });
    }
    removeUpgradeIcon() {
        var _a;
        if (!this.upgradeIcon) {
            return;
        }
        this.upgradeIcon.destroy();
        this.upgradeIcon = null;
        (_a = this.upgradeTween) === null || _a === void 0 ? void 0 : _a.destroy();
        this.upgradeTween = null;
    }
    select() {
        if (!this.isFocused || this.isSelected) {
            return;
        }
        // Need for fix events order
        if (this.scene.builder.selectedBuilding) {
            this.scene.builder.selectedBuilding.unselect();
        }
        this.scene.builder.selectedBuilding = this;
        this.isSelected = true;
        if (this.actionsArea) {
            this.actionsArea.setVisible(true);
        }
        this.scene.events.emit(WorldEvents.SELECT_BUILDING, this);
    }
    unselect() {
        if (!this.isSelected) {
            return;
        }
        this.scene.builder.selectedBuilding = null;
        this.isSelected = false;
        if (this.actionsArea) {
            this.actionsArea.setVisible(false);
        }
        this.scene.events.emit(WorldEvents.UNSELECT_BUILDING, this);
    }
    getTopCenterByLevel() {
        return {
            x: this.x,
            y: this.y - 6,
        };
    }
    setOutline(state) {
        if (this.outlineState === state) {
            return;
        }
        if (state === BuildingOutlineState.NONE) {
            this.removeShader('OutlineShader');
        }
        else {
            const params = {
                [BuildingOutlineState.FOCUSED]: { size: 2.0, color: 0xffffff },
                [BuildingOutlineState.SELECTED]: { size: 4.0, color: 0xd0ff4f },
            }[state];
            if (this.outlineState === BuildingOutlineState.NONE) {
                this.addShader('OutlineShader', params);
            }
            else {
                this.updateShader('OutlineShader', params);
            }
        }
        this.outlineState = state;
    }
    updateOutline() {
        let outlineState = BuildingOutlineState.NONE;
        if (this.isSelected) {
            outlineState = BuildingOutlineState.SELECTED;
        }
        else if (this.isFocused) {
            outlineState = BuildingOutlineState.FOCUSED;
        }
        this.setOutline(outlineState);
    }
    addActionArea() {
        if (!this.radius || this.actionsArea) {
            return;
        }
        const position = this.getPositionOnGround();
        this.actionsArea = this.scene.add.ellipse(position.x, position.y);
        this.actionsArea.setStrokeStyle(2, 0xffffff, 0.5);
        this.actionsArea.setFillStyle(0xffffff, 0.2);
        this.actionsArea.setVisible(false);
        this.updateActionArea();
        this.on(Phaser.GameObjects.Events.DESTROY, () => {
            var _a;
            (_a = this.actionsArea) === null || _a === void 0 ? void 0 : _a.destroy();
        });
    }
    updateActionArea() {
        if (!this.actionsArea) {
            return;
        }
        const d = this.getActionsRadius() * 2;
        this.actionsArea.setSize(d, d * LEVEL_TILE_SIZE.persperctive);
        this.actionsArea.setDepth(WORLD_DEPTH_EFFECT);
        this.actionsArea.updateDisplayOrigin();
    }
    break() {
        this.scene.sound.play(BuildingAudio.DEAD);
        if (this.scene.game.isSettingEnabled(GameSettings.EFFECTS)) {
            new Effect(this.scene, {
                texture: EffectTexture.SMOKE,
                position: this.getPositionOnGround(),
                depth: this.depth + 1,
                rate: 18,
            });
        }
        this.destroy();
    }
    startBuildProcess() {
        this.addBuildBar();
        this.addBuildTimer();
        this.setActive(false);
        this.setAlpha(0.5);
    }
    completeBuildProcess() {
        this.stopBuildProcess();
        this.setActive(true);
        this.setAlpha(1.0);
        this.scene.builder.emit(BuilderEvents.BUILD, this);
    }
    stopBuildProcess() {
        this.removeBuildBar();
        this.removeBuildTimer();
    }
    addBuildTimer() {
        const target = BUILDING_BUILD_DURATION / 50;
        let progress = 0;
        this.buildTimer = this.scene.time.addEvent({
            delay: 50,
            repeat: target,
            callback: () => {
                progress++;
                this.setAlpha(this.alpha + (0.5 / target));
                if (progress === target) {
                    this.completeBuildProcess();
                }
                else if (this.buildBar) {
                    const bar = this.buildBar.getAt(1);
                    const value = progress / target;
                    bar.setSize((this.buildBar.width - 2) * value, this.buildBar.height - 2);
                }
            },
        });
    }
    removeBuildTimer() {
        if (!this.buildTimer) {
            return;
        }
        this.buildTimer.destroy();
        this.buildTimer = null;
    }
    addBuildBar() {
        if (this.buildBar) {
            return;
        }
        const width = 20;
        const body = this.scene.add.rectangle(0, 0, width, 5, 0x000000);
        body.setOrigin(0.0, 0.0);
        const bar = this.scene.add.rectangle(1, 1, 0, 0, 0xffffff);
        bar.setOrigin(0.0, 0.0);
        this.buildBar = this.scene.add.container(-width / 2, 0);
        this.buildBar.setSize(body.width, body.height);
        this.buildBar.add([body, bar]);
        this.buildBar.setPosition(this.x - body.width / 2, this.y + 20);
        this.buildBar.setDepth(this.depth + LEVEL_TILE_SIZE.height * 0.5);
    }
    removeBuildBar() {
        if (!this.buildBar) {
            return;
        }
        this.buildBar.destroy();
        this.buildBar = null;
    }
    handleKeyboard() {
        var _a, _b, _c, _d;
        const handler = (callback) => (event) => {
            if (this.isSelected
                || (this.isFocused && this.scene.builder.selectedBuilding === null)) {
                callback();
                event.preventDefault();
            }
        };
        const handleRepair = handler(() => this.repair());
        const handleBreak = handler(() => this.break());
        const handleUpgrade = handler(() => this.upgrade());
        (_a = this.scene.input.keyboard) === null || _a === void 0 ? void 0 : _a.on(CONTROL_KEY.BUILDING_REPEAR, handleRepair);
        (_b = this.scene.input.keyboard) === null || _b === void 0 ? void 0 : _b.on(CONTROL_KEY.BUILDING_DESTROY, handleBreak);
        (_c = this.scene.input.keyboard) === null || _c === void 0 ? void 0 : _c.on(CONTROL_KEY.BUILDING_UPGRADE, handleUpgrade);
        (_d = this.scene.input.keyboard) === null || _d === void 0 ? void 0 : _d.on(CONTROL_KEY.BUILDING_UPGRADE_ANALOG, handleUpgrade);
        this.on(Phaser.GameObjects.Events.DESTROY, () => {
            var _a, _b, _c, _d;
            (_a = this.scene.input.keyboard) === null || _a === void 0 ? void 0 : _a.off(CONTROL_KEY.BUILDING_REPEAR, handleRepair);
            (_b = this.scene.input.keyboard) === null || _b === void 0 ? void 0 : _b.off(CONTROL_KEY.BUILDING_DESTROY, handleBreak);
            (_c = this.scene.input.keyboard) === null || _c === void 0 ? void 0 : _c.off(CONTROL_KEY.BUILDING_UPGRADE, handleUpgrade);
            (_d = this.scene.input.keyboard) === null || _d === void 0 ? void 0 : _d.off(CONTROL_KEY.BUILDING_UPGRADE_ANALOG, handleUpgrade);
        });
    }
    handlePointer() {
        const handleInput = (pointer) => {
            if (pointer.button === 0) {
                if (this.isFocused) {
                    this.select();
                }
                else {
                    this.unselect();
                }
            }
        };
        const handleClear = () => {
            this.unfocus();
            this.unselect();
        };
        this.scene.input.on(Phaser.Input.Events.POINTER_DOWN, handleInput);
        this.scene.game.events.on(GameEvents.FINISH, handleClear);
        this.scene.builder.on(BuilderEvents.BUILD_START, handleClear);
        this.on(Phaser.Input.Events.POINTER_OVER, this.focus, this);
        this.on(Phaser.Input.Events.POINTER_OUT, this.unfocus, this);
        this.on(Phaser.GameObjects.Events.DESTROY, () => {
            this.scene.input.off(Phaser.Input.Events.POINTER_DOWN, handleInput);
            this.scene.game.events.off(GameEvents.FINISH, handleClear);
            this.scene.builder.off(BuilderEvents.BUILD_START, handleClear);
        });
    }
}
registerAudioAssets(BuildingAudio);
registerImageAssets(BuildingIcon);
registerSpriteAssets(BuildingTexture, {
    width: LEVEL_TILE_SIZE.width,
    height: LEVEL_TILE_SIZE.height,
});
