import styled from 'styled-components';
import { InterfaceFont } from '~type/interface';
export const Wrapper = styled.div `
  color: #fff;
  font-family: ${InterfaceFont.PIXEL_TEXT};
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 1px;
  margin-top: -2px;
`;
