export var TutorialStep;
(function (TutorialStep) {
    TutorialStep["STOP_BUILD"] = "STOP_BUILD";
    TutorialStep["BUILD_TOWER_FIRE"] = "BUILD_TOWER_FIRE";
    TutorialStep["BUILD_AMMUNITION"] = "BUILD_AMMUNITION";
    TutorialStep["BUILD_GENERATOR"] = "BUILD_GENERATOR";
    TutorialStep["BUILD_RADAR"] = "BUILD_RADAR";
    TutorialStep["UPGRADE_BUILDING"] = "UPGRADE_BUILDING";
    TutorialStep["RELOAD_TOWER"] = "RELOAD_TOWER";
    TutorialStep["UPGRADE_SKILL"] = "UPGRADE_SKILL";
    TutorialStep["RESOURCES"] = "RESOURCES";
})(TutorialStep || (TutorialStep = {}));
export var TutorialStepState;
(function (TutorialStepState) {
    TutorialStepState["IDLE"] = "IDLE";
    TutorialStepState["IN_PROGRESS"] = "IN_PROGRESS";
    TutorialStepState["PAUSED"] = "PAUSED";
    TutorialStepState["COMPLETED"] = "COMPLETED";
})(TutorialStepState || (TutorialStepState = {}));
export var TutorialEvents;
(function (TutorialEvents) {
    TutorialEvents["BEG"] = "beg";
    TutorialEvents["END"] = "end";
})(TutorialEvents || (TutorialEvents = {}));
