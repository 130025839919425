import { BuildingVariant } from '~type/world/entities/building';
import { EnemyTexture } from '~type/world/entities/npc/enemy';
import { Enemy } from '../enemy';
export class EnemyImpure extends Enemy {
    constructor(scene, data) {
        super(scene, Object.assign(Object.assign({}, data), { texture: EnemyTexture.IMPURE, multipliers: {
                health: 1.3,
                damage: 0.7,
                speed: 0.8,
            } }));
    }
    update() {
        super.update();
        const isVisible = this.scene.builder
            .getBuildingsByVariant(BuildingVariant.RADAR)
            .some((building) => building.actionsAreaContains(this));
        this.setAlpha(isVisible ? 1.0 : 0.5);
    }
}
EnemyImpure.SpawnWaveRange = [8];
