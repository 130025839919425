import { Interface } from 'phaser-react-ui';
import { Scene } from '~game/scenes';
import { registerAudioAssets } from '~lib/assets';
import { GameScene } from '~type/game';
import { NoticeType, ScreenAudio, ScreenEvents, } from '~type/screen';
import { ScreenUI } from './interface';
export class Screen extends Scene {
    constructor() {
        super(GameScene.SCREEN);
    }
    create() {
        new Interface(this, ScreenUI);
    }
    notice(type, text) {
        this.events.emit(ScreenEvents.NOTICE, { type, text });
        if (type === NoticeType.ERROR) {
            this.game.sound.play(ScreenAudio.ERROR);
        }
    }
}
registerAudioAssets(ScreenAudio);
