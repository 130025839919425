import EventEmitter from 'events';
import { TutorialEvents, TutorialStepState, } from '~type/tutorial';
export class Tutorial extends EventEmitter {
    get isEnabled() { return this._isEnabled; }
    set isEnabled(v) { this._isEnabled = v; }
    constructor() {
        super();
        this.progress = {};
        this._isEnabled = true;
        this.setMaxListeners(0);
    }
    reset() {
        this.removeAllListeners();
        this.progress = {};
    }
    start(step) {
        if (this.progress[step] === TutorialStepState.IN_PROGRESS
            || this.progress[step] === TutorialStepState.COMPLETED) {
            return;
        }
        this.progress[step] = TutorialStepState.IN_PROGRESS;
        if (!this.isEnabled) {
            return;
        }
        this.emit(TutorialEvents.BEG, step);
        this.emit(`${TutorialEvents.BEG}_${step}`);
    }
    pause(step) {
        if (this.progress[step] !== TutorialStepState.IN_PROGRESS) {
            return;
        }
        this.progress[step] = TutorialStepState.PAUSED;
        if (!this.isEnabled) {
            return;
        }
        this.emit(TutorialEvents.END, step);
        this.emit(`${TutorialEvents.END}_${step}`);
    }
    complete(step) {
        if (this.progress[step] === TutorialStepState.COMPLETED) {
            return;
        }
        this.progress[step] = TutorialStepState.COMPLETED;
        if (!this.isEnabled) {
            return;
        }
        this.emit(TutorialEvents.END, step);
        this.emit(`${TutorialEvents.END}_${step}`);
    }
    state(step) {
        var _a;
        return (_a = this.progress[step]) !== null && _a !== void 0 ? _a : TutorialStepState.IDLE;
    }
    bind(step, callbacks) {
        if (callbacks.beg) {
            this.on(`${TutorialEvents.BEG}_${step}`, callbacks.beg);
        }
        if (callbacks.end) {
            this.on(`${TutorialEvents.END}_${step}`, callbacks.end);
        }
        return () => {
            if (callbacks.beg) {
                this.off(`${TutorialEvents.BEG}_${step}`, callbacks.beg);
            }
            if (callbacks.end) {
                this.off(`${TutorialEvents.END}_${step}`, callbacks.end);
            }
        };
    }
    bindAll(callbacks) {
        if (callbacks.beg) {
            this.on(TutorialEvents.BEG, callbacks.beg);
        }
        if (callbacks.end) {
            this.on(TutorialEvents.END, callbacks.end);
        }
        return () => {
            if (callbacks.beg) {
                this.off(TutorialEvents.BEG, callbacks.beg);
            }
            if (callbacks.end) {
                this.off(TutorialEvents.END, callbacks.end);
            }
        };
    }
    enable() {
        this.isEnabled = true;
        const states = Object.keys(this.progress);
        states.forEach((step) => {
            const state = this.state(step);
            if (state === TutorialStepState.IN_PROGRESS) {
                this.emit(TutorialEvents.BEG, step);
                this.emit(`${TutorialEvents.BEG}_${step}`);
            }
        });
    }
    disable() {
        const states = Object.keys(this.progress);
        states.forEach((step) => {
            const state = this.state(step);
            if (state === TutorialStepState.IN_PROGRESS) {
                this.emit(TutorialEvents.END, step);
                this.emit(`${TutorialEvents.END}_${step}`);
            }
        });
        this.isEnabled = false;
    }
}
