import { DIFFICULTY } from '~const/world/difficulty';
import { progressionQuadratic } from '~lib/difficulty';
import { EnemyTexture } from '~type/world/entities/npc/enemy';
import { Enemy } from '../enemy';
export class EnemyOverlord extends Enemy {
    constructor(scene, data) {
        super(scene, Object.assign(Object.assign({}, data), { texture: EnemyTexture.OVERLORD, multipliers: {
                health: 1.0,
                damage: 0.6,
                speed: 0.8,
            } }));
        const armour = progressionQuadratic({
            defaultValue: DIFFICULTY.ENEMY_ARMOUR * scene.game.getDifficultyMultiplier(),
            scale: DIFFICULTY.ENEMY_ARMOUR_GROWTH,
            level: scene.wave.number,
            retardationLevel: DIFFICULTY.ENEMY_ARMOUR_GROWTH_RETARDATION_LEVEL,
        });
        this.live.setMaxArmour(armour);
        this.live.setArmour(armour);
        this.addIndicator({
            color: 0x00d4ff,
            value: () => this.live.armour / this.live.maxArmour,
        });
    }
}
EnemyOverlord.SpawnWaveRange = [6];
