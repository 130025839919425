import { useScene, useSceneUpdate } from 'phaser-react-ui';
import React, { useState } from 'react';
import { BUILDINGS } from '~const/world/entities/buildings';
import { BuildingParams } from '~scene/system/interface/building-params';
import { Cost } from '~scene/system/interface/cost';
import { Text } from '~scene/system/interface/text';
import { GameScene } from '~type/game';
import { Alert, Head, Name, Wrapper, Body, } from './styles';
export const BuilderInfo = ({ variant }) => {
    const world = useScene(GameScene.WORLD);
    const [limit, setLimit] = useState(null);
    const [existCount, setExistCount] = useState(0);
    const [isAllowByWave, setAllowByWave] = useState(false);
    const [isAllowByTutorial, setAllowByTutorial] = useState(false);
    useSceneUpdate(world, () => {
        const currentIsAllowByWave = world.builder.isBuildingAllowByWave(variant);
        const currentIsAllowByTutorial = world.builder.isBuildingAllowByTutorial(variant);
        const currentLimit = world.builder.getBuildingLimit(variant);
        setAllowByWave(currentIsAllowByWave);
        setAllowByTutorial(currentIsAllowByTutorial);
        setLimit(currentLimit);
        if (currentLimit) {
            setExistCount(world.builder.getBuildingsByVariant(variant).length);
        }
    });
    return (isAllowByTutorial && (React.createElement(Wrapper, null,
        React.createElement(Head, null,
            React.createElement(Name, null, BUILDINGS[variant].Name),
            React.createElement(Cost, { type: "resources", value: BUILDINGS[variant].Cost, size: "large" })),
        React.createElement(Body, null,
            React.createElement(Text, null, BUILDINGS[variant].Description),
            isAllowByWave ? (!!limit && (React.createElement(Alert, { "$attention": existCount >= limit },
                "Current limit: ",
                existCount,
                "/",
                limit))) : (React.createElement(Alert, { "$attention": true },
                "Available from ",
                React.createElement("b", null, BUILDINGS[variant].AllowByWave),
                " wave")),
            React.createElement(BuildingParams, { list: BUILDINGS[variant].Params })))));
};
