import React, { useMemo } from 'react';
import { Positioner, Wrapper, Container, Key, } from './styles';
export const Hint = ({ children, side, width, align = 'center', }) => {
    const content = useMemo(() => {
        const array = Array.isArray(children) ? children : [children];
        return array.map((value, i) => {
            if (typeof value === 'string' && /\[[\w\s]+\]/.test(value)) {
                const [before, after] = value.split(/\[[\w\s]+\]/);
                return (React.createElement(React.Fragment, { key: i },
                    before,
                    React.createElement(Key, null, value.replace(/[\s\S]*\[([\w\s]+)\][\s\S]*/, '$1').toUpperCase()),
                    after));
            }
            return value;
        });
    }, [children]);
    return (React.createElement(Wrapper, { role: "hint" },
        React.createElement(Positioner, { "$side": side, "$align": align },
            React.createElement(Container, { "$width": width }, content))));
};
