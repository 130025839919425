export var TileType;
(function (TileType) {
    TileType["MAP"] = "MAP";
    TileType["BUILDING"] = "BUILDING";
    TileType["CRYSTAL"] = "CRYSTAL";
    TileType["SCENERY"] = "SCENERY";
})(TileType || (TileType = {}));
export var SpawnTarget;
(function (SpawnTarget) {
    SpawnTarget["ENEMY"] = "ENEMY";
    SpawnTarget["PLAYER"] = "PLAYER";
    SpawnTarget["SCENERY"] = "SCENERY";
    SpawnTarget["CRYSTAL"] = "CRYSTAL";
})(SpawnTarget || (SpawnTarget = {}));
export var LevelPlanet;
(function (LevelPlanet) {
    LevelPlanet["EARTH"] = "EARTH";
    LevelPlanet["MOON"] = "MOON";
    LevelPlanet["MARS"] = "MARS";
})(LevelPlanet || (LevelPlanet = {}));
export var LevelSceneryTexture;
(function (LevelSceneryTexture) {
    LevelSceneryTexture["EARTH"] = "level/earth/scenery";
    LevelSceneryTexture["MOON"] = "level/moon/scenery";
    LevelSceneryTexture["MARS"] = "level/mars/scenery";
})(LevelSceneryTexture || (LevelSceneryTexture = {}));
export var LevelTilesetTexture;
(function (LevelTilesetTexture) {
    LevelTilesetTexture["EARTH"] = "level/earth/tileset";
    LevelTilesetTexture["MOON"] = "level/moon/tileset";
    LevelTilesetTexture["MARS"] = "level/mars/tileset";
})(LevelTilesetTexture || (LevelTilesetTexture = {}));
export var BiomeType;
(function (BiomeType) {
    BiomeType["WATER"] = "WATER";
    BiomeType["SAND"] = "SAND";
    BiomeType["GRASS"] = "GRASS";
    BiomeType["RUBBLE"] = "RUBBLE";
    BiomeType["MOUNT"] = "MOUNT";
    BiomeType["SNOW"] = "SNOW";
})(BiomeType || (BiomeType = {}));
