import { useGame } from 'phaser-react-ui';
import React, { useState, useEffect } from 'react';
import { Hint } from '~scene/system/interface/hint';
import { TutorialStep } from '~type/tutorial';
import { Wrapper } from './styles';
export const GeneralHints = () => {
    const game = useGame();
    const [hint, setHint] = useState(null);
    const showHint = (step) => {
        switch (step) {
            case TutorialStep.STOP_BUILD: {
                return setHint('Use [Right click] to stop build');
            }
        }
    };
    const hideHint = (step) => {
        switch (step) {
            case TutorialStep.STOP_BUILD: {
                return setHint(null);
            }
        }
    };
    useEffect(() => game.tutorial.bindAll({
        beg: showHint,
        end: hideHint,
    }), []);
    return (hint && (React.createElement(Wrapper, null,
        React.createElement(Hint, { side: "top", align: "center" }, hint))));
};
