import Phaser from 'phaser';
import { DEBUG_MODS } from '~const/game';
import { WORLD_COLLIDE_SPEED_FACTOR, WORLD_DEPTH_DEBUG } from '~const/world';
import { Live } from '~lib/live';
import { equalPositions } from '~lib/utils';
import { Particles } from '~scene/world/effects';
import { Level } from '~scene/world/level';
import { GameFlag, GameSettings } from '~type/game';
import { LiveEvents } from '~type/live';
import { ParticlesTexture } from '~type/world/effects';
export class Sprite extends Phaser.Physics.Arcade.Sprite {
    get positionAtMatrix() { return this._positionAtMatrix; }
    set positionAtMatrix(v) { this._positionAtMatrix = v; }
    constructor(scene, { texture, positionAtMatrix, health, speed, frame = 0, }) {
        const positionAtWorld = Level.ToWorldPosition(Object.assign(Object.assign({}, positionAtMatrix), { z: 0 }));
        super(scene, positionAtWorld.x, positionAtWorld.y, texture, frame);
        this.gamut = 0;
        this.speed = 0;
        this.currentBiome = null;
        this.collisionTargets = [];
        this.collisionHandler = null;
        this.collisionGround = false;
        this.indicators = [];
        this.positionDebug = null;
        scene.add.existing(this);
        this.positionAtMatrix = positionAtMatrix;
        this.live = new Live({ health });
        this.container = this.scene.add.container(this.x, this.y);
        this.speed = speed;
        this.scene.physics.world.enable(this, Phaser.Physics.Arcade.DYNAMIC_BODY);
        this.setOrigin(0.5, 1.0);
        this.setImmovable(true);
        this.setPushable(false);
        this.addDebugPosition();
        this.live.on(LiveEvents.DAMAGE, this.onDamage.bind(this));
        this.live.on(LiveEvents.DEAD, this.onDead.bind(this));
        this.on(Phaser.GameObjects.Events.DESTROY, () => {
            this.container.destroy();
            this.live.removeAllListeners();
        });
    }
    update() {
        var _a;
        super.update();
        const positionOnGround = this.getPositionOnGround();
        const depth = Level.GetDepth(positionOnGround.y, 1);
        const positionOfTop = this.getTopCenter();
        this.positionAtMatrix = Level.ToMatrixPosition(positionOnGround);
        this.currentBiome = this.scene.level.map.getAt(this.positionAtMatrix);
        this.setDepth(depth);
        this.container.setDepth(depth + 19);
        this.container.setPosition(positionOfTop.x, ((_a = positionOfTop === null || positionOfTop === void 0 ? void 0 : positionOfTop.y) !== null && _a !== void 0 ? _a : 0) - 10);
        this.container.setAlpha(this.alpha);
        this.container.setVisible(this.visible);
        this.updateIndicators();
        this.drawDebugGroundPosition();
    }
    isStopped() {
        return equalPositions(this.body.velocity, { x: 0, y: 0 });
    }
    getAllPositionsAtMatrix() {
        return this.getProjectionOnGround().map((point) => Level.ToMatrixPosition(point));
    }
    addCollider(target, mode, callback) {
        this.scene.physics.add[mode](this, this.scene.getEntitiesGroup(target), (_, sprite) => {
            callback(sprite);
        });
    }
    setTilesCollision(targets, handler) {
        this.collisionTargets = targets;
        this.collisionHandler = handler;
    }
    setTilesGroundCollision(state) {
        this.collisionGround = state;
    }
    handleCollide(direction) {
        const tile = this.getCollidedTile(direction);
        if (this.collisionHandler && tile instanceof Phaser.GameObjects.Image) {
            this.collisionHandler(tile);
        }
        return Boolean(tile);
    }
    getCollidedTile(direction) {
        var _a;
        if (this.collisionTargets.length === 0 && !this.collisionGround) {
            return false;
        }
        const friction = (this.collisionGround && ((_a = this.currentBiome) === null || _a === void 0 ? void 0 : _a.friction)) || 1;
        const speedPerFrame = (this.speed / friction) * (WORLD_COLLIDE_SPEED_FACTOR * this.scene.deltaTime);
        const offset = this.scene.physics.velocityFromAngle(direction, speedPerFrame);
        // Check ground collision
        if (this.collisionGround) {
            const currentPositionAtWorld = this.getPositionOnGround();
            const positionAtMatrix = Level.ToMatrixPosition({
                x: currentPositionAtWorld.x + offset.x,
                y: currentPositionAtWorld.y + offset.y,
            });
            const biome = this.scene.level.map.getAt(positionAtMatrix);
            if (!(biome === null || biome === void 0 ? void 0 : biome.solid)) {
                return true;
            }
        }
        // Check wall collision
        if (this.collisionTargets.length > 0) {
            const positions = this.getProjectionOnGround();
            for (const position of positions) {
                const positionAtMatrix = Level.ToMatrixPosition({
                    x: position.x + offset.x,
                    y: position.y + offset.y,
                });
                const tile = this.scene.level.getTileWithType(Object.assign(Object.assign({}, positionAtMatrix), { z: 1 }), this.collisionTargets);
                if (tile) {
                    return tile;
                }
            }
        }
        return false;
    }
    getPositionOnGround() {
        return {
            x: this.x,
            y: this.y - this.getGamutOffset(),
        };
    }
    getBodyOffset() {
        return {
            x: 0,
            y: this.body ? (this.body.center.y - this.y) : 0,
        };
    }
    getGamutOffset() {
        return this.gamut * this.scaleY * 0.5;
    }
    getProjectionOnGround() {
        const count = 8;
        const rX = this.displayWidth * 0.4;
        const rY = this.getGamutOffset();
        const l = Phaser.Math.PI2 / count;
        const position = this.getPositionOnGround();
        const points = [];
        for (let u = 0; u < count; u++) {
            points.push({
                x: position.x + Math.sin(u * l) * rX,
                y: position.y - Math.cos(u * l) * rY,
            });
        }
        return points;
    }
    addIndicator(data) {
        var _a;
        const width = (_a = data.size) !== null && _a !== void 0 ? _a : this.displayWidth;
        const body = this.scene.add.rectangle(0, 0, width, 5, 0x000000);
        body.setOrigin(0.0, 0.0);
        const bar = this.scene.add.rectangle(1, 1, 0, 0, data.color);
        bar.setOrigin(0.0, 0.0);
        const container = this.scene.add.container(-width / 2, this.indicators.length * -6);
        container.setSize(body.width, body.height);
        container.add([body, bar]);
        this.container.add(container);
        this.indicators.push({ container, value: data.value });
    }
    updateIndicators() {
        this.indicators.forEach((indicator, index) => {
            const value = indicator.value();
            if (value <= 0.0) {
                indicator.container.destroy();
                this.indicators.splice(index, 1);
            }
            else {
                const bar = indicator.container.getAt(1);
                bar.setSize((indicator.container.width - 2) * value, indicator.container.height - 2);
            }
        });
    }
    addDebugPosition() {
        if (!DEBUG_MODS.position) {
            return;
        }
        this.positionDebug = this.scene.add.graphics();
        this.positionDebug.setDepth(WORLD_DEPTH_DEBUG);
        this.on(Phaser.GameObjects.Events.DESTROY, () => {
            var _a;
            (_a = this.positionDebug) === null || _a === void 0 ? void 0 : _a.destroy();
        });
    }
    drawDebugGroundPosition() {
        if (!this.positionDebug) {
            return;
        }
        this.positionDebug.clear();
        // Position
        this.positionDebug.lineStyle(1, 0xff0000);
        this.positionDebug.beginPath();
        const position = this.getPositionOnGround();
        this.positionDebug.moveTo(position.x, position.y);
        this.positionDebug.lineTo(position.x + 10, position.y);
        this.positionDebug.moveTo(position.x, position.y);
        this.positionDebug.lineTo(position.x, position.y + 10);
        this.positionDebug.closePath();
        this.positionDebug.strokePath();
        // Projection
        this.positionDebug.lineStyle(1, 0xffffff);
        this.positionDebug.beginPath();
        const positions = this.getProjectionOnGround();
        const points = [
            ...positions,
            positions[0],
        ];
        for (let i = 1; i < points.length; i++) {
            this.positionDebug.moveTo(points[i - 1].x, points[i - 1].y);
            this.positionDebug.lineTo(points[i].x, points[i].y);
        }
        this.positionDebug.closePath();
        this.positionDebug.strokePath();
    }
    onDamage() {
        if (!this.scene.game.isSettingEnabled(GameSettings.EFFECTS)
            || this.scene.game.isFlagEnabled(GameFlag.NO_BLOOD)) {
            return;
        }
        new Particles(this, {
            key: 'blood',
            texture: ParticlesTexture.BIT_SOFT,
            params: {
                duration: 200,
                follow: this,
                followOffset: this.getBodyOffset(),
                lifespan: { min: 100, max: 250 },
                scale: { start: 1.0, end: 0.5 },
                speed: 60,
                maxAliveParticles: 6,
                tint: 0xdd1e1e,
            },
        });
    }
    onDead() {
        this.anims.stop();
        this.scene.tweens.add({
            targets: [this, this.container],
            alpha: 0.0,
            duration: 250,
            onComplete: () => {
                this.destroy();
            },
        });
    }
}
