import { getModifiedObject, useScene, useSceneUpdate } from 'phaser-react-ui';
import React, { useState } from 'react';
import { PLAYER_SKILLS } from '~const/world/entities/player';
import { Cost } from '~scene/system/interface/cost';
import { Text } from '~scene/system/interface/text';
import { GameScene } from '~type/game';
import { Item, Info, Action, Label, Level, Button, Limit, } from './styles';
export const UpgradesListItem = ({ type }) => {
    const world = useScene(GameScene.WORLD);
    const [data, setData] = useState(null);
    const limit = (data === null || data === void 0 ? void 0 : data.currentLevel) && data.maxLevel <= data.currentLevel;
    const onUpgrade = () => {
        world.player.upgrade(type);
    };
    useSceneUpdate(world, () => {
        const newData = Object.assign(Object.assign({}, PLAYER_SKILLS[type]), { experience: world.player.getExperienceToUpgrade(type), currentLevel: world.player.upgradeLevel[type] });
        setData((current) => getModifiedObject(current, newData));
    });
    return (data && (React.createElement(Item, null,
        React.createElement(Info, null,
            React.createElement(Label, null, data.label),
            React.createElement(Text, null, data.description),
            React.createElement(Level, null,
                "LEVEL ",
                React.createElement("b", null, data.currentLevel))),
        limit ? (React.createElement(Action, null,
            React.createElement(Limit, null,
                "MAX",
                React.createElement("br", null),
                "LEVEL"))) : (React.createElement(Action, { onClick: onUpgrade, "$active": true },
            React.createElement(Button, null, "UPGRADE"),
            React.createElement(Cost, { type: "experience", value: data.experience, size: 'large' }))))));
};
