import { useGame } from 'phaser-react-ui';
import React, { useState } from 'react';
import { Setting } from '~scene/system/interface/setting';
export const Param = ({ type, data }) => {
    const game = useGame();
    const [currentValue, setCurrentValue] = useState(game.settings[type]);
    const updateSetting = (value) => {
        game.updateSetting(type, value);
        setCurrentValue(value);
    };
    return (React.createElement(Setting, { label: data.description, values: data.values, currentValue: currentValue, onChange: updateSetting }));
};
