import { WORLD_DEPTH_EFFECT } from '~const/world';
import { registerImageAssets } from '~lib/assets';
import { ParticlesTexture, } from '~type/world/effects';
export class Particles {
    constructor(parent, { key, positionAtWorld, texture, params, }) {
        var _a, _b;
        this.scene = parent.scene;
        this.parent = parent;
        this.key = key;
        this.emitter = this.scene.add.particles((_a = positionAtWorld === null || positionAtWorld === void 0 ? void 0 : positionAtWorld.x) !== null && _a !== void 0 ? _a : 0, (_b = positionAtWorld === null || positionAtWorld === void 0 ? void 0 : positionAtWorld.y) !== null && _b !== void 0 ? _b : 0, texture, params);
        this.emitter.setDepth(WORLD_DEPTH_EFFECT);
        if (!this.parent.effects) {
            this.parent.effects = {};
        }
        else if (this.parent.effects[key]) {
            this.parent.effects[key].destroy();
        }
        this.parent.effects[key] = this;
        this.parent.on(Phaser.GameObjects.Events.DESTROY, () => {
            this.destroy();
        });
        if (params.duration) {
            this.emitter.on(Phaser.GameObjects.Particles.Events.COMPLETE, () => {
                this.destroy();
            });
        }
    }
    destroy() {
        var _a;
        (_a = this.parent.effects) === null || _a === void 0 ? true : delete _a[this.key];
        this.emitter.destroy();
        this.parent.off(Phaser.GameObjects.Events.DESTROY, this.destroy);
    }
}
registerImageAssets(ParticlesTexture);
