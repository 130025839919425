import { EnemyTexture } from '~type/world/entities/npc/enemy';
import { Enemy } from '../enemy';
export class EnemyBat extends Enemy {
    constructor(scene, data) {
        super(scene, Object.assign(Object.assign({}, data), { texture: EnemyTexture.BAT, multipliers: {
                health: 0.35,
                damage: 0.2,
                speed: 1.0,
            } }));
    }
}
EnemyBat.SpawnWaveRange = [1, 4];
