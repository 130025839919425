import EventEmitter from 'events';
import Phaser from 'phaser';
import { WORLD_DEPTH_EFFECT } from '~const/world';
import { DIFFICULTY } from '~const/world/difficulty';
import { BUILDINGS } from '~const/world/entities/buildings';
import { LEVEL_TILE_SIZE } from '~const/world/level';
import { getStage, equalPositions } from '~lib/utils';
import { Level } from '~scene/world/level';
import { NoticeType } from '~type/screen';
import { TutorialStep, TutorialStepState } from '~type/tutorial';
import { BuilderEvents } from '~type/world/builder';
import { EntityType } from '~type/world/entities';
import { BuildingAudio, BuildingVariant, } from '~type/world/entities/building';
import { BiomeType, TileType } from '~type/world/level';
export class Builder extends EventEmitter {
    get isBuild() { return this._isBuild; }
    set isBuild(v) { this._isBuild = v; }
    get variant() { return this._variant; }
    set variant(v) { this._variant = v; }
    get radius() { return this._radius; }
    set radius(v) { this._radius = v; }
    constructor(scene) {
        super();
        this._isBuild = false;
        this.selectedBuilding = null;
        this.buildArea = null;
        this.buildingPreview = null;
        this.buildings = {};
        this._variant = null;
        this._radius = DIFFICULTY.BUILDER_BUILD_AREA;
        this.scene = scene;
        this.setMaxListeners(0);
        this.handleKeyboard();
        this.handleTutorial();
    }
    destroy() {
        this.close();
        this.removeAllListeners();
    }
    update() {
        if (this.isCanBuild()) {
            if (this.isBuild) {
                this.updateBuildAreaPosition();
                this.updateBuildingPreview();
            }
            else {
                this.open();
            }
        }
        else if (this.isBuild) {
            this.close();
        }
    }
    setBuildingVariant(variant) {
        if (this.variant === variant
            || !this.isBuildingAllowByTutorial(variant)) {
            return;
        }
        const BuildingInstance = BUILDINGS[variant];
        if (!this.isBuildingAllowByWave(variant)) {
            this.scene.game.screen.notice(NoticeType.ERROR, `Will be available on ${BuildingInstance.AllowByWave} wave`);
            return;
        }
        this.scene.sound.play(BuildingAudio.SELECT);
        this.variant = variant;
        if (this.buildingPreview) {
            this.buildingPreview.setTexture(BuildingInstance.Texture);
        }
    }
    unsetBuildingVariant() {
        if (this.variant === null) {
            return;
        }
        this.scene.sound.play(BuildingAudio.UNSELECT);
        this.scene.game.tutorial.complete(TutorialStep.STOP_BUILD);
        this.clearBuildingVariant();
    }
    addFoundation(position) {
        const newBiome = this.scene.level.getBiome(BiomeType.RUBBLE);
        if (!newBiome) {
            return;
        }
        for (let y = position.y - 1; y <= position.y + 1; y++) {
            for (let x = position.x - 1; x <= position.x + 1; x++) {
                const biome = this.scene.level.map.getAt({ x, y });
                if (biome && biome.solid && biome.type !== BiomeType.RUBBLE) {
                    // Replace biome
                    const index = Array.isArray(newBiome.tileIndex)
                        ? Phaser.Math.Between(...newBiome.tileIndex)
                        : newBiome.tileIndex;
                    this.scene.level.groundLayer.putTileAt(index, x, y);
                    this.scene.level.map.replaceAt({ x, y }, newBiome);
                    // Remove scenery
                    const tile = this.scene.level.getTileWithType({ x, y, z: 1 }, TileType.SCENERY);
                    if (tile) {
                        tile.destroy();
                    }
                    // Remove effects
                    this.scene.level.effectsOnGround.forEach((effect) => {
                        const positionAtMatrix = Level.ToMatrixPosition(effect);
                        if (equalPositions(positionAtMatrix, { x, y })) {
                            effect.destroy();
                        }
                    });
                }
            }
        }
    }
    isBuildingAllowByTutorial(variant) {
        if (!this.scene.game.tutorial.isEnabled) {
            return true;
        }
        const links = [
            { step: TutorialStep.BUILD_TOWER_FIRE, variant: BuildingVariant.TOWER_FIRE },
            { step: TutorialStep.BUILD_GENERATOR, variant: BuildingVariant.GENERATOR },
        ];
        const current = links.find((link) => (this.scene.game.tutorial.state(link.step) === TutorialStepState.IN_PROGRESS));
        return (!current || current.variant === variant);
    }
    isBuildingAllowByWave(variant) {
        const waveAllowed = BUILDINGS[variant].AllowByWave;
        if (waveAllowed) {
            return (waveAllowed <= this.scene.wave.number);
        }
        return true;
    }
    getBuildingLimit(variant) {
        var _a;
        if (!BUILDINGS[variant].Limit) {
            return null;
        }
        const start = (_a = BUILDINGS[variant].AllowByWave) !== null && _a !== void 0 ? _a : 1;
        const limit = getStage(start, this.scene.wave.number);
        return limit;
    }
    getBuildingsByVariant(variant) {
        var _a;
        return ((_a = this.buildings[variant]) !== null && _a !== void 0 ? _a : []);
    }
    getAssumedPosition() {
        return Level.ToMatrixPosition({
            x: this.scene.input.activePointer.worldX,
            y: this.scene.input.activePointer.worldY,
        });
    }
    onMouseClick(pointer) {
        if (pointer.button === 0) {
            this.build();
        }
        else if (pointer.button === 2) {
            this.unsetBuildingVariant();
        }
    }
    open() {
        if (this.isBuild) {
            return;
        }
        this.createBuildArea();
        this.createBuildingPreview();
        this.scene.input.on(Phaser.Input.Events.POINTER_UP, this.onMouseClick, this);
        this.isBuild = true;
        this.emit(BuilderEvents.BUILD_START);
    }
    close() {
        if (!this.isBuild) {
            return;
        }
        this.scene.input.off(Phaser.Input.Events.POINTER_UP, this.onMouseClick);
        this.destroyBuildingPreview();
        this.destroyBuildArea();
        this.isBuild = false;
        this.emit(BuilderEvents.BUILD_STOP);
    }
    clearBuildingVariant() {
        this.close();
        this.variant = null;
    }
    switchBuildingVariant(index) {
        const variant = Object.values(BuildingVariant)[index];
        if (variant) {
            if (this.variant === variant) {
                this.unsetBuildingVariant();
            }
            else {
                this.setBuildingVariant(variant);
            }
        }
    }
    isCanBuild() {
        return (this.variant !== null
            && !this.scene.player.live.isDead());
    }
    isAllowBuild() {
        if (!this.buildArea) {
            return false;
        }
        const positionAtMatrix = this.getAssumedPosition();
        const positionAtWorldDown = Level.ToWorldPosition(Object.assign(Object.assign({}, positionAtMatrix), { z: 0 }));
        const offset = this.buildArea.getTopLeft();
        const inArea = this.buildArea.geom.contains(positionAtWorldDown.x - offset.x, positionAtWorldDown.y - offset.y);
        if (!inArea) {
            return false;
        }
        const biome = this.scene.level.map.getAt(positionAtMatrix);
        if (!(biome === null || biome === void 0 ? void 0 : biome.solid)) {
            return false;
        }
        const isFreeFromTile = this.scene.level.isFreePoint(Object.assign(Object.assign({}, positionAtMatrix), { z: 1 }));
        if (!isFreeFromTile) {
            return false;
        }
        let spritePositionsAtMatrix = this.scene.player.getAllPositionsAtMatrix();
        this.scene.getEntities(EntityType.NPC).forEach((npc) => {
            spritePositionsAtMatrix = spritePositionsAtMatrix.concat(npc.getAllPositionsAtMatrix());
        });
        const isFreeFromSprite = spritePositionsAtMatrix.every((point) => !equalPositions(positionAtMatrix, point));
        if (!isFreeFromSprite) {
            return false;
        }
        return true;
    }
    build() {
        if (!this.variant || !this.isAllowBuild()) {
            return;
        }
        const BuildingInstance = BUILDINGS[this.variant];
        if (this.isBuildingLimitReached(this.variant)) {
            this.scene.game.screen.notice(NoticeType.ERROR, `You have maximum ${BuildingInstance.Name}`);
            return;
        }
        if (this.scene.player.resources < BuildingInstance.Cost) {
            this.scene.game.screen.notice(NoticeType.ERROR, 'Not enough resources');
            return;
        }
        this.createBuilding({
            variant: this.variant,
            positionAtMatrix: this.getAssumedPosition(),
        });
        this.scene.player.takeResources(BuildingInstance.Cost);
        this.scene.player.giveExperience(DIFFICULTY.BUILDING_BUILD_EXPERIENCE);
        this.scene.sound.play(BuildingAudio.BUILD);
    }
    createBuilding(data) {
        const BuildingInstance = BUILDINGS[data.variant];
        const building = new BuildingInstance(this.scene, {
            instant: data.instant,
            positionAtMatrix: data.positionAtMatrix,
        });
        let list = this.buildings[data.variant];
        if (list) {
            list.push(building);
        }
        else {
            list = [building];
            this.buildings[data.variant] = list;
        }
        building.on(Phaser.GameObjects.Events.DESTROY, () => {
            if (list) {
                const index = list.indexOf(building);
                if (index !== -1) {
                    list.splice(index, 1);
                }
            }
        });
        return building;
    }
    isBuildingLimitReached(variant) {
        const limit = this.getBuildingLimit(variant);
        if (limit) {
            return (this.getBuildingsByVariant(variant).length >= limit);
        }
        return false;
    }
    createBuildArea() {
        this.buildArea = this.scene.add.ellipse();
        this.buildArea.setStrokeStyle(2, 0xffffff, 0.4);
        this.updateBuildAreaPosition();
        this.updateBuildAreaSize();
    }
    setBuildAreaRadius(radius) {
        this.radius = radius;
        if (this.buildArea) {
            this.updateBuildAreaSize();
        }
    }
    updateBuildAreaSize() {
        if (!this.buildArea) {
            return;
        }
        this.buildArea.setSize(this.radius * 2, this.radius * 2 * LEVEL_TILE_SIZE.persperctive);
        this.buildArea.updateDisplayOrigin();
        this.buildArea.setDepth(WORLD_DEPTH_EFFECT);
    }
    updateBuildAreaPosition() {
        if (!this.buildArea) {
            return;
        }
        const position = this.scene.player.getPositionOnGround();
        this.buildArea.setPosition(position.x, position.y);
    }
    destroyBuildArea() {
        if (!this.buildArea) {
            return;
        }
        this.buildArea.destroy();
        this.buildArea = null;
    }
    createBuildingPreview() {
        if (!this.variant) {
            return;
        }
        const BuildingInstance = BUILDINGS[this.variant];
        this.buildingPreview = this.scene.add.image(0, 0, BuildingInstance.Texture);
        this.buildingPreview.setOrigin(0.5, LEVEL_TILE_SIZE.origin);
        this.updateBuildingPreview();
    }
    updateBuildingPreview() {
        if (!this.buildingPreview) {
            return;
        }
        const positionAtMatrix = this.getAssumedPosition();
        const tilePosition = Object.assign(Object.assign({}, positionAtMatrix), { z: 1 });
        const positionAtWorld = Level.ToWorldPosition(tilePosition);
        const depth = Level.GetTileDepth(positionAtWorld.y, tilePosition.z) + 1;
        this.buildingPreview.setPosition(positionAtWorld.x, positionAtWorld.y);
        this.buildingPreview.setDepth(depth);
        this.buildingPreview.setAlpha(this.isAllowBuild() ? 1.0 : 0.25);
    }
    destroyBuildingPreview() {
        if (!this.buildingPreview) {
            return;
        }
        this.buildingPreview.destroy();
        this.buildingPreview = null;
    }
    handleKeyboard() {
        var _a;
        (_a = this.scene.input.keyboard) === null || _a === void 0 ? void 0 : _a.on(Phaser.Input.Keyboard.Events.ANY_KEY_UP, (event) => {
            if (Number(event.key)) {
                this.switchBuildingVariant(Number(event.key) - 1);
            }
        });
    }
    handleTutorial() {
        this.scene.game.tutorial.bind(TutorialStep.BUILD_TOWER_FIRE, {
            beg: () => {
                this.scene.setTimePause(true);
            },
            end: () => {
                this.scene.setTimePause(false);
                this.clearBuildingVariant();
            },
        });
        this.scene.game.tutorial.bind(TutorialStep.BUILD_GENERATOR, {
            beg: () => {
                this.scene.setTimePause(true);
            },
            end: () => {
                this.scene.setTimePause(false);
            },
        });
        this.scene.game.screen.events.on(Phaser.Interface.Events.MOUNT, () => {
            this.scene.game.tutorial.start(TutorialStep.BUILD_TOWER_FIRE);
        });
    }
}
