import { useGame } from 'phaser-react-ui';
import React from 'react';
import { Button } from '~scene/system/interface/button';
import { Overlay } from '~scene/system/interface/overlay';
import { Result } from './result';
import { Wrapper, Label } from './styles';
export const GameoverUI = ({ stat, record }) => {
    const game = useGame();
    const onRestartClick = () => {
        game.stopGame();
    };
    return (React.createElement(Overlay, null,
        React.createElement(Wrapper, null,
            React.createElement(Label, null, "GAME OVER"),
            React.createElement(Button, { onClick: onRestartClick, size: "large", view: "confirm" }, "Play again"),
            React.createElement(Result, { stat: stat, record: record }))));
};
GameoverUI.displayName = 'GameoverUI';
