import { EnemyTexture } from '~type/world/entities/npc/enemy';
import { Enemy } from '../enemy';
export class EnemyRisper extends Enemy {
    constructor(scene, data) {
        super(scene, Object.assign(Object.assign({}, data), { texture: EnemyTexture.RISPER, multipliers: {
                health: 0.7,
                damage: 0.5,
                speed: 1.0,
            } }));
    }
}
EnemyRisper.SpawnWaveRange = [4, 9];
