export var EnemyTexture;
(function (EnemyTexture) {
    EnemyTexture["BAT"] = "enemy/bat";
    EnemyTexture["SPIKE"] = "enemy/spike";
    EnemyTexture["RISPER"] = "enemy/risper";
    EnemyTexture["DEMON"] = "enemy/demon";
    EnemyTexture["OVERLORD"] = "enemy/overlord";
    EnemyTexture["BOSS"] = "enemy/boss";
    EnemyTexture["IMPURE"] = "enemy/impure";
    EnemyTexture["UNDEAD"] = "enemy/undead";
    EnemyTexture["TERMER"] = "enemy/termer";
})(EnemyTexture || (EnemyTexture = {}));
export var EnemyVariant;
(function (EnemyVariant) {
    EnemyVariant["BAT"] = "BAT";
    EnemyVariant["SPIKE"] = "SPIKE";
    EnemyVariant["RISPER"] = "RISPER";
    EnemyVariant["DEMON"] = "DEMON";
    EnemyVariant["OVERLORD"] = "OVERLORD";
    EnemyVariant["BOSS"] = "BOSS";
    EnemyVariant["IMPURE"] = "IMPURE";
    EnemyVariant["UNDEAD"] = "UNDEAD";
    EnemyVariant["TERMER"] = "TERMER";
})(EnemyVariant || (EnemyVariant = {}));
