export var InterfaceTextColor;
(function (InterfaceTextColor) {
    InterfaceTextColor["HOVER"] = "#FF9600";
    InterfaceTextColor["SUCCESS"] = "#A4E24D";
    InterfaceTextColor["WARN"] = "#ff7629";
    InterfaceTextColor["ERROR"] = "#ff6d6d";
})(InterfaceTextColor || (InterfaceTextColor = {}));
export var InterfaceBackgroundColor;
(function (InterfaceBackgroundColor) {
    InterfaceBackgroundColor["INFO"] = "#2A87DB";
    InterfaceBackgroundColor["WARN"] = "#ff7629";
    InterfaceBackgroundColor["WARN_DARK"] = "#da6921";
    InterfaceBackgroundColor["SUCCESS_LIGHT"] = "#A4E24D";
    InterfaceBackgroundColor["SUCCESS"] = "#64A226";
    InterfaceBackgroundColor["SUCCESS_DARK"] = "#4D8B1B";
    InterfaceBackgroundColor["ERROR_LIGHT"] = "#ff6d6d";
    InterfaceBackgroundColor["ERROR"] = "#db2323";
    InterfaceBackgroundColor["ERROR_DARK"] = "#BC1927";
    InterfaceBackgroundColor["WHITE_TRANSPARENT_15"] = "rgba(255, 255, 255, 0.15)";
    InterfaceBackgroundColor["WHITE_TRANSPARENT_75"] = "rgba(255, 255, 255, 0.75)";
    InterfaceBackgroundColor["BLACK"] = "#000000";
    InterfaceBackgroundColor["BLACK_TRANSPARENT_75"] = "rgba(0, 0, 0, 0.75)";
    InterfaceBackgroundColor["BLACK_TRANSPARENT_50"] = "rgba(0, 0, 0, 0.50)";
    InterfaceBackgroundColor["BLACK_TRANSPARENT_25"] = "rgba(0, 0, 0, 0.25)";
    InterfaceBackgroundColor["BLUE"] = "#18324f";
    InterfaceBackgroundColor["BLUE_TRANSPARENT"] = "#18324fcc";
    InterfaceBackgroundColor["BLUE_DARK"] = "#0b1621";
    InterfaceBackgroundColor["BLUE_DARK_TRANSPARENT"] = "#0b1621ee";
})(InterfaceBackgroundColor || (InterfaceBackgroundColor = {}));
export var InterfaceFont;
(function (InterfaceFont) {
    InterfaceFont["PIXEL_LABEL"] = "PixelLabel";
    InterfaceFont["PIXEL_TEXT"] = "PixelText";
})(InterfaceFont || (InterfaceFont = {}));
