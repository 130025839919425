import React from 'react';
import { Wrapper } from './styles';
export const About = () => (React.createElement(Wrapper, null,
    "Your task is to survive on chosen planet as many waves as possible.",
    React.createElement("br", null),
    "With each wave count of enemies and their characteristics will grow.",
    React.createElement("br", null),
    React.createElement("br", null),
    "Build walls to defend, towers to attack, ammunitions to reload towers",
    React.createElement("br", null),
    "and generators to get more resources.",
    React.createElement("br", null),
    React.createElement("br", null),
    "And also upgrade skills of your character and his assistant."));
