import { useGame } from 'phaser-react-ui';
import React, { useEffect, useState } from 'react';
import { Button } from '~scene/system/interface/button';
import { Hint } from '~scene/system/interface/hint';
import { TutorialStep } from '~type/tutorial';
import { UpgradesList } from './list';
import { Wrapper } from './styles';
export const Skills = () => {
    const game = useGame();
    const [isOpened, setOpened] = useState(false);
    const [hint, setHint] = useState(false);
    const onClickButton = (event) => {
        setOpened(!isOpened);
        event.stopPropagation();
    };
    const onClose = () => {
        setOpened(false);
    };
    useEffect(() => game.tutorial.bind(TutorialStep.UPGRADE_SKILL, {
        beg: () => setHint(true),
        end: () => setHint(false),
    }), []);
    return (React.createElement(Wrapper, null,
        React.createElement(Button, { onClick: onClickButton, view: isOpened ? 'active' : undefined, size: 'fixed' }, "SKILLS"),
        isOpened && React.createElement(UpgradesList, { onClose: onClose }),
        hint && !isOpened && (React.createElement(Hint, { side: "top", align: "left" }, "Click to upgrade skills"))));
};
