var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CONTROL_KEY } from '~const/controls';
import { Scene } from '~game/scenes';
import { getAssetsPack, loadFontFace } from '~lib/assets';
import { removeLoading, setLoadingStatus } from '~lib/state';
import { GameScene, GameState } from '~type/game';
import { InterfaceFont } from '~type/interface';
import { MenuPage } from '~type/menu';
export class System extends Scene {
    constructor() {
        super({
            key: GameScene.SYSTEM,
            pack: getAssetsPack(),
        });
        setLoadingStatus('ASSETS LOADING');
    }
    create() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            yield this.game.loadPayload();
            yield Promise.all([
                loadFontFace(InterfaceFont.PIXEL_LABEL, 'pixel_label.ttf'),
                loadFontFace(InterfaceFont.PIXEL_TEXT, 'pixel_text.ttf'),
            ]);
            removeLoading();
            this.scene.launch(GameScene.WORLD);
            this.scene.launch(GameScene.MENU, {
                defaultPage: MenuPage.NEW_GAME,
            });
            this.scene.bringToTop();
            (_a = this.input.keyboard) === null || _a === void 0 ? void 0 : _a.on(CONTROL_KEY.PAUSE, () => {
                if (this.game.isPaused) {
                    // System pause
                    return;
                }
                switch (this.game.state) {
                    case GameState.FINISHED: {
                        this.game.stopGame();
                        break;
                    }
                    case GameState.PAUSED: {
                        this.game.resumeGame();
                        break;
                    }
                    case GameState.STARTED: {
                        this.game.pauseGame();
                        break;
                    }
                }
            });
        });
    }
}
