import { EnemyTexture } from '~type/world/entities/npc/enemy';
import { Enemy } from '../enemy';
export class EnemyTermer extends Enemy {
    constructor(scene, data) {
        super(scene, Object.assign(Object.assign({}, data), { texture: EnemyTexture.TERMER, multipliers: {
                health: 1.4,
                damage: 1.1,
                speed: 0.9,
            } }));
    }
}
EnemyTermer.SpawnWaveRange = [11];
