import { useGame, useScene, useSceneUpdate } from 'phaser-react-ui';
import React, { useState } from 'react';
import { PLAYER_SUPERSKILLS } from '~const/world/entities/player';
import { Cost } from '~scene/system/interface/cost';
import { Text } from '~scene/system/interface/text';
import { GameScene, GameState } from '~type/game';
import { Container, Timeout, Info, Icon, Body, Head, Name, } from './styles';
export const SuperskillItem = ({ type }) => {
    const game = useGame();
    const world = useScene(GameScene.WORLD);
    const scene = useScene(GameScene.SYSTEM);
    const [isPaused, setPaused] = useState(false);
    const [isActive, setActive] = useState(false);
    const [cost, setCost] = useState(0);
    const onClick = () => {
        world.player.useSuperskill(type);
    };
    useSceneUpdate(scene, () => {
        setPaused(game.state === GameState.PAUSED);
        setActive(Boolean(world.player.activeSuperskills[type]));
        setCost(world.player.getSuperskillCost(type));
    });
    return (React.createElement(Container, { onClick: onClick, "$active": isActive },
        React.createElement(Info, null,
            React.createElement(Head, null,
                React.createElement(Name, null, type),
                React.createElement(Cost, { type: "resources", value: cost, size: "small" })),
            React.createElement(Body, null,
                React.createElement(Text, null, PLAYER_SUPERSKILLS[type].description))),
        isActive && (React.createElement(Timeout, { style: {
                animationDuration: `${PLAYER_SUPERSKILLS[type].duration}ms`,
                animationPlayState: isPaused ? 'paused' : 'running',
            } })),
        React.createElement(Icon, { src: `assets/sprites/feature/${type.toLowerCase()}.png` })));
};
