export var PlayerTexture;
(function (PlayerTexture) {
    PlayerTexture["PLAYER"] = "player";
})(PlayerTexture || (PlayerTexture = {}));
export var PlayerAudio;
(function (PlayerAudio) {
    PlayerAudio["UPGRADE"] = "player/upgrade";
    PlayerAudio["WALK"] = "player/walk";
    PlayerAudio["DEAD"] = "player/dead";
    PlayerAudio["DAMAGE_1"] = "player/damage_1";
    PlayerAudio["DAMAGE_2"] = "player/damage_2";
    PlayerAudio["DAMAGE_3"] = "player/damage_3";
})(PlayerAudio || (PlayerAudio = {}));
export var PlayerSkill;
(function (PlayerSkill) {
    PlayerSkill["MAX_HEALTH"] = "MAX_HEALTH";
    PlayerSkill["SPEED"] = "SPEED";
    PlayerSkill["BUILD_AREA"] = "BUILD_AREA";
    PlayerSkill["ASSISTANT"] = "ASSISTANT";
})(PlayerSkill || (PlayerSkill = {}));
export var MovementDirection;
(function (MovementDirection) {
    MovementDirection[MovementDirection["LEFT"] = -1] = "LEFT";
    MovementDirection[MovementDirection["RIGHT"] = 1] = "RIGHT";
    MovementDirection[MovementDirection["UP"] = -1] = "UP";
    MovementDirection[MovementDirection["DOWN"] = 1] = "DOWN";
    MovementDirection[MovementDirection["NONE"] = 0] = "NONE";
})(MovementDirection || (MovementDirection = {}));
export var PlayerSuperskill;
(function (PlayerSuperskill) {
    PlayerSuperskill["FROST"] = "FROST";
    PlayerSuperskill["RAGE"] = "RAGE";
    PlayerSuperskill["SHIELD"] = "SHIELD";
    PlayerSuperskill["FIRE"] = "FIRE";
})(PlayerSuperskill || (PlayerSuperskill = {}));
