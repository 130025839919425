import styled from 'styled-components';
import { InterfaceFont } from '~type/interface';
export const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
export const Empty = styled.div `
  color: #fff;
  font-family: ${InterfaceFont.PIXEL_TEXT};
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 30px;
  letter-spacing: 1px;
  opacity: 0.5;
`;
