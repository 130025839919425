import React from 'react';
import { Avatar } from './avatar';
import { Experience } from './experience';
import { Health } from './health';
import { Resources } from './resources';
import { Score } from './score';
import { Skills } from './skills';
import { Wrapper, Space, Group } from './styles';
export const PlayerHUD = () => (React.createElement(Wrapper, null,
    React.createElement(Group, null,
        React.createElement(Avatar, null),
        React.createElement(Health, null),
        React.createElement(Space, null),
        React.createElement(Skills, null)),
    React.createElement(Group, null,
        React.createElement(Score, null),
        React.createElement(Space, null),
        React.createElement(Experience, null),
        React.createElement(Space, null),
        React.createElement(Resources, null))));
