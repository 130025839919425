import React, { useState } from "react";
import { Game } from '~game';
const App = () => {
    const [showGame, setShowGame] = useState(false);
    const [username, setUsername] = useState('');
    const handleChange = (e) => {
        setUsername(e.target.value);
    };
    const onClickIniciar = () => {
        if (username != 'g4t1lh0') {
            setShowGame(false);
            alert('Invalid username.');
            return;
        }
        setShowGame(true);
    };
    let game;
    if (showGame) {
        game = new Game();
        if (IS_DEV_MODE) {
            window.GAME = game;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "system-overlay", id: "loading" },
                React.createElement("div", { className: "loading-animation" }),
                React.createElement("div", { id: "loading-status" }, "GAME LOADING")),
            React.createElement("div", { id: "game-container" })));
    }
    else {
        game = null;
        window.GAME = null;
    }
    return (React.createElement("div", { style: { textAlign: "center", paddingTop: 100 } },
        React.createElement("input", { type: "text", id: "username", name: "username", placeholder: "Username", onChange: handleChange, value: username }),
        React.createElement("button", { onClick: onClickIniciar }, "Iniciar jogo")));
};
export default App;
