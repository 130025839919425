import { EnemyTexture } from '~type/world/entities/npc/enemy';
import { Enemy } from '../enemy';
export class EnemyUndead extends Enemy {
    constructor(scene, data) {
        super(scene, Object.assign(Object.assign({}, data), { texture: EnemyTexture.UNDEAD, multipliers: {
                health: 2.0,
                damage: 0.5,
                speed: 0.7,
            } }));
    }
}
EnemyUndead.SpawnWaveRange = [7];
