import { useGame } from 'phaser-react-ui';
import React, { useEffect, useState } from 'react';
import { Hint } from '~scene/system/interface/hint';
import { TutorialStep } from '~type/tutorial';
import { BuildingVariant } from '~type/world/entities/building';
import { BuilderInfo } from './info';
import { BuilderPreview } from './preview';
import { Variant, Info, Wrapper } from './styles';
export const Builder = () => {
    const game = useGame();
    const [hint, setHint] = useState(null);
    const showHint = (step) => {
        switch (step) {
            case TutorialStep.BUILD_GENERATOR: {
                return setHint({
                    variant: BuildingVariant.GENERATOR,
                    text: 'Build generator to get resources',
                });
            }
            case TutorialStep.BUILD_RADAR: {
                return setHint({
                    variant: BuildingVariant.RADAR,
                    text: 'Build radar to uncover enemies',
                });
            }
            case TutorialStep.BUILD_TOWER_FIRE: {
                return setHint({
                    variant: BuildingVariant.TOWER_FIRE,
                    text: 'Build tower to attack enemies',
                });
            }
            case TutorialStep.BUILD_AMMUNITION: {
                return setHint({
                    variant: BuildingVariant.AMMUNITION,
                    text: 'Build ammunition to reload towers',
                });
            }
        }
    };
    const hideHint = (step) => {
        switch (step) {
            case TutorialStep.BUILD_GENERATOR:
            case TutorialStep.BUILD_RADAR:
            case TutorialStep.BUILD_TOWER_FIRE:
            case TutorialStep.BUILD_AMMUNITION: {
                return setHint(null);
            }
        }
    };
    useEffect(() => game.tutorial.bindAll({
        beg: showHint,
        end: hideHint,
    }), []);
    return (React.createElement(Wrapper, null, Object.values(BuildingVariant).map((variant, index) => (React.createElement(Variant, { key: variant },
        (hint === null || hint === void 0 ? void 0 : hint.variant) === variant && (React.createElement(Hint, { side: "right" }, hint.text)),
        React.createElement(Info, null,
            React.createElement(BuilderInfo, { variant: variant })),
        React.createElement(BuilderPreview, { variant: variant, number: index + 1 }))))));
};
