import React, { useMemo } from 'react';
import { Wrapper, Item, Value, Label, Record, } from './styles';
export const Result = ({ stat, record }) => {
    const statItems = useMemo(() => [
        { key: 'score', label: 'Total score', value: stat.score },
        { key: 'waves', label: 'Waves completed', value: stat.waves },
        { key: 'kills', label: 'Enemies killed', value: stat.kills },
        { key: 'lived', label: 'Minutes lived', value: stat.lived.toFixed(1) },
    ], []);
    return (React.createElement(Wrapper, null, statItems.map((item) => {
        var _a;
        return (React.createElement(Item, { key: item.key },
            React.createElement(Value, null, item.value),
            React.createElement(Label, null, item.label),
            ((_a = record === null || record === void 0 ? void 0 : record[item.key]) !== null && _a !== void 0 ? _a : 0) < stat[item.key] && (React.createElement(Record, null, "RECORD"))));
    })));
};
