import { DIFFICULTY } from '~const/world/difficulty';
import { progressionQuadratic } from '~lib/difficulty';
import { NoticeType } from '~type/screen';
import { TutorialStep } from '~type/tutorial';
import { BuildingAudio, BuildingEvents, BuildingTexture, BuildingVariant, BuildingIcon, } from '~type/world/entities/building';
import { Building } from '../building';
export class BuildingAmmunition extends Building {
    get ammo() { return this._ammo; }
    set ammo(v) { this._ammo = v; }
    constructor(scene, data) {
        super(scene, Object.assign(Object.assign({}, data), { variant: BuildingVariant.AMMUNITION, health: BuildingAmmunition.Health, texture: BuildingAmmunition.Texture, radius: {
                default: DIFFICULTY.BUILDING_AMMUNITION_RADIUS,
                growth: DIFFICULTY.BUILDING_AMMUNITION_RADIUS_GROWTH,
            } }));
        this.maxAmmo = DIFFICULTY.BUILDING_AMMUNITION_AMMO;
        this._ammo = DIFFICULTY.BUILDING_AMMUNITION_AMMO;
        this.scene.game.tutorial.complete(TutorialStep.BUILD_AMMUNITION);
        this.on(BuildingEvents.UPGRADE, this.onUpgrade.bind(this));
    }
    getInfo() {
        const info = [{
                label: 'Ammo',
                icon: BuildingIcon.AMMO,
                value: this.ammo,
            }];
        return super.getInfo().concat(info);
    }
    getSavePayload() {
        return Object.assign(Object.assign({}, super.getSavePayload()), { ammo: this.ammo });
    }
    loadSavePayload(data) {
        super.loadSavePayload(data);
        if (data.ammo) {
            this.ammo = data.ammo;
        }
    }
    use(amount) {
        if (this.ammo <= amount) {
            const left = this.ammo;
            this.scene.game.screen.notice(NoticeType.WARN, `${this.getMeta().Name} are over`);
            if (this.scene.game.sound.getAll(BuildingAudio.OVER).length === 0) {
                this.scene.game.sound.play(BuildingAudio.OVER);
            }
            this.destroy();
            return left;
        }
        this.ammo -= amount;
        return amount;
    }
    onUpgrade() {
        const maxAmmo = progressionQuadratic({
            defaultValue: DIFFICULTY.BUILDING_AMMUNITION_AMMO,
            scale: DIFFICULTY.BUILDING_AMMUNITION_AMMO_GROWTH,
            level: this.upgradeLevel,
            roundTo: 10,
        });
        const addedAmmo = maxAmmo - this.maxAmmo;
        this.maxAmmo = maxAmmo;
        this.ammo += addedAmmo;
    }
}
BuildingAmmunition.Name = 'Ammunition';
BuildingAmmunition.Description = 'Reloads towers ammo within building radius';
BuildingAmmunition.Params = [
    { label: 'Health', value: DIFFICULTY.BUILDING_AMMUNITION_HEALTH, icon: BuildingIcon.HEALTH },
    { label: 'Ammo', value: DIFFICULTY.BUILDING_AMMUNITION_AMMO, icon: BuildingIcon.AMMO },
];
BuildingAmmunition.Texture = BuildingTexture.AMMUNITION;
BuildingAmmunition.Cost = DIFFICULTY.BUILDING_AMMUNITION_COST;
BuildingAmmunition.Health = DIFFICULTY.BUILDING_AMMUNITION_HEALTH;
BuildingAmmunition.AllowByWave = DIFFICULTY.BUILDING_AMMUNITION_ALLOW_BY_WAVE;
