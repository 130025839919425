import { useCurrentScene, useSceneUpdate } from 'phaser-react-ui';
import React, { useEffect, useState } from 'react';
import { ScreenEvents } from '~type/screen';
import { Item, Wrapper } from './styles';
export const Notices = () => {
    const screen = useCurrentScene();
    const [notices, setNotices] = useState([]);
    const addNotice = (data) => {
        setNotices((currentNotices) => {
            let isExist = false;
            const newNotices = currentNotices.map((currentNotice) => {
                if (currentNotice.text === data.text) {
                    isExist = true;
                    return Object.assign(Object.assign({}, currentNotice), { timestamp: Date.now() });
                }
                return currentNotice;
            });
            if (!isExist) {
                newNotices.push(Object.assign(Object.assign({}, data), { timestamp: Date.now() }));
            }
            return newNotices;
        });
    };
    useEffect(() => {
        screen.events.on(ScreenEvents.NOTICE, addNotice);
        return () => {
            screen.events.off(ScreenEvents.NOTICE, addNotice);
        };
    }, []);
    useSceneUpdate(screen, () => {
        const now = Date.now();
        setNotices((currentNotices) => {
            const newNotices = currentNotices.filter((currentNotice) => now - currentNotice.timestamp < 3000);
            return newNotices.length === currentNotices.length
                ? currentNotices
                : newNotices;
        });
    });
    return (React.createElement(Wrapper, null, notices.map((notice) => (React.createElement(Item, { role: "notice", key: notice.text, "$type": notice.type }, notice.text)))));
};
