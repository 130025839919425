export const DIFFICULTY = {
    /**
     * Player
     */
    PLAYER_START_RESOURCES: 70,
    PLAYER_SPEED: 90,
    PLAYER_SPEED_GROWTH: 0.05,
    PLAYER_SPEED_EXPERIENCE_TO_UPGRADE: 100,
    PLAYER_HEALTH: 999,
    PLAYER_HEALTH_GROWTH: 0.55,
    PLAYER_HEALTH_EXPERIENCE_TO_UPGRADE: 100,
    PLAYER_EXPERIENCE_TO_UPGRADE_GROWTH: 0.75,
    /**
     * Assistant
     */
    ASSISTANT_EXPERIENCE_TO_UPGRADE: 100,
    ASSISTANT_ATTACK_DAMAGE: 25,
    ASSISTANT_ATTACK_DAMAGE_GROWTH: 0.55,
    ASSISTANT_ATTACK_SPEED: 500,
    ASSISTANT_ATTACK_SPEED_GROWTH: 0.1,
    ASSISTANT_ATTACK_DISTANCE: 70,
    ASSISTANT_ATTACK_DISTANCE_GROWTH: 0.15,
    ASSISTANT_ATTACK_PAUSE: 1000,
    ASSISTANT_ATTACK_PAUSE_GROWTH: -0.15,
    /**
     * Superskills
     */
    SUPERSKILL_FROST_COST: 40,
    SUPERSKILL_FROST_DURATION: 6000,
    SUPERSKILL_RAGE_COST: 60,
    SUPERSKILL_RAGE_DURATION: 10000,
    SUPERSKILL_SHIELD_COST: 40,
    SUPERSKILL_SHIELD_DURATION: 10000,
    SUPERSKILL_FIRE_COST: 70,
    SUPERSKILL_FIRE_DURATION: 1000,
    SUPERSKILL_COST_GROWTH: 0.15,
    /**
     * Wave
     */
    WAVE_TIMELEFT: 10000,
    WAVE_TIMELEFT_GROWTH: 0.5,
    WAVE_ENEMIES_COUNT: 4,
    WAVE_ENEMIES_COUNT_GROWTH: 0.27,
    WAVE_ENEMIES_SPAWN_PAUSE: 2300,
    WAVE_ENEMIES_SPAWN_PAUSE_GROWTH: -0.035,
    WAVE_EXPERIENCE: 50,
    WAVE_EXPERIENCE_GROWTH: 0.2,
    /**
     * Ads reward
     */
    ADS_REWARD_EXPERIENCE: 400,
    ADS_REWARD_RESOURCES: 30,
    ADS_REWARD_GROWTH: 1.0,
    ADS_REWARD_FREQUENCY: 3,
    /**
     * Crystals
     */
    CRYSTAL_SPAWN_FACTOR: 0.15,
    CRYSTAL_RESOURCES: 20,
    CRYSTAL_RESOURCES_GROWTH: 1.0,
    /**
     * Enemies
     */
    ENEMY_HEALTH: 5,
    ENEMY_HEALTH_GROWTH: 0.4,
    ENEMY_HEALTH_GROWTH_RETARDATION_LEVEL: 13,
    ENEMY_ARMOUR: 60,
    ENEMY_ARMOUR_GROWTH: 0.4,
    ENEMY_ARMOUR_GROWTH_RETARDATION_LEVEL: 15,
    ENEMY_SPEED: 60,
    ENEMY_SPEED_GROWTH: 0.1,
    ENEMY_SPEED_GROWTH_MAX_LEVEL: 13,
    ENEMY_DAMAGE: 10,
    ENEMY_DAMAGE_GROWTH: 0.22,
    ENEMY_KILL_EXPERIENCE: 10,
    ENEMY_KILL_EXPERIENCE_GROWTH: 0.15,
    /**
     * Builder
     */
    BUILDER_BUILD_AREA: 140,
    BUILDER_BUILD_AREA_GROWTH: 0.13,
    BUILDER_BUILD_AREA_EXPERIENCE_TO_UPGRADE: 100,
    /**
     * Buildings
     */
    BUILDING_HEALTH_GROWTH: 0.7,
    BUILDING_BUILD_EXPERIENCE: 30,
    BUILDING_UPGRADE_EXPERIENCE: 15,
    BUILDING_UPGRADE_EXPERIENCE_GROWTH: 0.75,
    BUILDING_UPGRADE_COST_MULTIPLIER: 2.0,
    /**
     * Building: Wall
     */
    BUILDING_WALL_COST: 10,
    BUILDING_WALL_ALLOW_BY_WAVE: 2,
    BUILDING_WALL_HEALTH: 1500,
    /**
     * Building: Towers
     */
    BUIDLING_TOWER_SHOT_DAMAGE_GROWTH: 0.55,
    BUIDLING_TOWER_SHOT_FREEZE_GROWTH: 0.4,
    BUIDLING_TOWER_SHOT_SPEED_GROWTH: 0.2,
    BUIDLING_TOWER_AMMO_AMOUNT: 30,
    /**
     * Building: Tower: Fire
     */
    BUILDING_TOWER_FIRE_COST: 30,
    BUILDING_TOWER_FIRE_HEALTH: 600,
    BUILDING_TOWER_FIRE_RADIUS: 160,
    BUILDING_TOWER_FIRE_RADIUS_GROWTH: 0.25,
    BUILDING_TOWER_FIRE_DELAY: 1400,
    BUILDING_TOWER_FIRE_DELAY_GROWTH: -0.15,
    BUILDING_TOWER_FIRE_DAMAGE: 40,
    BUILDING_TOWER_FIRE_SHOT_SPEED: 300,
    /**
     * Building: Tower: Frozen
     */
    BUILDING_TOWER_FROZEN_COST: 40,
    BUILDING_TOWER_FROZEN_HEALTH: 800,
    BUILDING_TOWER_FROZEN_ALLOW_BY_WAVE: 3,
    BUILDING_TOWER_FROZEN_RADIUS: 150,
    BUILDING_TOWER_FROZEN_RADIUS_GROWTH: 0.25,
    BUILDING_TOWER_FROZEN_DELAY: 1400,
    BUILDING_TOWER_FROZEN_DELAY_GROWTH: -0.15,
    BUILDING_TOWER_FROZEN_FREEZE_DURATION: 800,
    BUILDING_TOWER_FROZEN_SHOT_SPEED: 300,
    /**
     * Building: Tower: Lazer
     */
    BUILDING_TOWER_LAZER_COST: 80,
    BUILDING_TOWER_LAZER_HEALTH: 400,
    BUILDING_TOWER_LAZER_ALLOW_BY_WAVE: 6,
    BUILDING_TOWER_LAZER_RADIUS: 140,
    BUILDING_TOWER_LAZER_RADIUS_GROWTH: 0.25,
    BUILDING_TOWER_LAZER_DELAY: 1300,
    BUILDING_TOWER_LAZER_DELAY_GROWTH: -0.16,
    BUILDING_TOWER_LAZER_DAMAGE: 65,
    /**
     * Building: Generator
     */
    BUILDING_GENERATOR_COST: 20,
    BUILDING_GENERATOR_HEALTH: 300,
    BUILDING_GENERATOR_DELAY: 1300,
    BUILDING_GENERATOR_DELAY_GROWTH: -0.154,
    /**
     * Building: Ammunition
     */
    BUILDING_AMMUNITION_COST: 40,
    BUILDING_AMMUNITION_HEALTH: 400,
    BUILDING_AMMUNITION_ALLOW_BY_WAVE: 3,
    BUILDING_AMMUNITION_RADIUS: 120,
    BUILDING_AMMUNITION_RADIUS_GROWTH: 0.25,
    BUILDING_AMMUNITION_AMMO: 150,
    BUILDING_AMMUNITION_AMMO_GROWTH: 1.0,
    /**
     * Building: Radar
     */
    BUILDING_RADAR_COST: 60,
    BUILDING_RADAR_HEALTH: 800,
    BUILDING_RADAR_ALLOW_BY_WAVE: 8,
    BUILDING_RADAR_RADIUS: 130,
    BUILDING_RADAR_RADIUS_GROWTH: 0.3, // Radius growth by level (Linear)
};
