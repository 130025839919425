export var WaveEvents;
(function (WaveEvents) {
    WaveEvents["START"] = "start";
    WaveEvents["COMPLETE"] = "complete";
})(WaveEvents || (WaveEvents = {}));
export var WaveAudio;
(function (WaveAudio) {
    WaveAudio["START"] = "wave/start";
    WaveAudio["COMPLETE"] = "wave/complete";
    WaveAudio["TICK"] = "wave/tick";
})(WaveAudio || (WaveAudio = {}));
