import { EnemyTexture } from '~type/world/entities/npc/enemy';
import { Enemy } from '../enemy';
export class EnemySpike extends Enemy {
    constructor(scene, data) {
        super(scene, Object.assign(Object.assign({}, data), { texture: EnemyTexture.SPIKE, multipliers: {
                health: 0.7,
                damage: 0.4,
                speed: 0.8,
            } }));
    }
}
EnemySpike.SpawnWaveRange = [3, 9];
