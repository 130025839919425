import { RelativePosition, useScene } from 'phaser-react-ui';
import React, { useEffect, useRef, useState } from 'react';
import { Hint } from '~scene/system/interface/hint';
import { GameScene } from '~type/game';
import { WorldEvents } from '~type/world';
import { Wrapper } from './styles';
export const RelativeHint = () => {
    const world = useScene(GameScene.WORLD);
    const [hint, setHint] = useState(null);
    const refWrapper = useRef(null);
    const showHint = (target) => {
        setHint(target);
    };
    const hideHint = () => {
        setHint(null);
    };
    useEffect(() => {
        world.events.on(WorldEvents.SHOW_HINT, showHint);
        world.events.on(WorldEvents.HIDE_HINT, hideHint);
        return () => {
            world.events.off(WorldEvents.SHOW_HINT, showHint);
            world.events.off(WorldEvents.HIDE_HINT, hideHint);
        };
    }, []);
    return hint && (React.createElement(RelativePosition, { x: hint.position.x, y: hint.position.y },
        React.createElement(Wrapper, { ref: refWrapper },
            React.createElement(Hint, { side: hint.side }, hint.text))));
};
