var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Phaser from 'phaser';
import { AUDIO_VOLUME, CONTAINER_ID, DEBUG_MODS, SETTINGS, } from '~const/game';
import { Analytics } from '~lib/analytics';
import { Storage } from '~lib/storage';
import { Tutorial } from '~lib/tutorial';
import { eachEntries } from '~lib/utils';
import { Gameover } from '~scene/gameover';
import { Menu } from '~scene/menu';
import { Screen } from '~scene/screen';
import { System } from '~scene/system';
import { World } from '~scene/world';
import { GameAdType, GameDifficulty, GameEvents, GameFlag, GameScene, GameSettings, GameState, } from '~type/game';
import { MenuPage } from '~type/menu';
import { shaders } from '../shaders';
export class Game extends Phaser.Game {
    get state() { return this._state; }
    set state(v) { this._state = v; }
    get screen() { return this._screen; }
    set screen(v) { this._screen = v; }
    get world() { return this._world; }
    set world(v) { this._world = v; }
    get settings() { return this._settings; }
    set settings(v) { this._settings = v; }
    get usedSave() { return this._usedSave; }
    set usedSave(v) { this._usedSave = v; }
    constructor() {
        super({
            scene: [System, World, Screen, Menu, Gameover],
            pixelArt: true,
            autoRound: true,
            disableContextMenu: true,
            width: window.innerWidth,
            height: window.innerHeight,
            parent: CONTAINER_ID,
            transparent: true,
            scale: {
                mode: Phaser.Scale.RESIZE,
            },
            physics: {
                default: 'arcade',
                arcade: {
                    debug: DEBUG_MODS.basic,
                    fps: 60,
                    gravity: { y: 0 },
                },
            },
        });
        this.difficulty = GameDifficulty.EASY;
        this._state = GameState.IDLE;
        this._settings = {};
        this._usedSave = null;
        this.tutorial = new Tutorial();
        this.analytics = new Analytics();
        this.storage = new Storage();
        this.readFlags();
        this.readSettings();
        // if (this.isFlagEnabled(GameFlag.ADS)) {
        //   registerScript('https://sdk.crazygames.com/crazygames-sdk-v2.js');
        // }
        this.events.on(Phaser.Core.Events.READY, () => {
            this.screen = this.scene.getScene(GameScene.SCREEN);
            this.world = this.scene.getScene(GameScene.WORLD);
            this.sound.setVolume(AUDIO_VOLUME);
            this.registerShaders();
        });
        this.events.on(`${GameEvents.UPDATE_SETTINGS}.${GameSettings.AUDIO}`, (value) => {
            this.sound.mute = (value === 'off');
        });
        this.events.on(`${GameEvents.UPDATE_SETTINGS}.${GameSettings.TUTORIAL}`, (value) => {
            if (value === 'on') {
                this.tutorial.enable();
            }
            else {
                this.tutorial.disable();
            }
        });
        window.onerror = (message, path, line, column, error) => {
            if (error) {
                this.analytics.trackError(error);
            }
            else if (typeof message === 'string') {
                this.analytics.trackError(new Error(message));
            }
            return false;
        };
    }
    loadPayload() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.storage.init()
                .then(() => this.storage.load());
        });
    }
    pauseGame() {
        if (this.state !== GameState.STARTED) {
            return;
        }
        this.state = GameState.PAUSED;
        this.world.scene.pause();
        this.screen.scene.pause();
        this.scene.systemScene.scene.launch(GameScene.MENU, {
            defaultPage: null,
        });
    }
    resumeGame() {
        if (this.state !== GameState.PAUSED) {
            return;
        }
        this.state = GameState.STARTED;
        this.scene.systemScene.scene.stop(GameScene.MENU);
        this.world.scene.resume();
        this.screen.scene.resume();
    }
    continueGame(save) {
        if (this.state !== GameState.IDLE) {
            return;
        }
        this.usedSave = save;
        this.loadSavePayload(this.usedSave.payload.game);
        this.world.scene.restart(this.usedSave.payload.level);
        this.world.events.once(Phaser.Scenes.Events.CREATE, () => {
            this.startGame();
        });
    }
    startNewGame() {
        if (this.state !== GameState.IDLE) {
            return;
        }
        this.usedSave = null;
        this.startGame();
    }
    startGame() {
        if (this.state !== GameState.IDLE) {
            return;
        }
        this.state = GameState.STARTED;
        if (!this.isSettingEnabled(GameSettings.TUTORIAL)) {
            this.tutorial.disable();
        }
        this.scene.systemScene.scene.stop(GameScene.MENU);
        this.scene.systemScene.scene.launch(GameScene.SCREEN);
        this.world.start();
        if (!IS_DEV_MODE) {
            window.onbeforeunload = function confirmLeave() {
                return 'Do you confirm leave game?';
            };
        }
    }
    stopGame() {
        if (this.state === GameState.IDLE) {
            return;
        }
        if (this.state === GameState.FINISHED) {
            this.scene.systemScene.scene.stop(GameScene.GAMEOVER);
        }
        this.state = GameState.IDLE;
        this.world.scene.restart();
        this.tutorial.reset();
        this.scene.systemScene.scene.stop(GameScene.SCREEN);
        this.scene.systemScene.scene.launch(GameScene.MENU, {
            defaultPage: MenuPage.NEW_GAME,
        });
        this.showAd(GameAdType.MIDGAME);
        if (!IS_DEV_MODE) {
            window.onbeforeunload = null;
        }
    }
    finishGame() {
        if (this.state !== GameState.STARTED) {
            return;
        }
        this.state = GameState.FINISHED;
        this.events.emit(GameEvents.FINISH);
        const record = this.getRecordStat();
        const stat = this.getCurrentStat();
        if (!IS_DEV_MODE) {
            this.writeBestStat(stat, record);
        }
        this.scene.systemScene.scene.stop(GameScene.SCREEN);
        this.scene.systemScene.scene.launch(GameScene.GAMEOVER, { stat, record });
        this.analytics.trackEvent({
            world: this.world,
            success: false,
        });
    }
    getDifficultyMultiplier() {
        switch (this.difficulty) {
            case GameDifficulty.EASY: return 0.8;
            case GameDifficulty.HARD: return 1.4;
            default: return 1.0;
        }
    }
    updateSetting(key, value) {
        this.settings[key] = value;
        localStorage.setItem(`SETTINGS.${key}`, value);
        this.events.emit(`${GameEvents.UPDATE_SETTINGS}.${key}`, value);
    }
    isSettingEnabled(key) {
        return (this.settings[key] === 'on');
    }
    readSettings() {
        eachEntries(GameSettings, (key) => {
            var _a;
            this.settings[key] = (_a = localStorage.getItem(`SETTINGS.${key}`)) !== null && _a !== void 0 ? _a : SETTINGS[key].default;
        });
    }
    isFlagEnabled(key) {
        return this.flags.includes(key);
    }
    readFlags() {
        var _a;
        const query = new URLSearchParams(window.location.search);
        const rawFlags = query.get('flags');
        this.flags = (_a = rawFlags === null || rawFlags === void 0 ? void 0 : rawFlags.toUpperCase().split(',')) !== null && _a !== void 0 ? _a : [];
    }
    showAd(type, callback) {
        var _a, _b, _c;
        if (!this.isFlagEnabled(GameFlag.ADS)) {
            return;
        }
        // @ts-ignore
        (_c = (_b = (_a = window.CrazyGames) === null || _a === void 0 ? void 0 : _a.SDK) === null || _b === void 0 ? void 0 : _b.ad) === null || _c === void 0 ? void 0 : _c.requestAd(type, {
            adStarted: () => {
                this.pause();
            },
            adFinished: () => {
                this.resume();
                callback === null || callback === void 0 ? void 0 : callback();
            },
            adError: (error) => {
                console.warn(`Error ${type} ad:`, error);
            },
        });
    }
    getRecordStat() {
        try {
            const recordValue = localStorage.getItem(`BEST_STAT.${this.difficulty}`);
            return recordValue && JSON.parse(recordValue);
        }
        catch (error) {
            return null;
        }
    }
    writeBestStat(stat, record) {
        const params = Object.keys(stat);
        const betterStat = params.reduce((curr, param) => {
            var _a;
            return (Object.assign(Object.assign({}, curr), { [param]: Math.max(stat[param], (_a = record === null || record === void 0 ? void 0 : record[param]) !== null && _a !== void 0 ? _a : 0) }));
        }, {});
        localStorage.setItem(`BEST_STAT.${this.difficulty}`, JSON.stringify(betterStat));
    }
    getCurrentStat() {
        return {
            score: this.world.player.score,
            waves: this.world.wave.number - 1,
            kills: this.world.player.kills,
            lived: this.world.getTime() / 1000 / 60,
        };
    }
    getSavePayload() {
        return {
            difficulty: this.difficulty,
            tutorial: this.tutorial.progress,
        };
    }
    loadSavePayload(data) {
        this.difficulty = data.difficulty;
        this.tutorial.progress = data.tutorial;
    }
    registerShaders() {
        const renderer = this.renderer;
        eachEntries(shaders, (name, Shader) => {
            renderer.pipelines.addPostPipeline(name, Shader);
        });
    }
}
