import { useRelativeScale } from 'phaser-react-ui';
import React from 'react';
import { INTERFACE_SCALE } from '~const/interface';
import { AdsReward } from './ads-reward';
import { Builder } from './builder';
import { Debug } from './debug';
import { GeneralHints } from './general-hints';
import { Notices } from './notices';
import { PlayerHUD } from './player-hud';
import { Column, Grid, Wrapper } from './styles';
import { Superskills } from './superskills';
import { Wave } from './wave';
export const ScreenUI = () => {
    const refScale = useRelativeScale(INTERFACE_SCALE);
    return (React.createElement(Wrapper, { ref: refScale },
        React.createElement(Grid, null,
            React.createElement(Column, { "$side": "left" },
                React.createElement(PlayerHUD, null),
                React.createElement(Debug, null)),
            React.createElement(Column, { "$side": "center" },
                React.createElement(Wave, null),
                React.createElement(AdsReward, null),
                React.createElement(GeneralHints, null),
                React.createElement(Superskills, null)),
            React.createElement(Column, { "$side": "right" },
                React.createElement(Builder, null))),
        React.createElement(Notices, null)));
};
ScreenUI.displayName = 'ScreenUI';
