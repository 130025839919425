import { FailureType } from '~type/state';
export function throwFailure(type) {
    if (document.getElementById(`failure-${type}`)) {
        return;
    }
    const overlay = document.createElement('div');
    overlay.classList.add('system-overlay');
    overlay.id = `failure-${type}`;
    switch (type) {
        case FailureType.BAD_DEVICE:
            overlay.innerText = 'DEVICE IS NOT SUPPORTED';
            break;
        case FailureType.BAD_SCREEN_SIZE:
            overlay.innerText = 'SCREEN SIZE IS NOT SUPPORTED';
            break;
        case FailureType.UNCAUGHT_ERROR:
            overlay.innerText = 'UNCAUGHT ERROR';
            break;
    }
    document.body.append(overlay);
}
export function removeFailure(type) {
    const overlay = document.getElementById(`failure-${type}`);
    if (overlay) {
        overlay.remove();
    }
}
export function setLoadingStatus(text) {
    const status = document.getElementById('loading-status');
    if (status) {
        status.innerText = text;
    }
}
export function removeLoading() {
    const overlay = document.getElementById('loading');
    if (overlay) {
        overlay.remove();
    }
}
