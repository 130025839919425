import { WorldGenerator } from 'gen-biome';
import Phaser from 'phaser';
import { LEVEL_TILE_SIZE, LEVEL_MAP_SIZE, LEVEL_MAP_MAX_HEIGHT, LEVEL_BIOME_PARAMETERS, LEVEL_SCENERY_TILE_SIZE, LEVEL_PLANETS, LEVEL_SEED_SIZE, } from '~const/world/level';
import { registerSpriteAssets } from '~lib/assets';
import { Navigator } from '~lib/navigator';
import { interpolate } from '~lib/utils';
import { GameEvents, GameSettings } from '~type/game';
import { BiomeType, SpawnTarget, LevelSceneryTexture, TileType, LevelTilesetTexture, LevelPlanet, } from '~type/world/level';
import { TileMatrix } from './tile-matrix';
export class Level extends TileMatrix {
    get effectsOnGround() { return this._effectsOnGround; }
    set effectsOnGround(v) { this._effectsOnGround = v; }
    get groundLayer() { return this._groundLayer; }
    set groundLayer(v) { this._groundLayer = v; }
    constructor(scene, { planet, seed }) {
        super(LEVEL_MAP_SIZE, LEVEL_MAP_MAX_HEIGHT);
        this.gridCollide = [];
        this.gridSolid = [];
        this._effectsOnGround = [];
        this.scene = scene;
        this.planet = planet !== null && planet !== void 0 ? planet : LevelPlanet.EARTH;
        const generator = new WorldGenerator({
            width: LEVEL_MAP_SIZE,
            height: LEVEL_MAP_SIZE,
        });
        const layer = generator.addLayer(LEVEL_BIOME_PARAMETERS);
        LEVEL_PLANETS[this.planet].BIOMES.forEach((biome) => {
            if (biome.params) {
                layer.addBiome(biome.params, biome.data);
            }
        });
        this.map = generator.generate({
            seed,
            seedSize: LEVEL_SEED_SIZE,
        });
        this.gridCollide = this.map.getMatrix().map((y) => y.map((x) => x.collide));
        this.gridSolid = this.map.getMatrix().map((y) => y.map((x) => !x.solid));
        this.navigator = new Navigator();
        this.addTilemap();
        this.addMapTiles();
        this.addScenery();
        this.scene.game.events.on(`${GameEvents.UPDATE_SETTINGS}.${GameSettings.EFFECTS}`, (value) => {
            if (value === 'off') {
                this.removeEffects();
            }
        });
    }
    looseEffects() {
        this.effectsOnGround.forEach((effect) => {
            effect.setAlpha(effect.alpha - 0.2);
            if (effect.alpha <= 0) {
                effect.destroy();
            }
        });
    }
    removeEffects() {
        this.effectsOnGround.forEach((effect) => {
            effect.destroy();
        });
        this.effectsOnGround = [];
    }
    readSpawnPositions(target, grid = 2) {
        var _a;
        const positions = [];
        const rand = Math.floor(grid / 2);
        for (let sX = grid; sX < this.map.width - grid; sX += grid) {
            for (let sY = grid; sY < this.map.height - grid; sY += grid) {
                const x = sX + Phaser.Math.Between(-rand, rand);
                const y = sY + Phaser.Math.Between(-rand, rand);
                const targets = (_a = this.map.getAt({ x, y })) === null || _a === void 0 ? void 0 : _a.spawn;
                if (targets && targets.includes(target)) {
                    positions.push({ x, y });
                }
            }
        }
        return positions;
    }
    hasTilesBetweenPositions(positionA, positionB) {
        const positionAtMatrixA = Level.ToMatrixPosition(positionA);
        const positionAtMatrixB = Level.ToMatrixPosition(positionB);
        const line = interpolate(positionAtMatrixA, positionAtMatrixB);
        return line.some((point) => { var _a; return ((_a = this.getTile(Object.assign(Object.assign({}, point), { z: 1 }))) === null || _a === void 0 ? void 0 : _a.tileType) === TileType.MAP; });
    }
    getBiome(type) {
        var _a, _b;
        return (_b = (_a = LEVEL_PLANETS[this.planet].BIOMES.find((biome) => (biome.data.type === type))) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    }
    addTilemap() {
        const data = new Phaser.Tilemaps.MapData({
            width: LEVEL_MAP_SIZE,
            height: LEVEL_MAP_SIZE,
            tileWidth: LEVEL_TILE_SIZE.width,
            tileHeight: LEVEL_TILE_SIZE.height * 0.5,
            orientation: Phaser.Tilemaps.Orientation.ISOMETRIC,
            format: Phaser.Tilemaps.Formats.ARRAY_2D,
        });
        const tilemap = new Phaser.Tilemaps.Tilemap(this.scene, data);
        const tileset = tilemap.addTilesetImage(LevelTilesetTexture[this.planet], undefined, LEVEL_TILE_SIZE.width, LEVEL_TILE_SIZE.height);
        if (!tileset) {
            throw Error('Unable to create map tileset');
        }
        this.addFalloffLayer(tilemap, tileset);
        this.addGroundLayer(tilemap, tileset);
    }
    addGroundLayer(tilemap, tileset) {
        const layer = tilemap.createBlankLayer('ground', tileset, -LEVEL_TILE_SIZE.width * 0.5, -LEVEL_TILE_SIZE.height * LEVEL_TILE_SIZE.origin);
        if (!layer) {
            throw Error('Unable to create map layer');
        }
        this.groundLayer = layer;
    }
    addFalloffLayer(tilemap, tileset) {
        const sizeInPixel = Math.max(this.scene.sys.canvas.clientWidth, this.scene.sys.canvas.clientHeight) * 0.5;
        const offset = Math.ceil(sizeInPixel / (LEVEL_TILE_SIZE.height * 0.5));
        const sizeInTiles = offset * 2 + LEVEL_MAP_SIZE;
        const position = Level.ToWorldPosition({ x: -offset, y: -offset, z: 0 });
        const layer = tilemap.createBlankLayer('falloff', tileset, position.x - LEVEL_TILE_SIZE.width * 0.5, position.y - LEVEL_TILE_SIZE.height * LEVEL_TILE_SIZE.origin, sizeInTiles, sizeInTiles);
        if (!layer) {
            return;
        }
        const biome = this.getBiome(BiomeType.WATER);
        if (!biome) {
            return;
        }
        const index = Array.isArray(biome.tileIndex)
            ? biome.tileIndex[0]
            : biome.tileIndex;
        for (let y = 0; y < sizeInTiles; y++) {
            for (let x = 0; x < sizeInTiles; x++) {
                if (x < offset || x >= sizeInTiles - offset || y < offset || y >= sizeInTiles - offset) {
                    layer.putTileAt(index, x, y, false);
                }
            }
        }
    }
    addMapTiles() {
        const addTile = (position, biome) => {
            const index = Array.isArray(biome.tileIndex)
                ? Phaser.Math.Between(...biome.tileIndex)
                : biome.tileIndex;
            if (biome.z === 0) {
                // Add tile to static tilemap layer
                this.groundLayer.putTileAt(index, position.x, position.y, false);
            }
            else {
                // Add tile as image
                // Need for correct calculate depth
                this.addMountTile(index, Object.assign(Object.assign({}, position), { z: biome.z }));
            }
        };
        this.map.each((position, biome) => {
            addTile(position, biome);
            // Add tile to hole
            if (biome.z > 1) {
                const z = biome.z - 1;
                const shiftX = this.map.getAt({ x: position.x + 1, y: position.y });
                const shiftY = this.map.getAt({ x: position.x, y: position.y + 1 });
                if ((shiftX && shiftX.z !== z) || (shiftY && shiftY.z !== z)) {
                    const patch = LEVEL_PLANETS[this.planet].BIOMES.find((b) => (b.data.z === z));
                    if (patch) {
                        addTile(position, patch.data);
                    }
                }
            }
        });
    }
    addMountTile(index, tilePosition) {
        const positionAtWorld = Level.ToWorldPosition(tilePosition);
        const tile = this.scene.add.image(positionAtWorld.x, positionAtWorld.y, LevelTilesetTexture[this.planet], index);
        tile.tileType = TileType.MAP;
        tile.setDepth(Level.GetTileDepth(positionAtWorld.y, tilePosition.z));
        tile.setOrigin(0.5, LEVEL_TILE_SIZE.origin);
        this.putTile(tile, tilePosition, false);
    }
    addScenery() {
        this.sceneryTiles = this.scene.add.group();
        const positions = this.readSpawnPositions(SpawnTarget.SCENERY);
        const count = Math.ceil(LEVEL_MAP_SIZE * LEVEL_PLANETS[this.planet].SCENERY_DENSITY);
        for (let i = 0; i < count; i++) {
            const positionAtMatrix = Phaser.Utils.Array.GetRandom(positions);
            const tilePosition = Object.assign(Object.assign({}, positionAtMatrix), { z: 1 });
            if (this.isFreePoint(tilePosition)) {
                const positionAtWorld = Level.ToWorldPosition(tilePosition);
                const tile = this.scene.add.image(positionAtWorld.x, positionAtWorld.y, LevelSceneryTexture[this.planet], Phaser.Math.Between(0, LEVEL_PLANETS[this.planet].SCENERY_VARIANTS - 1));
                tile.tileType = TileType.SCENERY;
                tile.clearable = true;
                tile.setDepth(Level.GetTileDepth(positionAtWorld.y, tilePosition.z));
                tile.setOrigin(0.5, LEVEL_SCENERY_TILE_SIZE.origin);
                this.putTile(tile, tilePosition);
                this.sceneryTiles.add(tile);
            }
        }
    }
    getSavePayload() {
        return {
            planet: this.planet,
            seed: this.map.seed,
        };
    }
    static ToMatrixPosition(positionAtWorld) {
        const { width, height, origin } = LEVEL_TILE_SIZE;
        const n = {
            x: (positionAtWorld.x / (width * 0.5)),
            y: (positionAtWorld.y / (height * origin)),
        };
        const positionAtMatrix = {
            x: Math.round((n.x + n.y) / 2),
            y: Math.round((n.y - n.x) / 2),
        };
        return positionAtMatrix;
    }
    static ToWorldPosition(tilePosition) {
        const { width, height, origin } = LEVEL_TILE_SIZE;
        const positionAtWorld = {
            x: (tilePosition.x - tilePosition.y) * (width * 0.5),
            y: (tilePosition.x + tilePosition.y) * (height * origin) - (tilePosition.z * (height * 0.5)),
        };
        return positionAtWorld;
    }
    static GetDepth(YAtWorld, tileZ, offset = 0) {
        return YAtWorld + (tileZ * LEVEL_TILE_SIZE.height) + offset;
    }
    static GetTileDepth(YAtWorld, tileZ) {
        return YAtWorld + (tileZ * LEVEL_TILE_SIZE.height) + LEVEL_TILE_SIZE.height * 0.5;
    }
}
registerSpriteAssets(LevelTilesetTexture, LEVEL_TILE_SIZE);
registerSpriteAssets(LevelSceneryTexture, LEVEL_SCENERY_TILE_SIZE);
