import React, { useState } from 'react';
import { Overlay } from '~scene/system/interface/overlay';
import { Content } from './content';
import { Navigation } from './navigation';
import { Wrapper, Logotype, Sidebar, Main, } from './styles';
export const MenuUI = ({ defaultPage }) => {
    const [page, setPage] = useState(defaultPage);
    return (React.createElement(Overlay, null,
        React.createElement(Wrapper, null,
            React.createElement(Sidebar, null,
                React.createElement(Logotype, { src: "assets/logotype.png" }),
                React.createElement(Navigation, { page: page, onSelect: setPage })),
            React.createElement(Main, null,
                React.createElement(Content, { page: page })))));
};
MenuUI.displayName = 'MenuUI';
