import { DIFFICULTY } from '~const/world/difficulty';
import { TutorialStep } from '~type/tutorial';
import { BuildingVariant, BuildingTexture, BuildingIcon, } from '~type/world/entities/building';
import { Building } from '../building';
export class BuildingRadar extends Building {
    constructor(scene, data) {
        super(scene, Object.assign(Object.assign({}, data), { variant: BuildingVariant.RADAR, health: BuildingRadar.Health, texture: BuildingRadar.Texture, radius: {
                default: DIFFICULTY.BUILDING_RADAR_RADIUS,
                growth: DIFFICULTY.BUILDING_RADAR_RADIUS_GROWTH,
            } }));
        this.scene.game.tutorial.complete(TutorialStep.BUILD_RADAR);
    }
}
BuildingRadar.Name = 'Radar';
BuildingRadar.Description = 'Makes hidden enemies visible within building radius';
BuildingRadar.Params = [
    { label: 'Health', value: DIFFICULTY.BUILDING_RADAR_HEALTH, icon: BuildingIcon.HEALTH },
];
BuildingRadar.Texture = BuildingTexture.RADAR;
BuildingRadar.Cost = DIFFICULTY.BUILDING_RADAR_COST;
BuildingRadar.Health = DIFFICULTY.BUILDING_RADAR_HEALTH;
BuildingRadar.AllowByWave = DIFFICULTY.BUILDING_RADAR_ALLOW_BY_WAVE;
