import EventEmitter from 'events';
import Phaser from 'phaser';
import { CONTROL_KEY } from '~const/controls';
import { DIFFICULTY } from '~const/world/difficulty';
import { ENEMIES } from '~const/world/entities/enemies';
import { ENEMY_BOSS_SPAWN_WAVE_RATE } from '~const/world/entities/enemy';
import { WAVE_TIMELEFT_ALARM } from '~const/world/wave';
import { registerAudioAssets } from '~lib/assets';
import { progressionLinear, progressionQuadratic, progressionQuadraticMixed } from '~lib/difficulty';
import { eachEntries } from '~lib/utils';
import { NoticeType } from '~type/screen';
import { TutorialStep } from '~type/tutorial';
import { EntityType } from '~type/world/entities';
import { EnemyVariant } from '~type/world/entities/npc/enemy';
import { WaveAudio, WaveEvents, } from '~type/world/wave';
export class Wave extends EventEmitter {
    get isGoing() { return this._isGoing; }
    set isGoing(v) { this._isGoing = v; }
    get isPeaceMode() { return this._isPeaceMode; }
    set isPeaceMode(v) { this._isPeaceMode = v; }
    get number() { return this._number; }
    set number(v) { this._number = v; }
    constructor(scene) {
        super();
        this._isGoing = false;
        this._isPeaceMode = false;
        this._number = 1;
        this.spawnedEnemiesCount = 0;
        this.enemiesMaxCount = 0;
        this.lastSpawnedEnemyVariant = null;
        this.nextWaveTimestamp = 0;
        this.nextSpawnTimestamp = 0;
        this.alarmInterval = null;
        this.scene = scene;
        this.setMaxListeners(0);
        this.handleKeyboard();
        this.runTimeleft();
    }
    destroy() {
        this.removeAllListeners();
        if (this.alarmInterval) {
            clearInterval(this.alarmInterval);
        }
    }
    getTimeleft() {
        const now = this.scene.getTime();
        return Math.max(0, this.nextWaveTimestamp - now);
    }
    update() {
        const now = this.scene.getTime();
        if (this.isGoing) {
            if (this.spawnedEnemiesCount < this.enemiesMaxCount) {
                if (this.nextSpawnTimestamp <= now) {
                    this.spawnEnemy();
                }
            }
            else if (this.scene.getEntitiesGroup(EntityType.ENEMY).getTotalUsed() === 0) {
                this.complete();
            }
        }
        else if (!this.isPeaceMode) {
            const left = this.nextWaveTimestamp - now;
            if (left <= 0) {
                this.start();
            }
            else if (left <= WAVE_TIMELEFT_ALARM
                && !this.scene.isTimePaused()
                && !this.alarmInterval) {
                this.scene.sound.play(WaveAudio.TICK);
                this.alarmInterval = setInterval(() => {
                    this.scene.sound.play(WaveAudio.TICK);
                }, 1000);
            }
        }
    }
    getEnemiesLeft() {
        const currentEnemies = this.scene.getEntitiesGroup(EntityType.ENEMY).getTotalUsed();
        const killedEnemies = this.spawnedEnemiesCount - currentEnemies;
        return this.enemiesMaxCount - killedEnemies;
    }
    skipTimeleft() {
        if (this.isGoing || this.scene.isTimePaused()) {
            return;
        }
        const now = this.scene.getTime();
        const skipedTime = this.nextWaveTimestamp - now;
        const resources = Math.floor(this.scene.getResourceExtractionSpeed() * (skipedTime / 1000));
        this.scene.player.giveResources(resources);
        this.nextWaveTimestamp = now;
    }
    runTimeleft() {
        const pause = progressionLinear({
            defaultValue: DIFFICULTY.WAVE_TIMELEFT,
            scale: DIFFICULTY.WAVE_TIMELEFT_GROWTH,
            level: this.number,
            roundTo: 1000,
        });
        this.nextWaveTimestamp = this.scene.getTime() + pause;
    }
    start() {
        this.isGoing = true;
        this.nextSpawnTimestamp = 0;
        this.spawnedEnemiesCount = 0;
        this.enemiesMaxCount = progressionQuadraticMixed({
            defaultValue: DIFFICULTY.WAVE_ENEMIES_COUNT,
            scale: DIFFICULTY.WAVE_ENEMIES_COUNT_GROWTH,
            level: this.number,
        });
        if (this.alarmInterval) {
            clearInterval(this.alarmInterval);
            this.alarmInterval = null;
        }
        this.scene.sound.play(WaveAudio.START);
        this.emit(WaveEvents.START, this.number);
    }
    complete() {
        const prevNumber = this.number;
        this.isGoing = false;
        this.number++;
        this.runTimeleft();
        this.scene.game.screen.notice(NoticeType.INFO, `Wave ${prevNumber} completed`);
        this.scene.sound.play(WaveAudio.COMPLETE);
        this.emit(WaveEvents.COMPLETE, prevNumber);
        this.scene.level.looseEffects();
        if (this.number === 2) {
            this.scene.game.tutorial.start(TutorialStep.UPGRADE_SKILL);
            this.scene.game.tutorial.start(TutorialStep.UPGRADE_BUILDING);
        }
        else if (this.number === 3) {
            this.scene.game.tutorial.start(TutorialStep.BUILD_AMMUNITION);
        }
        else if (this.number === 8) {
            this.scene.game.tutorial.start(TutorialStep.BUILD_RADAR);
        }
        this.scene.game.analytics.trackEvent({
            world: this.scene,
            success: true,
        });
    }
    getSavePayload() {
        return {
            number: this.number,
            timeleft: this.getTimeleft(),
        };
    }
    loadSavePayload(data) {
        this.number = data.number;
        this.nextWaveTimestamp = this.scene.getTime() + data.timeleft;
    }
    spawnEnemy() {
        const variant = this.getEnemyVariant();
        if (!variant) {
            return;
        }
        this.scene.spawnEnemy(variant);
        const pause = progressionQuadratic({
            defaultValue: DIFFICULTY.WAVE_ENEMIES_SPAWN_PAUSE,
            scale: DIFFICULTY.WAVE_ENEMIES_SPAWN_PAUSE_GROWTH,
            level: this.number,
        });
        this.nextSpawnTimestamp = this.scene.getTime() + Math.max(pause, 500);
        this.spawnedEnemiesCount++;
    }
    getEnemyVariant() {
        if (this.number % ENEMY_BOSS_SPAWN_WAVE_RATE === 0
            && this.spawnedEnemiesCount < Math.ceil(this.number / ENEMY_BOSS_SPAWN_WAVE_RATE)) {
            return EnemyVariant.BOSS;
        }
        const variants = [];
        eachEntries(ENEMIES, (variant, Instance) => {
            if (variant !== this.lastSpawnedEnemyVariant) {
                if (Instance.SpawnWaveRange) {
                    const [min, max] = Instance.SpawnWaveRange;
                    if (this.number >= min && (!max || this.number <= max)) {
                        variants.push(variant);
                    }
                }
            }
        });
        if (variants.length > 0) {
            this.lastSpawnedEnemyVariant = Phaser.Utils.Array.GetRandom(variants);
        }
        return this.lastSpawnedEnemyVariant;
    }
    handleKeyboard() {
        var _a;
        (_a = this.scene.input.keyboard) === null || _a === void 0 ? void 0 : _a.on(CONTROL_KEY.SKIP_WAVE_TIMELEFT, () => {
            this.skipTimeleft();
        });
    }
}
registerAudioAssets(WaveAudio);
