import Phaser from 'phaser';
import { Interface } from 'phaser-react-ui';
import { v4 as uuidv4 } from 'uuid';
import { DIFFICULTY } from '~const/world/difficulty';
import { ENEMIES } from '~const/world/entities/enemies';
import { ENEMY_SPAWN_DISTANCE_FROM_BUILDING, ENEMY_SPAWN_DISTANCE_FROM_PLAYER, ENEMY_SPAWN_POSITIONS, ENEMY_SPAWN_POSITIONS_GRID, } from '~const/world/entities/enemy';
import { LEVEL_PLANETS } from '~const/world/level';
import { Crystal } from '~entity/crystal';
import { Assistant } from '~entity/npc/variants/assistant';
import { Player } from '~entity/player';
import { Scene } from '~game/scenes';
import { aroundPosition, sortByDistance } from '~lib/utils';
import { Builder } from '~scene/world/builder';
import { Camera } from '~scene/world/camera';
import { WorldUI } from '~scene/world/interface';
import { Level } from '~scene/world/level';
import { Wave } from '~scene/world/wave';
import { GameEvents, GameScene, GameState } from '~type/game';
import { LiveEvents } from '~type/live';
import { WorldEvents, } from '~type/world';
import { EntityType } from '~type/world/entities';
import { BuildingVariant } from '~type/world/entities/building';
import { PlayerSkill } from '~type/world/entities/player';
import { SpawnTarget, } from '~type/world/level';
import { WaveEvents } from '~type/world/wave';
export class World extends Scene {
    get player() { return this._player; }
    set player(v) { this._player = v; }
    get assistant() { return this._assistant; }
    set assistant(v) { this._assistant = v; }
    get level() { return this._level; }
    set level(v) { this._level = v; }
    get wave() { return this._wave; }
    set wave(v) { this._wave = v; }
    get builder() { return this._builder; }
    set builder(v) { this._builder = v; }
    get camera() { return this._camera; }
    set camera(v) { this._camera = v; }
    get deltaTime() { return this._deltaTime; }
    set deltaTime(v) { this._deltaTime = v; }
    constructor() {
        super(GameScene.WORLD);
        this._assistant = null;
        this.enemySpawnPositions = [];
        this.enemySpawnPositionsAnalog = [];
        this.currentHintId = null;
        this._deltaTime = 1;
    }
    create(data) {
        this.input.setPollAlways();
        this.lifecyle = this.time.addEvent({
            delay: Number.MAX_SAFE_INTEGER,
            loop: true,
            paused: true,
        });
        this.level = new Level(this, data);
        this.camera = new Camera(this);
        this.generateEnemySpawnPositions();
    }
    start() {
        new Interface(this, WorldUI);
        this.camera.addZoomControl();
        this.resetTime();
        this.addWaveManager();
        this.addBuilder();
        this.addEntityGroups();
        this.addPlayer();
        this.addAssistant();
        this.addCrystals();
        if (this.game.usedSave) {
            this.loadSavePayload(this.game.usedSave.payload.world);
        }
    }
    update(time, delta) {
        if (this.game.state !== GameState.STARTED) {
            return;
        }
        this.deltaTime = delta;
        this.player.update();
        this.builder.update();
        this.wave.update();
    }
    showHint(hint) {
        this.currentHintId = uuidv4();
        this.events.emit(WorldEvents.SHOW_HINT, hint);
        return this.currentHintId;
    }
    hideHint(id) {
        if (!id || id === this.currentHintId) {
            this.events.emit(WorldEvents.HIDE_HINT);
            this.currentHintId = null;
        }
    }
    getTime() {
        return Math.floor(this.lifecyle.getElapsed());
    }
    isTimePaused() {
        return this.lifecyle.paused;
    }
    setTimePause(state) {
        this.lifecyle.paused = state;
    }
    resetTime() {
        var _a, _b;
        this.setTimePause(false);
        this.lifecyle.elapsed = (_b = (_a = this.game.usedSave) === null || _a === void 0 ? void 0 : _a.payload.world.time) !== null && _b !== void 0 ? _b : 0;
    }
    getResourceExtractionSpeed() {
        const generators = this.builder.getBuildingsByVariant(BuildingVariant.GENERATOR);
        const countPerSecond = generators.reduce((current, generator) => (current + ((1 / generator.getActionsDelay()) * 1000)), 0);
        return countPerSecond;
    }
    addEntity(type, gameObject) {
        this.add.existing(gameObject);
        this.entityGroups[type].add(gameObject);
    }
    getEntitiesGroup(type) {
        return this.entityGroups[type];
    }
    getEntities(type) {
        return this.entityGroups[type].getChildren();
    }
    spawnEnemy(variant) {
        const EnemyInstance = ENEMIES[variant];
        const positionAtMatrix = this.getEnemySpawnPosition();
        const enemy = new EnemyInstance(this, { positionAtMatrix });
        return enemy;
    }
    generateEnemySpawnPositions() {
        this.enemySpawnPositions = this.level.readSpawnPositions(SpawnTarget.ENEMY, ENEMY_SPAWN_POSITIONS_GRID);
        this.enemySpawnPositionsAnalog = [];
        for (let x = 0; x < this.level.map.width; x++) {
            for (let y = 0; y < this.level.map.height; y++) {
                if (x === 0
                    || x === this.level.map.width - 1
                    || y === 0
                    || y === this.level.map.height - 1) {
                    this.enemySpawnPositionsAnalog.push({ x, y });
                }
            }
        }
    }
    getEnemySpawnPosition() {
        const buildings = this.getEntities(EntityType.BUILDING);
        let freePositions = this.enemySpawnPositions.filter((position) => (Phaser.Math.Distance.BetweenPoints(position, this.player.positionAtMatrix) >= ENEMY_SPAWN_DISTANCE_FROM_PLAYER
            && buildings.every((building) => (Phaser.Math.Distance.BetweenPoints(position, building.positionAtMatrix) >= ENEMY_SPAWN_DISTANCE_FROM_BUILDING))));
        if (freePositions.length === 0) {
            freePositions = this.enemySpawnPositionsAnalog;
        }
        const closestPositions = sortByDistance(freePositions, this.player.positionAtMatrix)
            .slice(0, ENEMY_SPAWN_POSITIONS);
        const positionAtMatrix = Phaser.Utils.Array.GetRandom(closestPositions);
        return positionAtMatrix;
    }
    getFuturePosition(sprite, seconds) {
        const fps = this.game.loop.actualFps;
        const drag = Math.pow(0.3, (1 / fps));
        const per = 1 - Math.pow(drag, (seconds * fps));
        const offset = {
            x: ((sprite.body.velocity.x / fps) * per) / (1 - drag),
            y: ((sprite.body.velocity.y / fps) * per) / (1 - drag),
        };
        return {
            x: sprite.body.center.x + offset.x,
            y: sprite.body.center.y + offset.y,
        };
    }
    getSavePayload() {
        return {
            time: this.getTime(),
            crystals: this.getEntities(EntityType.CRYSTAL)
                .map((crystal) => crystal.getSavePayload()),
            buildings: this.getEntities(EntityType.BUILDING)
                .map((building) => building.getSavePayload()),
        };
    }
    loadSavePayload(data) {
        data.buildings.forEach((buildingData) => {
            const building = this.builder.createBuilding({
                variant: buildingData.variant,
                positionAtMatrix: buildingData.position,
                instant: true,
            });
            building.loadSavePayload(buildingData);
        });
    }
    addEntityGroups() {
        this.entityGroups = {
            [EntityType.CRYSTAL]: this.add.group(),
            [EntityType.ENEMY]: this.add.group(),
            [EntityType.NPC]: this.add.group({
                runChildUpdate: true,
            }),
            [EntityType.BUILDING]: this.add.group({
                runChildUpdate: true,
            }),
            [EntityType.SHOT]: this.add.group({
                runChildUpdate: true,
            }),
        };
    }
    addWaveManager() {
        this.wave = new Wave(this);
        if (this.game.usedSave) {
            this.wave.loadSavePayload(this.game.usedSave.payload.wave);
        }
        this.events.once(Phaser.Scenes.Events.SHUTDOWN, () => {
            this.wave.destroy();
        });
    }
    addBuilder() {
        this.builder = new Builder(this);
        this.events.once(Phaser.Scenes.Events.SHUTDOWN, () => {
            this.builder.destroy();
        });
        this.game.events.once(GameEvents.FINISH, () => {
            this.builder.close();
        });
    }
    addPlayer() {
        let positionAtMatrix;
        if (this.game.usedSave) {
            positionAtMatrix = this.game.usedSave.payload.player.position;
        }
        else {
            positionAtMatrix = Phaser.Utils.Array.GetRandom(this.level.readSpawnPositions(SpawnTarget.PLAYER));
        }
        this.player = new Player(this, { positionAtMatrix });
        if (this.game.usedSave) {
            this.player.loadSavePayload(this.game.usedSave.payload.player);
        }
        this.camera.focusOn(this.player);
        this.player.live.on(LiveEvents.DEAD, () => {
            this.camera.zoomOut();
            this.game.finishGame();
        });
    }
    addAssistant() {
        const create = () => {
            const positionAtMatrix = aroundPosition(this.player.positionAtMatrix).find((spawn) => {
                const biome = this.level.map.getAt(spawn);
                return biome === null || biome === void 0 ? void 0 : biome.solid;
            });
            this.assistant = new Assistant(this, {
                owner: this.player,
                positionAtMatrix: positionAtMatrix || this.player.positionAtMatrix,
                speed: this.player.speed,
                health: this.player.live.maxHealth,
                level: this.player.upgradeLevel[PlayerSkill.ASSISTANT],
            });
            this.assistant.once(Phaser.Scenes.Events.DESTROY, () => {
                this.assistant = null;
                this.wave.once(WaveEvents.COMPLETE, () => {
                    create();
                });
            });
        };
        create();
    }
    addCrystals() {
        const positions = this.level.readSpawnPositions(SpawnTarget.CRYSTAL);
        const getRandomPosition = () => {
            const freePositions = positions.filter((position) => this.level.isFreePoint(Object.assign(Object.assign({}, position), { z: 1 })));
            return Phaser.Utils.Array.GetRandom(freePositions);
        };
        const create = (position) => {
            const variants = LEVEL_PLANETS[this.level.planet].CRYSTAL_VARIANTS;
            new Crystal(this, {
                positionAtMatrix: position,
                variant: Phaser.Utils.Array.GetRandom(variants),
            });
        };
        const maxCount = Math.ceil(Math.floor((this.level.size * DIFFICULTY.CRYSTAL_SPAWN_FACTOR) / this.game.getDifficultyMultiplier()));
        if (this.game.usedSave) {
            this.game.usedSave.payload.world.crystals.forEach((crystal) => {
                create(crystal.position);
            });
        }
        else {
            for (let i = 0; i < maxCount; i++) {
                const position = getRandomPosition();
                create(position);
            }
        }
        this.wave.on(WaveEvents.COMPLETE, () => {
            const newCount = maxCount - this.getEntitiesGroup(EntityType.CRYSTAL).getTotalUsed();
            for (let i = 0; i < newCount; i++) {
                const position = getRandomPosition();
                create(position);
            }
        });
    }
}
