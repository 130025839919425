var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { openDB } from 'idb';
export class Storage {
    constructor() {
        this.db = null;
        this._saves = [];
    }
    get saves() { return this._saves; }
    set saves(v) { this._saves = v; }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            return openDB('save', 1, {
                upgrade: (db) => {
                    if (!db.objectStoreNames.contains('save')) {
                        db.createObjectStore('save', { keyPath: 'name' });
                    }
                },
            })
                .then((db) => {
                this.db = db;
            })
                .catch((error) => {
                console.error('Game storage error:', error);
            });
        });
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.db) {
                return Promise.resolve();
            }
            const transaction = this.db.transaction('save', 'readonly');
            const store = transaction.objectStore('save');
            this.saves = [];
            yield store
                .getAll()
                .then((data) => {
                data.forEach((save) => {
                    try {
                        this.saves.push(Object.assign(Object.assign({}, save), { payload: JSON.parse(save.payload) }));
                    }
                    catch (error) {
                        return null;
                    }
                });
                this.saves = this.saves.sort((a, b) => b.date - a.date);
            })
                .catch((error) => {
                console.error('Game saves load error:', error);
            });
        });
    }
    delete(name) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.db) {
                return Promise.resolve();
            }
            const transaction = this.db.transaction('save', 'readwrite');
            const store = transaction.objectStore('save');
            yield store.delete(name);
            const index = this.saves.findIndex((save) => save.name === name);
            if (index !== -1) {
                this.saves.splice(index, 1);
            }
        });
    }
    save(game, name) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.db) {
                return Promise.resolve(null);
            }
            const transaction = this.db.transaction('save', 'readwrite');
            const store = transaction.objectStore('save');
            const payload = {
                game: game.getSavePayload(),
                world: game.world.getSavePayload(),
                level: game.world.level.getSavePayload(),
                player: game.world.player.getSavePayload(),
                wave: game.world.wave.getSavePayload(),
            };
            const save = {
                name,
                payload,
                date: Date.now(),
            };
            const existIndex = this.saves.findIndex((s) => s.name === name);
            if (existIndex !== -1) {
                yield store.delete(name);
                this.saves.splice(existIndex, 1);
            }
            return store
                .add(Object.assign(Object.assign({}, save), { payload: JSON.stringify(payload) }))
                .then(() => {
                this.saves.unshift(save);
                return save;
            })
                .catch((error) => {
                console.error('Game save error:', error);
                return null;
            });
        });
    }
}
