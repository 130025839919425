export var BuildingEvents;
(function (BuildingEvents) {
    BuildingEvents["UPGRADE"] = "upgrade";
})(BuildingEvents || (BuildingEvents = {}));
export var BuildingVariant;
(function (BuildingVariant) {
    BuildingVariant["WALL"] = "WALL";
    BuildingVariant["TOWER_FIRE"] = "TOWER_FIRE";
    BuildingVariant["TOWER_LAZER"] = "TOWER_LAZER";
    BuildingVariant["TOWER_FROZEN"] = "TOWER_FROZEN";
    BuildingVariant["GENERATOR"] = "GENERATOR";
    BuildingVariant["AMMUNITION"] = "AMMUNITION";
    BuildingVariant["RADAR"] = "RADAR";
})(BuildingVariant || (BuildingVariant = {}));
export var BuildingTexture;
(function (BuildingTexture) {
    BuildingTexture["WALL"] = "building/textures/wall";
    BuildingTexture["TOWER_FIRE"] = "building/textures/tower_fire";
    BuildingTexture["TOWER_FROZEN"] = "building/textures/tower_frozen";
    BuildingTexture["TOWER_LAZER"] = "building/textures/tower_lazer";
    BuildingTexture["GENERATOR"] = "building/textures/generator";
    BuildingTexture["AMMUNITION"] = "building/textures/ammunition";
    BuildingTexture["RADAR"] = "building/textures/radar";
})(BuildingTexture || (BuildingTexture = {}));
export var BuildingIcon;
(function (BuildingIcon) {
    BuildingIcon["ALERT"] = "building/icons/alert";
    BuildingIcon["UPGRADE"] = "building/icons/upgrade";
    BuildingIcon["HEALTH"] = "building/icons/params/health";
    BuildingIcon["RADIUS"] = "building/icons/params/radius";
    BuildingIcon["AMMO"] = "building/icons/params/ammo";
    BuildingIcon["HEAL"] = "building/icons/params/heal";
    BuildingIcon["DAMAGE"] = "building/icons/params/damage";
    BuildingIcon["RESOURCES"] = "building/icons/params/resources";
    BuildingIcon["SPEED"] = "building/icons/params/speed";
    BuildingIcon["DELAY"] = "building/icons/params/pause";
})(BuildingIcon || (BuildingIcon = {}));
export var BuildingAudio;
(function (BuildingAudio) {
    BuildingAudio["SELECT"] = "building/select";
    BuildingAudio["UNSELECT"] = "building/unselect";
    BuildingAudio["BUILD"] = "building/build";
    BuildingAudio["UPGRADE"] = "building/upgrade";
    BuildingAudio["DEAD"] = "building/dead";
    BuildingAudio["OVER"] = "building/over";
    BuildingAudio["RELOAD"] = "building/reload";
    BuildingAudio["REPAIR"] = "building/repair";
    BuildingAudio["DAMAGE_1"] = "building/damage_1";
    BuildingAudio["DAMAGE_2"] = "building/damage_2";
})(BuildingAudio || (BuildingAudio = {}));
export var BuildingOutlineState;
(function (BuildingOutlineState) {
    BuildingOutlineState["NONE"] = "NONE";
    BuildingOutlineState["FOCUSED"] = "FOCUSED";
    BuildingOutlineState["SELECTED"] = "SELECTED";
})(BuildingOutlineState || (BuildingOutlineState = {}));
