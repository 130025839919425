import EventEmmiter from 'events';
import { LiveEvents } from '~type/live';
export class Live extends EventEmmiter {
    get health() { return this._health; }
    set health(v) { this._health = v; }
    get maxHealth() { return this._maxHealth; }
    set maxHealth(v) { this._maxHealth = v; }
    get armour() { return this._armour; }
    set armour(v) { this._armour = v; }
    get maxArmour() { return this._maxArmour; }
    set maxArmour(v) { this._maxArmour = v; }
    constructor({ health, maxHealth, armour, maxArmour, }) {
        var _a;
        super();
        this.health = health;
        this.maxHealth = maxHealth !== null && maxHealth !== void 0 ? maxHealth : health;
        this.armour = armour !== null && armour !== void 0 ? armour : 0;
        this.maxArmour = (_a = maxArmour !== null && maxArmour !== void 0 ? maxArmour : armour) !== null && _a !== void 0 ? _a : 0;
    }
    damage(amount) {
        let totalAmount = amount;
        if (this.armour > 0) {
            totalAmount = amount - this.armour;
            this.setArmour(this.armour - amount);
            if (totalAmount <= 0) {
                return;
            }
        }
        this.setHealth(this.health - totalAmount);
    }
    kill() {
        this.setHealth(0);
    }
    heal() {
        this.setHealth(this.maxHealth);
    }
    addHealth(amount) {
        this.setHealth(this.health + amount);
    }
    setHealth(amount) {
        if (this.isDead()) {
            return;
        }
        const prevHealth = this.health;
        this.health = Math.min(this.maxHealth, Math.max(0, amount));
        if (this.health < prevHealth) {
            this.emit(LiveEvents.DAMAGE, prevHealth - this.health);
            if (this.health === 0) {
                this.emit(LiveEvents.DEAD);
            }
        }
    }
    setArmour(amount) {
        if (this.isDead()) {
            return;
        }
        this.armour = Math.min(this.maxArmour, Math.max(0, amount));
    }
    setMaxHealth(amount) {
        this.maxHealth = Math.max(1, amount);
    }
    isMaxHealth() {
        return (this.health === this.maxHealth);
    }
    setMaxArmour(amount) {
        this.maxArmour = Math.max(1, amount);
    }
    isDead() {
        return (this.health === 0);
    }
}
