import Phaser from 'phaser';
export class TileMatrix {
    constructor(size, height) {
        this.tiles = [];
        this.size = size;
        this.height = height;
        for (let z = 0; z < height; z++) {
            this.tiles[z] = [];
            for (let y = 0; y < size; y++) {
                this.tiles[z][y] = [];
            }
        }
    }
    isFreePoint(position) {
        const tile = this.getTile(position);
        return !tile || Boolean(tile.clearable);
    }
    getTile(position) {
        var _a, _b, _c;
        const { x, y, z } = position;
        return (_c = (_b = (_a = this.tiles[z]) === null || _a === void 0 ? void 0 : _a[y]) === null || _b === void 0 ? void 0 : _b[x]) !== null && _c !== void 0 ? _c : null;
    }
    getTileWithType(position, type) {
        if (!this.tileIs(position, type)) {
            return null;
        }
        return this.getTile(position);
    }
    tileIs(position, type) {
        const tile = this.getTile(position);
        if (!tile) {
            return false;
        }
        if (type instanceof Array) {
            return type.includes(tile.tileType);
        }
        return (type === tile.tileType);
    }
    putTile(tile, position, destroyable = true) {
        const existsTile = this.getTile(position);
        if (existsTile) {
            existsTile.destroy();
        }
        const { x, y, z } = position;
        this.tiles[z][y][x] = tile;
        if (destroyable) {
            tile.on(Phaser.GameObjects.Events.DESTROY, () => {
                this.removeTile(position);
            });
        }
    }
    removeTile(position) {
        const tile = this.getTile(position);
        if (!tile) {
            return;
        }
        const { x, y, z } = position;
        delete this.tiles[z][y][x];
    }
}
