import React, { useEffect, useMemo, useRef } from 'react';
import { PLAYER_SKILLS } from '~const/world/entities/player';
import { UpgradesListItem } from './item';
import { Container } from './styles';
export const UpgradesList = ({ onClose }) => {
    const refContainer = useRef(null);
    const upgradeTypes = useMemo(() => Object.keys(PLAYER_SKILLS), []);
    const onClickOutside = (event) => {
        const isOutside = event
            .composedPath()
            .every((element) => element !== refContainer.current);
        if (isOutside) {
            onClose();
        }
    };
    const onKeyPress = (event) => {
        if (event.key === 'Escape') {
            onClose();
            event.stopPropagation();
        }
    };
    useEffect(() => {
        document.addEventListener('click', onClickOutside);
        document.addEventListener('keyup', onKeyPress);
        return () => {
            document.removeEventListener('click', onClickOutside);
            document.removeEventListener('keyup', onKeyPress);
        };
    }, []);
    return (React.createElement(Container, { ref: refContainer }, upgradeTypes.map((type) => (React.createElement(UpgradesListItem, { key: type, type: type })))));
};
