import { getModifiedArray, RelativePosition, useScene, useSceneUpdate, } from 'phaser-react-ui';
import React, { useEffect, useState } from 'react';
import { BUILDING_MAX_UPGRADE_LEVEL } from '~const/world/entities/building';
import { BuildingParams } from '~scene/system/interface/building-params';
import { GameScene } from '~type/game';
import { WorldEvents } from '~type/world';
import { BuildingControls } from './controls';
import { Name, Level, Health, Wrapper, Head, Body, } from './styles';
export const BuildingInfo = () => {
    const world = useScene(GameScene.WORLD);
    const [building, setBuilding] = useState(null);
    const [upgradeLevel, setUpgradeLevel] = useState(1);
    const [health, setHealth] = useState(1);
    const [maxHealth, setMaxHealth] = useState(1);
    const [params, setParams] = useState([]);
    const [controls, setControls] = useState([]);
    const onSelect = (target) => {
        setBuilding(target);
    };
    const onUnselect = () => {
        setBuilding(null);
        setParams([]);
        setControls([]);
    };
    useEffect(() => {
        world.events.on(WorldEvents.SELECT_BUILDING, onSelect);
        world.events.on(WorldEvents.UNSELECT_BUILDING, onUnselect);
        return () => {
            world.events.off(WorldEvents.SELECT_BUILDING, onSelect);
            world.events.off(WorldEvents.UNSELECT_BUILDING, onUnselect);
        };
    }, []);
    useSceneUpdate(world, () => {
        if (!(building === null || building === void 0 ? void 0 : building.active)) {
            return;
        }
        setUpgradeLevel(building.upgradeLevel);
        setHealth(building.live.health);
        setMaxHealth(building.live.maxHealth);
        setParams((current) => getModifiedArray(current, building.getInfo(), ['value', 'attention']));
        setControls((current) => getModifiedArray(current, building.getControls(), ['label', 'cost']));
    }, [building]);
    return (building && (React.createElement(RelativePosition, { x: building.x, y: building.y },
        React.createElement(Wrapper, null,
            React.createElement(Head, null,
                React.createElement(Name, null, building.getMeta().Name)),
            React.createElement(Body, null,
                React.createElement(Health, null,
                    React.createElement(Health.Progress, { style: { width: `${(health / maxHealth) * 100}%` } }),
                    React.createElement(Health.Value, null, `${health} HP`)),
                React.createElement(Level, null, Array.from({ length: BUILDING_MAX_UPGRADE_LEVEL }).map((_, level) => (React.createElement(Level.Progress, { key: level, "$active": level < upgradeLevel })))),
                React.createElement(BuildingParams, { list: params })),
            React.createElement(BuildingControls, { list: controls })))));
};
