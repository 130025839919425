var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const ASSETS_PACK = {
    files: [],
};
function normalizeAssetsFiles(files) {
    if (typeof files === 'string') {
        return [files];
    }
    return Object.values(files);
}
export function registerAudioAssets(files) {
    ASSETS_PACK.files = ASSETS_PACK.files.concat(normalizeAssetsFiles(files).map((audio) => ({
        key: audio,
        type: 'audio',
        url: `assets/audio/${audio}.mp3`,
    })));
}
export function registerImageAssets(files) {
    ASSETS_PACK.files = ASSETS_PACK.files.concat(normalizeAssetsFiles(files).map((image) => ({
        key: image,
        type: 'image',
        url: `assets/sprites/${image}.png`,
    })));
}
export function registerSpriteAssets(files, params) {
    ASSETS_PACK.files = ASSETS_PACK.files.concat(normalizeAssetsFiles(files).map((sprite) => {
        const { width, height } = (typeof params === 'function') ? params(sprite) : params;
        return {
            key: sprite,
            type: 'spritesheet',
            url: `assets/sprites/${sprite}.png`,
            frameConfig: {
                frameWidth: width,
                frameHeight: height,
            },
        };
    }));
}
export function getAssetsPack() {
    return ASSETS_PACK;
}
export function loadFontFace(name, file) {
    return __awaiter(this, void 0, void 0, function* () {
        const font = new FontFace(name, `url('assets/fonts/${file}')`);
        return font.load().then(() => {
            document.fonts.add(font);
            return font;
        });
    });
}
