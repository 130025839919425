import Phaser from 'phaser';
import { useGame } from 'phaser-react-ui';
import React, { useMemo } from 'react';
import { Button } from '~scene/system/interface/button';
import { GameDifficulty } from '~type/game';
import { LevelPlanet } from '~type/world/level';
import { Param } from './param';
import { Wrapper, Params } from './styles';
export const NewGame = () => {
    const game = useGame();
    const planets = useMemo(() => Object.keys(LevelPlanet), []);
    const difficulties = useMemo(() => Object.keys(GameDifficulty), []);
    const onChangePlanet = (planet) => {
        game.world.scene.restart({ planet });
        game.world.events.once(Phaser.Scenes.Events.CREATE, () => {
            game.world.camera.focusOnLevel();
        });
    };
    const onChangeDifficulty = (difficulty) => {
        game.difficulty = difficulty;
    };
    const onClickStart = () => {
        game.startNewGame();
    };
    return (React.createElement(Wrapper, null,
        React.createElement(Params, null,
            React.createElement(Param, { label: "Planet", values: planets, defaultValue: game.world.level.planet, onChange: onChangePlanet }),
            React.createElement(Param, { label: "Difficulty", values: difficulties, defaultValue: game.difficulty, onChange: onChangeDifficulty })),
        React.createElement(Button, { onClick: onClickStart, view: 'primary', size: "medium" }, "Start")));
};
