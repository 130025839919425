import { DIFFICULTY } from '~const/world/difficulty';
import { BuildingVariant, BuildingTexture, BuildingIcon, } from '~type/world/entities/building';
import { Building } from '../building';
export class BuildingWall extends Building {
    constructor(scene, data) {
        super(scene, Object.assign(Object.assign({}, data), { variant: BuildingVariant.WALL, health: BuildingWall.Health, texture: BuildingWall.Texture }));
    }
}
BuildingWall.Name = 'Wall';
BuildingWall.Description = 'Defends other buildings';
BuildingWall.Params = [
    { label: 'Health', value: DIFFICULTY.BUILDING_WALL_HEALTH, icon: BuildingIcon.HEALTH },
];
BuildingWall.Texture = BuildingTexture.WALL;
BuildingWall.Cost = DIFFICULTY.BUILDING_WALL_COST;
BuildingWall.Health = DIFFICULTY.BUILDING_WALL_HEALTH;
BuildingWall.AllowByWave = DIFFICULTY.BUILDING_WALL_ALLOW_BY_WAVE;
