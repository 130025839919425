export var GameAdType;
(function (GameAdType) {
    GameAdType["MIDGAME"] = "midgame";
    GameAdType["REWARDED"] = "rewarded";
})(GameAdType || (GameAdType = {}));
export var GameScene;
(function (GameScene) {
    GameScene["SYSTEM"] = "SYSTEM";
    GameScene["GAMEOVER"] = "GAMEOVER";
    GameScene["WORLD"] = "WORLD";
    GameScene["SCREEN"] = "SCREEN";
    GameScene["MENU"] = "MENU";
})(GameScene || (GameScene = {}));
export var GameEvents;
(function (GameEvents) {
    GameEvents["START"] = "start";
    GameEvents["FINISH"] = "finish";
    GameEvents["UPDATE_SETTINGS"] = "update_settings";
})(GameEvents || (GameEvents = {}));
export var GameState;
(function (GameState) {
    GameState["IDLE"] = "IDLE";
    GameState["STARTED"] = "STARTED";
    GameState["FINISHED"] = "FINISHED";
    GameState["PAUSED"] = "PAUSED";
})(GameState || (GameState = {}));
export var GameSettings;
(function (GameSettings) {
    GameSettings["AUDIO"] = "AUDIO";
    GameSettings["EFFECTS"] = "EFFECTS";
    GameSettings["TUTORIAL"] = "TUTORIAL";
})(GameSettings || (GameSettings = {}));
export var GameDifficulty;
(function (GameDifficulty) {
    GameDifficulty["EASY"] = "EASY";
    GameDifficulty["NORMAL"] = "NORMAL";
    GameDifficulty["HARD"] = "HARD";
})(GameDifficulty || (GameDifficulty = {}));
export var GameFlag;
(function (GameFlag) {
    GameFlag["NO_BLOOD"] = "NO_BLOOD";
    GameFlag["ADS"] = "ADS";
})(GameFlag || (GameFlag = {}));
