import React, { useState } from 'react';
import { Setting } from '~scene/system/interface/setting';
export const Param = ({ label, values, defaultValue, onChange, }) => {
    const [currentValue, setCurrentValue] = useState(defaultValue);
    const handleChange = (value) => {
        onChange(value);
        setCurrentValue(value);
    };
    return (React.createElement(Setting, { label: label, values: values, currentValue: currentValue, onChange: handleChange }));
};
