import { EnemyTexture } from '~type/world/entities/npc/enemy';
import { Enemy } from '../enemy';
export class EnemyDemon extends Enemy {
    constructor(scene, data) {
        super(scene, Object.assign(Object.assign({}, data), { texture: EnemyTexture.DEMON, multipliers: {
                health: 0.4,
                damage: 0.3,
                speed: 0.8,
            } }));
    }
}
EnemyDemon.SpawnWaveRange = [2, 7];
