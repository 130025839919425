import { useGame, useScene, useSceneUpdate } from 'phaser-react-ui';
import React, { useState } from 'react';
import { GameScene } from '~type/game';
import { Value } from './styles';
export const Debug = () => {
    const game = useGame();
    const world = useScene(GameScene.WORLD);
    const [frames, setFrames] = useState(0);
    const [memory, setMemory] = useState();
    useSceneUpdate(world, () => {
        var _a;
        setFrames(Math.round(game.loop.actualFps));
        // @ts-ignore
        const heapSize = (_a = performance === null || performance === void 0 ? void 0 : performance.memory) === null || _a === void 0 ? void 0 : _a.usedJSHeapSize;
        if (heapSize) {
            setMemory((heapSize / 1024 / 1024).toFixed(2));
        }
    });
    return (React.createElement(Value, null,
        frames,
        " FPS",
        memory && (React.createElement(React.Fragment, null,
            React.createElement("br", null),
            memory,
            " MB"))));
};
