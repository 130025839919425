export var ScreenAudio;
(function (ScreenAudio) {
    ScreenAudio["ERROR"] = "interface/error";
})(ScreenAudio || (ScreenAudio = {}));
export var ScreenEvents;
(function (ScreenEvents) {
    ScreenEvents["NOTICE"] = "notice";
})(ScreenEvents || (ScreenEvents = {}));
export var NoticeType;
(function (NoticeType) {
    NoticeType["INFO"] = "INFO";
    NoticeType["WARN"] = "WARN";
    NoticeType["ERROR"] = "ERROR";
})(NoticeType || (NoticeType = {}));
