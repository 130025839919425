import { useScene, useSceneUpdate } from 'phaser-react-ui';
import React, { useState } from 'react';
import { GameScene } from '~type/game';
import { Container, Progress, Value } from './styles';
export const Health = () => {
    const world = useScene(GameScene.WORLD);
    const [health, setHealth] = useState(0);
    const [maxHealth, setMaxHealth] = useState(0);
    useSceneUpdate(world, () => {
        setHealth(world.player.live.health);
        setMaxHealth(world.player.live.maxHealth);
    });
    return (React.createElement(Container, null,
        React.createElement(Progress, { style: { width: `${(health / maxHealth) * 100}%` } }),
        React.createElement(Value, null, `${health} HP`)));
};
