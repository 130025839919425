import { useGame, useScene, useSceneUpdate } from 'phaser-react-ui';
import React, { useState, useEffect } from 'react';
import { Amount } from '~scene/system/interface/amount';
import { Hint } from '~scene/system/interface/hint';
import { GameScene } from '~type/game';
import { TutorialStep } from '~type/tutorial';
import { Wrapper } from './styles';
export const Resources = () => {
    const game = useGame();
    const world = useScene(GameScene.WORLD);
    const [amount, setAmount] = useState(0);
    const [hint, setHint] = useState(false);
    useEffect(() => game.tutorial.bind(TutorialStep.RESOURCES, {
        beg: () => setHint(true),
        end: () => setHint(false),
    }), []);
    useSceneUpdate(world, () => {
        setAmount(world.player.resources);
    });
    return (React.createElement(Wrapper, null,
        React.createElement(Amount, { type: "resources" }, amount),
        hint && (React.createElement(Hint, { side: "top", align: "left" },
            "If resources not enough",
            React.createElement("br", null),
            "you can find crystals on map"))));
};
