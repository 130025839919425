import Phaser from 'phaser';
import { CAMERA_ZOOM } from '~const/world/camera';
import { Level } from '~scene/world/level';
export class Camera {
    constructor(scene) {
        this.scene = scene;
    }
    zoomOut() {
        this.scene.cameras.main.zoomTo(CAMERA_ZOOM * 0.5, 10 * 1000);
    }
    shake() {
        this.scene.cameras.main.shake(100, 0.0005);
    }
    focusOn(object) {
        const camera = this.scene.cameras.main;
        camera.resetFX();
        camera.startFollow(object);
        camera.setZoom(CAMERA_ZOOM * 2);
        camera.zoomTo(CAMERA_ZOOM, 200);
    }
    focusOnLevel() {
        const camera = this.scene.cameras.main;
        const size = this.scene.level.size - 1;
        const beg = Level.ToWorldPosition({ x: 0, y: size, z: 0 });
        const end = Level.ToWorldPosition({ x: size, y: 0, z: 0 });
        camera.setZoom(CAMERA_ZOOM);
        camera.pan(beg.x + (this.scene.sys.canvas.width / 2), beg.y, 0);
        setTimeout(() => {
            camera.pan(end.x - (this.scene.sys.canvas.width / 2), end.y, 2 * 60 * 1000);
        }, 0);
    }
    addZoomControl() {
        const camera = this.scene.cameras.main;
        this.scene.input.on(Phaser.Input.Events.POINTER_WHEEL, (pointer) => {
            const zoom = camera.zoom - (pointer.deltaY / 500);
            const clampZoom = Math.min(CAMERA_ZOOM, Math.max(CAMERA_ZOOM / 2, zoom));
            camera.zoomTo(clampZoom, 10);
        });
    }
}
