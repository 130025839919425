import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import NavigatorWorker from 'worker-loader!./worker';
import { NavigatorEvent, } from '~type/navigator';
export class Navigator {
    constructor() {
        this.pointsCost = [];
        this.tasks = [];
        this.worker = new NavigatorWorker();
        this.worker.addEventListener('message', ({ data }) => {
            const task = this.tasks.find((info) => info.id === data.payload.id);
            if (task) {
                switch (data.event) {
                    case NavigatorEvent.COMPLETE_TASK:
                        task.callback(data.payload.path);
                        break;
                }
            }
        });
    }
    setPointCost(position, cost) {
        if (!this.pointsCost[position.y]) {
            this.pointsCost[position.y] = [];
        }
        this.pointsCost[position.y][position.x] = cost;
        this.worker.postMessage({
            event: NavigatorEvent.UPDATE_POINTS_COST,
            payload: this.pointsCost,
        });
    }
    resetPointCost(position) {
        if (!this.pointsCost[position.y]) {
            return;
        }
        delete this.pointsCost[position.y][position.x];
        this.worker.postMessage({
            event: NavigatorEvent.UPDATE_POINTS_COST,
            payload: this.pointsCost,
        });
    }
    createTask(data, callback) {
        var _a;
        const payload = Object.assign({}, data);
        payload.id = (_a = payload.id) !== null && _a !== void 0 ? _a : uuidv4();
        this.worker.postMessage({
            event: NavigatorEvent.CREATE_TASK,
            payload,
        });
        this.tasks.push({
            id: payload.id,
            callback,
        });
        return payload.id;
    }
    cancelTask(id) {
        this.worker.postMessage({
            event: NavigatorEvent.CANCEL_TASK,
            payload: { taskId: id },
        });
        const taskIndex = this.tasks.findIndex((task) => task.id === id);
        if (taskIndex !== -1) {
            this.tasks.splice(taskIndex, 1);
        }
    }
}
