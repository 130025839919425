export var ShotLazerAudio;
(function (ShotLazerAudio) {
    ShotLazerAudio["LAZER"] = "shot/lazer";
})(ShotLazerAudio || (ShotLazerAudio = {}));
export var ShotBallTexture;
(function (ShotBallTexture) {
    ShotBallTexture["FIRE"] = "shot/fire";
    ShotBallTexture["FROZEN"] = "shot/frozen";
})(ShotBallTexture || (ShotBallTexture = {}));
export var ShotBallAudio;
(function (ShotBallAudio) {
    ShotBallAudio["FIRE"] = "shot/ball_fire";
    ShotBallAudio["FROZEN"] = "shot/ball_frozen";
})(ShotBallAudio || (ShotBallAudio = {}));
