import { ShotBallAudio, ShotBallTexture } from '~type/world/entities/shot';
import { ShotBall } from '../ball';
export class ShotBallFrozen extends ShotBall {
    constructor(scene, params) {
        super(scene, params, {
            texture: ShotBallTexture.FROZEN,
            audio: ShotBallAudio.FROZEN,
            glowColor: 0x00a1ff,
        });
    }
}
