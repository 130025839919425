export var AssistantTexture;
(function (AssistantTexture) {
    AssistantTexture["ASSISTANT"] = "assistant";
})(AssistantTexture || (AssistantTexture = {}));
export var AssistantAudio;
(function (AssistantAudio) {
    AssistantAudio["DEAD"] = "assistant/dead";
    AssistantAudio["DAMAGE_1"] = "assistant/damage_1";
    AssistantAudio["DAMAGE_2"] = "assistant/damage_2";
})(AssistantAudio || (AssistantAudio = {}));
