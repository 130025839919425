var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { removeFailure, throwFailure } from '~lib/state';
import { isValidScreenSize, isMobileDevice } from '~lib/utils';
import { FailureType } from '~type/state';
import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
(() => __awaiter(void 0, void 0, void 0, function* () {
    console.clear();
    if (!IS_DEV_MODE && isMobileDevice()) {
        throwFailure(FailureType.BAD_DEVICE);
        return;
    }
    function checkScreenSize() {
        if (isValidScreenSize()) {
            removeFailure(FailureType.BAD_SCREEN_SIZE);
        }
        else {
            throwFailure(FailureType.BAD_SCREEN_SIZE);
        }
    }
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    // const game = new Game();
    // if (IS_DEV_MODE) {
    //   window.GAME = game;
    // }
    ReactDOM.render(React.createElement(App, null), document.getElementById("root") || document.createElement("div"));
}))();
