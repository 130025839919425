import { useScene, useSceneUpdate } from 'phaser-react-ui';
import React, { useMemo, useState } from 'react';
import { GameScene } from '~type/game';
import { PlayerSuperskill } from '~type/world/entities/player';
import { SuperskillItem } from './item';
import { Wrapper } from './styles';
export const Superskills = () => {
    const world = useScene(GameScene.WORLD);
    const superskills = useMemo(() => Object.keys(PlayerSuperskill), []);
    const [isWaveGoing, setWaveGoing] = useState(false);
    useSceneUpdate(world, () => {
        setWaveGoing(world.wave.isGoing);
    });
    return isWaveGoing ? (React.createElement(Wrapper, null, superskills.map((superskill) => (React.createElement(SuperskillItem, { key: superskill, type: superskill }))))) : (React.createElement("div", null));
};
