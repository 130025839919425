import { DIFFICULTY } from '~const/world/difficulty';
import { ASSISTANT_PATH_BREAKPOINT, ASSISTANT_TILE_SIZE, } from '~const/world/entities/assistant';
import { NPC } from '~entity/npc';
import { ShotBallFire } from '~entity/shot/ball/variants/fire';
import { registerAudioAssets, registerSpriteAssets } from '~lib/assets';
import { progressionQuadratic } from '~lib/difficulty';
import { getClosest } from '~lib/utils';
import { Effect } from '~scene/world/effects';
import { GameSettings } from '~type/game';
import { EffectTexture } from '~type/world/effects';
import { EntityType } from '~type/world/entities';
import { AssistantTexture, AssistantAudio, } from '~type/world/entities/npc/assistant';
import { WaveEvents } from '~type/world/wave';
export class Assistant extends NPC {
    constructor(scene, { owner, positionAtMatrix, speed, health, level, }) {
        super(scene, {
            texture: AssistantTexture.ASSISTANT,
            positionAtMatrix,
            speed,
            health,
            pathFindTriggerDistance: ASSISTANT_PATH_BREAKPOINT,
        });
        this.nextAttackTimestamp = 0;
        this.level = 1;
        scene.add.existing(this);
        this.shot = new ShotBallFire(scene, {
            maxDistance: DIFFICULTY.ASSISTANT_ATTACK_DISTANCE,
            speed: DIFFICULTY.ASSISTANT_ATTACK_SPEED,
            damage: DIFFICULTY.ASSISTANT_ATTACK_DAMAGE,
        }, {
            scale: 0.75,
        });
        this.shot.setInitiator(this, () => this.body.center);
        this.shotDefaultParams = this.shot.params;
        this.gamut = ASSISTANT_TILE_SIZE.gamut;
        this.owner = owner;
        this.level = level;
        this.body.setCircle(this.width / 2, 0, 1);
        this.addIndicator({
            color: 0xd0ff4f,
            value: () => this.live.health / this.live.maxHealth,
            size: 20,
        });
        this.handleWaveComplete();
        this.activate();
        this.addCollider(EntityType.ENEMY, 'collider', (enemy) => {
            enemy.attack(this);
        });
        this.addCollider(EntityType.ENEMY, 'overlap', (enemy) => {
            enemy.overlapTarget();
        });
    }
    update() {
        super.update();
        if (this.isPathPassed) {
            this.setVelocity(0, 0);
        }
        if (this.isCanAttack()) {
            this.attack();
        }
    }
    onDamage() {
        this.scene.game.sound.play(Phaser.Utils.Array.GetRandom([
            AssistantAudio.DAMAGE_1,
            AssistantAudio.DAMAGE_2,
        ]));
        super.onDamage();
    }
    onDead() {
        this.scene.sound.play(AssistantAudio.DEAD);
        if (this.scene.game.isSettingEnabled(GameSettings.EFFECTS)) {
            new Effect(this.scene, {
                texture: EffectTexture.EXPLOSION,
                position: this.body.center,
                depth: this.depth + 1,
            });
        }
        super.onDead();
    }
    isCanAttack() {
        return (this.nextAttackTimestamp < this.scene.getTime()
            && !this.owner.live.isDead());
    }
    attack() {
        const target = this.getTarget();
        if (!target) {
            return;
        }
        this.shot.params = this.getShotCurrentParams();
        this.shot.shoot(target);
        const now = this.scene.getTime();
        const pause = progressionQuadratic({
            defaultValue: DIFFICULTY.ASSISTANT_ATTACK_PAUSE,
            scale: DIFFICULTY.ASSISTANT_ATTACK_PAUSE_GROWTH,
            level: this.level,
        });
        this.nextAttackTimestamp = now + Math.max(pause, 200);
    }
    getTarget() {
        const maxDistance = progressionQuadratic({
            defaultValue: DIFFICULTY.ASSISTANT_ATTACK_DISTANCE,
            scale: DIFFICULTY.ASSISTANT_ATTACK_DISTANCE_GROWTH,
            level: this.level,
        });
        const enemies = this.scene.getEntities(EntityType.ENEMY).filter((enemy) => {
            if (enemy.alpha < 1.0 || enemy.live.isDead()) {
                return false;
            }
            const positionFrom = this.getPositionOnGround();
            const positionTo = enemy.getPositionOnGround();
            return (Phaser.Math.Distance.BetweenPoints(positionFrom, positionTo) <= maxDistance
                && !this.scene.level.hasTilesBetweenPositions(positionFrom, positionTo));
        });
        return getClosest(enemies, this);
    }
    getShotCurrentParams() {
        const params = {
            maxDistance: this.shotDefaultParams.maxDistance
                && progressionQuadratic({
                    defaultValue: this.shotDefaultParams.maxDistance,
                    scale: DIFFICULTY.ASSISTANT_ATTACK_DISTANCE_GROWTH,
                    level: this.level,
                }),
            speed: this.shotDefaultParams.speed
                && progressionQuadratic({
                    defaultValue: this.shotDefaultParams.speed,
                    scale: DIFFICULTY.ASSISTANT_ATTACK_SPEED_GROWTH,
                    level: this.level,
                }),
            damage: this.shotDefaultParams.damage
                && progressionQuadratic({
                    defaultValue: this.shotDefaultParams.damage,
                    scale: DIFFICULTY.ASSISTANT_ATTACK_DAMAGE_GROWTH,
                    level: this.level,
                }),
        };
        return params;
    }
    handleWaveComplete() {
        const handler = () => {
            this.live.heal();
        };
        this.scene.wave.on(WaveEvents.COMPLETE, handler);
        this.on(Phaser.Scenes.Events.DESTROY, () => {
            this.scene.wave.off(WaveEvents.COMPLETE, handler);
        });
    }
}
registerAudioAssets(AssistantAudio);
registerSpriteAssets(AssistantTexture, ASSISTANT_TILE_SIZE);
