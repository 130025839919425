import { useScene, useSceneUpdate } from 'phaser-react-ui';
import React, { useState } from 'react';
import { Amount } from '~scene/system/interface/amount';
import { GameScene } from '~type/game';
export const Score = () => {
    const world = useScene(GameScene.WORLD);
    const [amount, setAmount] = useState(0);
    useSceneUpdate(world, () => {
        setAmount(world.player.score);
    });
    return React.createElement(Amount, { type: "score" }, amount);
};
